// src/contexts/UserContext.js
import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState({
    uni: '',
    email: '',
    department: '',
    name: '',
    role: 'Admin',
  });

  const updateUserInfo = (newInfo) => {
    setUserInfo(prevInfo => ({
      ...prevInfo,
      ...newInfo
    }));
  };

  const clearUserInfo = () => {
    setUserInfo({
      uni: '',
      email: '',
      department: '',
      name: '',
      role: 'Admin',

    });
  };

  return (
    <UserContext.Provider value={{ userInfo, updateUserInfo, clearUserInfo }}>
      {children}
    </UserContext.Provider>
  );
}

// Custom hook to use the user info
export function useUser() {
  return useContext(UserContext);
}