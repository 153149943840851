import React, { useState, useRef, useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import { Tooltip, CircularProgress } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import LinkIcon from '@mui/icons-material/Link';
import { useAuth } from '../../contexts/authContext';
import { ArrowBack, ArrowDownward } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { Article } from '@mui/icons-material';
import { OpenInNew } from '@mui/icons-material';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { DynamicForm } from '@mui/icons-material';
import { NotificationContext } from './Notification';
import { Download } from '@mui/icons-material';
import generatePDF, { usePDF } from 'react-to-pdf';
import { axiosInstance } from '../../contexts/authContext';
import { v4 as uuidv4 } from 'uuid';
import { Close } from '@mui/icons-material';
import { icon } from 'leaflet';
import _EventForm from './_EventForm';
import { Replay } from '@mui/icons-material';
// Styles
import { useTheme } from '../../contexts/authContext/ThemeContext';
import { colors } from '../color';


const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
};

const sectionStyle = {
    borderRadius: '10px',
    marginBottom: '0px',

};

const section1Style = {
    ...sectionStyle,
};

const section2Style = {
    ...sectionStyle,
    flex: 1,
    padding: '20px',
    boxSizing: 'border-box'
};

const buttonGroupStyle = {
    display: 'flex',
    flexDirection: "column",
    gap: '10px',
    width: "fit-content",
    marginTop: '20px'
};

const metricButtonStyle = {
    padding: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    flex: '1',
    backgroundColor: '#E5E7EB', // Light gray for better contrast on #fafafa
    transition: 'background-color 0.2s, color 0.2s', // Smooth transitions
};

const metricButtonActiveStyle = {
    backgroundColor: '#D1FAE5', // Light green for active state
    color: '#065F46', // Dark green text for active state
};

const metricTextStyle = (active) => ({
    fontSize: '0.75rem',
    color: active ? '#065F46' : '#6B7280', // Dark green for active, neutral gray for inactive
    textAlign: 'start',
    fontWeight: '500', // Medium weight for better emphasis
});

const metricCountStyle = (active) => ({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: active ? '#065F46' : '#111827', // Dark green for active, dark gray for inactive
});


const chartContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

const chartRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%'
};

const individualChartStyle = {
    width: '17vw',
    height: '17vw',
};


const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '6rem'
};

const titleContainerStyle = {
    paddingLeft: '1.25rem'
};

const baseTableCell = {
    backgroundColor: '#fff',
    color: '#333',
    padding: '12px 16px',
    borderRadius: '6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px',
    border: '1px solid #f0f0f0',
    transition: 'background-color 0.2s'
};

const tableContainerStyle = {
    display: 'grid',
    gap: '12px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    overflow: 'auto'
};

const tableHeaderStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '12px'
};

const tableColumnStyle = {
    backgroundColor: '#f8f9fa',
    color: '#444',
    padding: '12px 16px',
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const tableBodyStyle = {
    display: 'grid',
    gap: '12px'
};

const tableRowStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '12px'
};

const tableCellStyle = {
    ...baseTableCell
};

const tableCellLeftStyle = {
    ...baseTableCell,
    textAlign: 'left'
};

const quickActionButtonStyle = (label) => {
    const colors = {
        edit: { bg: "#F3F4F6", text: "#333333" }, // Light Gray background, Dark Gray text
        hide: { bg: "#FCE8E8", text: "#D32F2F" }, // Light Red background, Dark Red text
        report: { bg: "#E6F4EA", text: "#2E7D32" }, // Light Green background, Dark Green text
        ai: { bg: "#EFE6FF", text: "#5E35B1" }, // Light Purple background, Dark Purple text
        view: { bg: "#E3F2FD", text: "#1565C0" }, // Light Blue background, Dark Blue text
        default: { bg: "#F3F4F6", text: "#333333" }, // Neutral fallback
    };

    const { bg, text } = colors[label] || colors.default;

    return {
        backgroundColor: bg,
        color: text,
        border: "none",
        padding: "5px 15px", // Simplified shorthand
        width: "fit-content",
        borderRadius: "20px",
        fontSize: "12px",
        display: 'flex',
        alignItems: "center",
        gap: "8px",
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        '&:hover': {
            backgroundColor: "#D1FAE5", // A light green hover for consistency (optional)
        }
    };
};


const iconStyle = {
    width: "15px",
    marginRight: "0px"
}



const linkIconStyle = {
    marginLeft: '0.5rem',
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer'
};

const overlayContainer = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'auto',
    padding: '20px'
};
const closeButton = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    marginRight: "20px",
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 1001
};

// Utility function for creating pie charts
const createPieChart = (elementId, data, title) => {
    const labels = Object.keys(data);
    const series = Object.values(data);

    const options = {
        chart: {
            type: 'donut',
            fontFamily: 'Lexend, Century Gothic, sans-serif',
            width: "130%",
        },
        series,
        labels,
        legend: { show: false },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => `${opts.w.globals.series[opts.seriesIndex]}\n(${val.toFixed(1)}%)`,
            style: { fontSize: '10px', colors: ['#EDEDED'] }, // Light text for dark mode
        },
        tooltip: {
            theme: 'dark', // Keep it dark mode
            style: { fontSize: '10px', fontFamily: 'Lexend, Century Gothic, sans-serif' },
            y: { formatter: value => `${value} clicks` },
        },
        colors: ['#6699CC', '#336699', '#1E477A', '#103458', '#081F38'], // Softer gradient of blues
        plotOptions: {
            pie: {
                donut: {
                    size: '50%', // Standard donut size
                },
            },
        },
        title: {
            text: title,
            align: 'center',
            margin: 20,
            offsetY: 10,
            style: {
                fontSize: '12px',
                fontFamily: 'Lexend, Century Gothic, sans-serif',
                fontWeight: '500',
                color: '#141414', // Light text for dark mode
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: { position: 'center' },
                plotOptions: { pie: { donut: { size: '70%' } } }, // Adjust size for small screens
            },
        }],
    };

    const chart = new ApexCharts(document.querySelector(elementId), options);
    chart.render();
    return chart;
};

// Components
const MetricButton = ({ label, count, active, setMetric, changeChart, isLoading }) => {
    const buttonStyle = {
        ...metricButtonStyle,
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
        cursor: isLoading ? 'not-allowed' : 'pointer',
        ...(active ? metricButtonActiveStyle : {})
    };

    return (
        <div
            style={buttonStyle}
            onClick={() => {
                if (!isLoading) {
                    setMetric();
                    if (changeChart) changeChart();
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={metricTextStyle(active)}>{label}</div>
                <div style={metricCountStyle(active)}>{count}</div>
            </div>
        </div>
    );
};

// const TableHeader = () => (
//     <div style={tableHeaderStyle}>
//         <div style={tableCellStyle}>Student ID</div>
//         <div style={tableCellStyle}>Name</div>
//         <div style={tableCellStyle}>Email</div>
//         <div style={tableCellStyle}>Year Joined</div>
//         <div style={tableCellStyle}>Student Type</div>
//         <div style={tableCellStyle}>Level</div>
//     </div>
// );

// const StudentTable = ({ currentMetric, studentLists }) => {
//     const getStudentList = () => {
//         const lists = {
//             clicks: studentLists?.clickStudents,
//             saved: studentLists?.savedStudents,
//             registration: studentLists?.registrationStudents,
//             attendance: studentLists?.attendanceStudents,
//         };
//         return lists[currentMetric] || [];
//     };

//     return getStudentList().map(student => (
//         <div key={student._id} style={tableRowStyle}>
//             <div style={tableCellStyle}>{student.studentNumber}</div>
//             <div style={tableCellLeftStyle}>{student.name}</div>
//             <div style={tableCellStyle}>{student.email}</div>
//             <div style={tableCellStyle}>{student.yearJoined}</div>
//             <div style={tableCellStyle}>{student.origin}</div>
//             <div style={tableCellStyle}>{student.level}</div>
//         </div>
//     ));
// };

const LoadingSpinner = () => (
    <div style={loadingStyle}>
        <CircularProgress thickness={10} />
    </div>
);


// // Skeleton components
// const MetricSkeleton = () => (
//     <div
//         style={{
//             padding: '15px',
//             borderRadius: '8px',
//             backgroundColor: '#E5E7EB',
//             width: '100%'
//         }}
//     >
//         <div className="animate-pulse">
//             <div className="h-2 w-16 bg-gray-200 rounded mb-3"></div>
//             <div className="h-8 w-24 bg-gray-200 rounded"></div>
//         </div>
//     </div>
// );

// const ChartSkeleton = () => (
//     <div className="animate-pulse flex flex-col items-center justify-center" style={{ width: '15vw', height: '15vw' }}>
//         <div className="w-full h-full bg-gray-200 rounded-full"></div>
//         <div className="h-2 w-20 bg-gray-200 rounded mt-4"></div>
//     </div>
// );

// const TableSkeleton = () => {
//     const rows = Array(5).fill(null);
//     return (
//         <div className="animate-pulse space-y-4">
//             <div className="grid grid-cols-6 gap-4">
//                 {Array(6).fill(null).map((_, i) => (
//                     <div key={i} className="h-8 bg-gray-200 rounded"></div>
//                 ))}
//             </div>
//             {rows.map((_, rowIndex) => (
//                 <div key={rowIndex} className="grid grid-cols-6 gap-4">
//                     {Array(6).fill(null).map((_, colIndex) => (
//                         <div key={colIndex} className="h-6 bg-gray-100 rounded"></div>
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );
// };




const _EventAnalytics = ({ _id, title, visible: initialVisible, ...eventProps }) => {

    const { isDarkMode, toggleTheme } = useTheme();


    const titleStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: "30px",
        fontWeight: "600",
        color: colors.background(!isDarkMode)
    };
    const queryClient = useQueryClient();
    const { currentUser, idToken } = useAuth();
    const [currentMetric, setCurrentMetric] = useState('clicks');
    const [visible, setVisible] = useState(false);
    const [visibilityChanging, setVisibilityChanging] = useState(false);
    const [reportCreationDateTime, setReportCreationDateTime] = useState();
    const [reportUUID, setReportUUID] = useState();
    const [savingReport, setSavingReport] = useState(false);

    const [refreshing, setRefreshLoading] = useState(false);

    const reportRef = useRef();

    const { setNotification } = useContext(NotificationContext);


    const [task, setTask] = useState();

    async function handleVisibility() {
        setVisibilityChanging(true);
        try {
            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/toggleEventVisibility',
                { eventID: event._id },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
            );

            setVisible(response.data.visible);
            setVisibilityChanging(false);


        } catch (err) {
            console.error(`Error fetching Click Rate Metrics: ${err}`);
        }

    }

    async function saveReport(userID, reportObject) {
        setSavingReport(true);
        try {
            const response = await axiosInstance.post(
                '/saveReport',
                {
                    userID,
                    reportObject
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );

            if (response.status === 200) {
                console.log("Before invalidation - checking cache:", queryClient.getQueryCache().findAll(['reports']));
                await queryClient.invalidateQueries(['reports', currentUser, idToken]);
                console.log("After invalidation");
                
                await queryClient.refetchQueries(['reports', currentUser, idToken]);
                console.log("After refetch attempt");
                
                const updatedCache = queryClient.getQueryCache().findAll(['reports']);
                console.log("Updated cache:", updatedCache);

                
                setSavingReport(false);

                return {
                    success: true,
                    data: response.data
                };
            } else {
                setSavingReport(false);

                throw new Error('Failed to save report');
            }
        } catch (error) {
            setSavingReport(false);

            return {
                success: false,
                error: error.response?.data?.error || error.message || 'Failed to save report'
            };
        }
    };


    // Chart refs
    const chartRefs = {
        chart1: useRef(null),
        chart2: useRef(null),
        chart3: useRef(null),
        chart4: useRef(null),
    };

    // API calls using react-query
    const fetchEvent = async ({ queryKey }) => {
        const [_, eventId, token] = queryKey;
        const response = await axios.post(
            'https://us-central1-resevents-e239e.cloudfunctions.net/getEventData',
            { eventID: eventId },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
    };

    const { data: event = {}, isLoading: eventLoading, refetch: refetchEvent } = useQuery(
        ['event', _id, idToken],
        fetchEvent,
        {
            enabled: !!_id && !!idToken
        }
    );
    useEffect(() => {
        if (event) {
            setVisible(event.visible);
        }
    }, [event]);

    const { data: metrics, isLoading: metricsLoading, refetch: refetchMetrics } = useQuery(
        ['metrics', _id],
        async () => {
            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/getEventMetrics',
                { eventID: _id },
                { headers: { 'Authorization': `Bearer ${idToken}` } }
            );

            
            return response.data;
        }
    );

    const { data: studentLists, isLoading: studentListsLoading, refetch: refetchStudentLists } = useQuery(
        ['studentLists', _id],
        async () => {
            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/getStudentLists',
                { eventID: _id },
                { headers: { 'Authorization': `Bearer ${idToken}` } }
            );



            return response.data;
        }
    );

    const { data: clickRateMetrics, isLoading: clickRateLoading } = useQuery(
        ['clickRate', _id],
        async () => {
            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/getClickRateMetrics',
                { eventID: _id },
                { headers: { 'Authorization': `Bearer ${idToken}` } }
            );

            
            return response.data;
        }
    );

    // Chart data transformation
    const chartData = React.useMemo(() => ({
        series: [{
            name: 'Clicks',
            data: clickRateMetrics?.map(item => {
                const [date, count] = Object.entries(item)[0];
                return { x: new Date(date).toISOString(), y: count };
            }) || [],
        }],
        options: {
            chart: {
                type: 'line',
                height: 350,
                toolbar: { show: false },
                background: '#FFFFFF',
            },
            stroke: { curve: 'smooth', width: 2.5 },
            markers: { size: 6, hover: { sizeOffset: 4 } },
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'Date',
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                labels: {
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                tooltip: { enabled: false },
            },
            yaxis: {
                title: {
                    text: 'Number of Clicks',
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                labels: {
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
            },
            colors: ['#519DE9'],
            grid: { show: false },
        }
    }), [clickRateMetrics]);

    // // Create pie charts when metrics change
    React.useEffect(() => {
        const currentMetricData = {
            clicks: metrics?.clickMetrics,
            saved: metrics?.savedMetrics,
            registration: metrics?.registrationMetrics,
            attendance: metrics?.attendanceMetrics,
        }[currentMetric];

        if (currentMetricData) {
            Object.values(chartRefs).forEach(ref => ref.current?.destroy());

            chartRefs.chart1.current = createPieChart('#event-chart1', currentMetricData.faculty, "Faculty");
            chartRefs.chart2.current = createPieChart('#event-chart2', currentMetricData.origin, "Student Type");
            chartRefs.chart3.current = createPieChart('#event-chart3', currentMetricData.level, "Program");
            chartRefs.chart4.current = createPieChart('#event-chart4', currentMetricData.yearJoined, "Year Joined");
        }
    }, [currentMetric, metrics]);

    //   if (eventLoading) return <LoadingSpinner />;


    const SummaryComponent = () => (
        <div style={{ width: "100%", height: "100%", backgroundColor: "lightblue" }}>
            <h2>Summary View</h2>
        </div>
    );

    const EditComponent = () => (
        <div style={{ width: "100%", height: "100%", backgroundColor: "lightgreen" }}>
            <h2>Edit View</h2>
        </div>
    );



    const formatTimestamp = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };


    function ReportComponent() {


        // Styles
        const styles = {
            container: {
                width: "fit-content",

                margin: '0 auto',
                padding: '2rem',
                backgroundColor: '#F8FAFC',
                overflowY: "scroll",
                marginTop: "20px",
                border: "1px solid #103458", // Thin border for visual separation
                borderRadius: "10px",        // Border radius for rounded corners
            },
            reportContainer: {
                width: "800px",
                margin: '0 auto',
                padding: '2rem',
                backgroundColor: '#FFFFFF',
                transition: 'all 0.3s ease', // Smooth resizing for better user experience
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Optional shadow for visual hierarchy
            },
            header: {
                marginBottom: '2rem',
            },
            subheading: {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: '#64748B',
                textTransform: 'uppercase',
                letterSpacing: '0.05em',
                marginBottom: '0.5rem',
            },
            mainHeading: {
                fontSize: '2rem',
                fontWeight: 600,
                color: '#0F172A',
                lineHeight: 1.2,
            },
            card: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1.25rem',
                marginBottom: '2rem',
            },
            dateRow: {
                marginBottom: '0.75rem',
            },
            dateLabel: {
                fontSize: '0.75rem',
                fontWeight: 500,
                color: '#64748B',
                marginBottom: '0.25rem',
            },
            dateValue: {
                fontSize: '0.875rem',
                color: '#1E293B',
            },
            metricsGrid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '1rem',
                marginBottom: '2rem',
            },
            metricCard: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1.25rem',
                textAlign: 'center',
            },
            metricValue: {
                fontSize: '1.75rem',
                fontWeight: 600,
                color: '#0F172A',
                marginBottom: '0.5rem',
            },
            metricLabel: {
                fontSize: '0.875rem',
                color: '#64748B',
                fontWeight: 500,
            },
            sectionHeading: {
                fontSize: '1.125rem',
                fontWeight: 600,
                color: '#1E293B',
                marginBottom: '1rem',
            },
            demographicsGrid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '1.5rem',
                marginBottom: '2rem',
            },
            demographicCard: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                overflow: 'hidden',
            },
            cardHeader: {
                padding: '1rem',
                backgroundColor: '#F8FAFC',
                borderBottom: '1px solid #E2E8F0',
            },
            cardTitle: {
                fontSize: '0.875rem',
                fontWeight: 600,
                color: '#1E293B',
            },
            table: {
                width: '100%',
                borderCollapse: 'collapse',
            },
            th: {
                textAlign: 'left',
                padding: '0.75rem 1rem',
                fontSize: '0.75rem',
                fontWeight: 500,
                color: '#64748B',
                borderBottom: '1px solid #E2E8F0',
            },
            td: {
                padding: '0.75rem 1rem',
                fontSize: '0.875rem',
                borderBottom: '1px solid #E2E8F0',
            },
            highlightRow: {
                backgroundColor: '#F1F5F9',
            },
            percentage: {
                color: '#64748B',
                fontSize: '0.75rem',
            },
            studentSection: {
                marginTop: '2rem',
            },
            studentTableWrapper: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1rem',
                marginBottom: '2rem',
            },
            tableGrid: {
                display: 'grid',
                gridTemplateColumns: 'minmax(120px, 1fr) minmax(200px, 2fr) minmax(200px, 2fr) minmax(100px, 1fr)',
                gap: '0.75rem',
            },
            tableHeader: {
                backgroundColor: '#F8FAFC',
                padding: '0.75rem 1rem',
                fontSize: '0.65rem',
                fontWeight: 600,
                height: "fit-content",
                borderRadius: '6px',
            },
            tableCell: {
                padding: '0.75rem 1rem',
                fontSize: 'clamp(0.65rem, 1vw, 0.7rem)', // Responsive font size
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                wordBreak: 'break-word', // Allows long words to break and wrap
                whiteSpace: 'normal',
            },
            footer: {
                textAlign: 'center',
                padding: '2rem',
                color: '#64748B',
                fontSize: '0.875rem',
            },
        };


        // Title
        const title = event.title;

        // TimeStamps

        const createdDateTime = formatTimestamp(event?.birthDate);
        const startDateTime = formatTimestamp(event?.startDate);
        const endDateTime = formatTimestamp(event?.endDate);

        // Base Metrics
        const attendeeCount = event.attendance_count;
        const clickCount = event.click_count;
        const registrationCount = event.registration_count;
        const saveCount = event.save_count;

        // Demographics Metrics
        const attendanceMetrics = metrics.attendanceMetrics;
        const clickMetrics = metrics.clickMetrics;
        const registrationMetrics = metrics.registrationMetrics;

        // Student Lists 
        const attendanceStudentList = studentLists['attendanceStudents'];
        const registrationStudentList = studentLists['registrationStudents'];
        const clicksStudentList = studentLists['clickStudents'];





        // Demographics Component
        const DemographicCard = ({ title, data }) => (


            <div style={styles.demographicCard}>
                <div style={styles.cardHeader}>
                    <div style={styles.cardTitle}>{title}</div>
                </div>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Category</th>
                            <th style={styles.th}>Count</th>
                            {/* <th style={styles.th}>%</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(data).map(([category, count], index) => (
                            <tr
                                key={index}
                                style={styles.td}
                            >
                                <td style={styles.td}>{category}</td>
                                <td style={styles.td}>{count}</td>
                                {/* Uncomment and calculate percentage if needed
            <td style={styles.td}>
              <span style={styles.percentage}>
                {/* Calculate percentage based on total or any other logic * /}
                {/* {(count / total) * 100}% */}
                                {/* </span>
            </td>
            */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );

        // Student Table
        const StudentTable = ({ title, students }) => (
            <div style={styles.studentSection}>
                <h3 style={styles.sectionHeading}>{title}</h3>
                <div style={styles.studentTableWrapper}>
                    <div style={styles.tableGrid}>
                        <div style={styles.tableHeader}>Student ID</div>
                        <div style={styles.tableHeader}>Name</div>
                        <div style={styles.tableHeader}>Email</div>
                        <div style={styles.tableHeader}>Enrollment Year</div>

                        {students.map((student, index) => (
                            <React.Fragment key={index}>
                                <div style={styles.tableCell}>{student.studentNumber}</div>
                                <div style={styles.tableCell}>{student.name}</div>
                                <div style={styles.tableCell}>{student.email}</div>
                                <div style={styles.tableCell}>{student.yearJoined}</div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );

        // Report Component
        const EventAnalyticsReport = () => {
            const metrics = [
                { value: attendeeCount, label: 'Attendees' },
                { value: registrationCount, label: 'Registrations' },
                { value: clickCount, label: 'Clicks' },
                { value: saveCount, label: 'Saves' }
            ];

            return (
                <div style={styles.container}>
                    <div style={styles.reportContainer} ref={reportRef}>
                        {/* Header Section */}
                        <div style={styles.header}>
                            <div style={styles.subheading}>Engage Event Analytics Report</div>
                            <h1 style={styles.mainHeading}>{title}</h1>
                            <div style={styles.dateLabel}>Report ID {reportUUID}</div>
                        </div>

                        {/* Date Section */}
                        <div style={styles.card}>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Report Created</div>
                                <div style={styles.dateValue}>{formatTimestamp(reportCreationDateTime)}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event Post Date</div>
                                <div style={styles.dateValue}>{createdDateTime}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event Start</div>
                                <div style={styles.dateValue}>{startDateTime}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event End</div>
                                <div style={styles.dateValue}>{endDateTime}</div>
                            </div>
                        </div>

                        {/* Metrics Section */}
                        <div style={styles.metricsGrid}>
                            {metrics.map((metric, index) => (
                                <div key={index} style={styles.metricCard}>
                                    <div style={styles.metricValue}>{metric.value}</div>
                                    <div style={styles.metricLabel}>{metric.label}</div>
                                </div>
                            ))}
                            <div>
                    </div>
                        </div>

                        {/* Demographics Sections */}
                        <h1 style={styles.header}>Demographics</h1>
                        <div>
                            <h2 style={styles.sectionHeading}>Attendees Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={attendanceMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={attendanceMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={attendanceMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={attendanceMetrics.yearJoined}
                                />
                                {/* Add other demographic cards similarly */}
                            </div>
                        </div>

                        <div>
                            <h2 style={styles.sectionHeading}>Registration Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={registrationMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={registrationMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={registrationMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={registrationMetrics.yearJoined}
                                />
                                {/* Add other demographic cards similarly */}
                            </div>

                        </div>

                        <div>
                            <h2 style={styles.sectionHeading}>Clicks Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={clickMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={clickMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={clickMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={clickMetrics.yearJoined}
                                />
                                {/* Add other demographic cards similarly */}
                            </div>
                        </div>

                        {/* Student Details Section */}
                        <h1 style={styles.header}>Student Lists</h1>
                        <div>
                            <StudentTable
                                title="Registered Students"
                                students={registrationStudentList}
                            />
                            <StudentTable
                                title="Attendees"
                                students={attendanceStudentList}
                            />
                            <StudentTable
                                title="Students Who Clicked"
                                students={clicksStudentList}
                            />
                        </div>

                        {/* Footer */}
                        <div style={styles.footer}>
                            Lunisity Software Inc. · Engage 2025
                        </div>
                    </div>

                </div>
            );
        };


        return <EventAnalyticsReport />;
    };





    return (
             <div style={containerStyle}>

            {task === "edit"  && <div style={overlayContainer}>
                <div style={closeButton} onClick={() => setTask('')}>
                        <Close />
                    </div>
                    <_EventForm event={event} type={"edit"} />
                </div>}

            {task === "report" && (
                <div style={overlayContainer}>
                    <div style={closeButton} onClick={() => setTask('')}>
                        <Close />
                    </div>
                    <div style={{ ...quickActionButtonStyle("default"), padding: "10px 15px" }} onClick={async () => {
                        const reportObject = {
                            "_id": reportUUID,
                            "birthDate": reportCreationDateTime,
                            "visible": true,
                            "type": "event",
                            "event": {
                                "title": event.title,
                                "birthDate": event.birthDate,
                                "startDate": event.startDate,
                                "endDate": event.endDate,
                                "attendeeCount": event.attendance_count,
                                "clickCount": event.click_count,
                                "registrationCount": event.registration_count,
                                "saveCount": event.save_count
                            },

                            "metrics": {
                                "attendanceMetrics": metrics.attendanceMetrics,
                                "clickMetrics": metrics.clickMetrics,
                                "registrationMetrics": metrics.registrationMetrics,
                            },
                            "studentLists": {
                                "attendanceStudentList": studentLists['attendanceStudents'],
                                "registrationStudentList": studentLists['registrationStudents'],
                                "clicksStudentList": studentLists['clickStudents'],
                            }
                        }

              
                        const result = await saveReport(currentUser.uid, reportObject);
                        if (result.success) {
                            setNotification('reports', true);
                            generatePDF(reportRef);
                        }
                        
                    }}>

                        {savingReport ? (
                            <CircularProgress style={{ ...iconStyle, width: '10px', height: "10px", color: "#141414" }} />
                        ) : (
                            <Download style={iconStyle} />
                        )}
                        Save Report
                    </div>
                    <ReportComponent
                    />
                </div>
            )}

            <div style={section1Style}>

                {/* // Title */}
                <div style={titleStyle}>
                {event?.title || title}
                </div>

                {/* // Action Buttons */}
                <div style={{
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}>

                    <Tooltip title="Refresh" arrow>
                        <div
                            style={quickActionButtonStyle("view")}
                            onClick={async () => {
                                setRefreshLoading(true);
                                await refetchEvent();
                                await refetchMetrics();
                                await refetchStudentLists();

                                setRefreshLoading(false);
                            }}
                        >
                            {
                                refreshing ? (
                                <CircularProgress style={{ ...iconStyle, width: '10px', height: '10px', color: "#1565C0" }} />
                                ) : 
                                (
                                <Replay style={iconStyle} />
                            )
                            }
                        </div>
                    </Tooltip>
                    <Tooltip title="Edit Event" arrow>
                        <div
                            style={!(metricsLoading || studentListsLoading || eventLoading) ? quickActionButtonStyle("report") : quickActionButtonStyle("default")}
                            onClick={() => {
                                if (!(metricsLoading || studentListsLoading || eventLoading))
                                    setTask('edit')
                            }}
                        >
                            <Edit style={iconStyle} />Edit
                        </div>
                    </Tooltip>

                    <Tooltip title={visibilityChanging ? "Changing Visibility" : visible ? "Make this event undiscoverable for Students" : "Make this event discoverable for Students"} arrow>
                        <div
                            style={!(metricsLoading || studentListsLoading || eventLoading) ? quickActionButtonStyle("hide") : quickActionButtonStyle("default")}
                            onClick={() => {
                                if (!(metricsLoading || studentListsLoading || eventLoading))
                                    handleVisibility();

                            }}

                        >
                            {visibilityChanging ? (
                                <CircularProgress style={{ ...iconStyle, width: '10px', height: '10px', color: "#D32F2F" }} />
                            ) : visible ? (
                                <VisibilityOff style={iconStyle} />
                            ) : (
                                <Visibility style={iconStyle} />
                            )}
                            {visibilityChanging ? 'Changing...' : visible ? 'Hide' : 'Show'}
                        </div>
                    </Tooltip>

                    <Tooltip title="Create a Report for this Event" arrow>
                        <div
                            style={!(metricsLoading || studentListsLoading || eventLoading) ? quickActionButtonStyle("report") : quickActionButtonStyle("default")}
                            onClick={() => {
                                if (!(metricsLoading || studentListsLoading || eventLoading)) {
                                    setReportCreationDateTime(Math.floor(Date.now() / 1000));
                                    setReportUUID(uuidv4());
                                    setTask('report');
                                }

                            }}
                        >
                            <Article style={iconStyle} />Generate Report
                        </div>
                    </Tooltip>

                    {/* <Tooltip title="Generate AI Summary & Insights" arrow>
                        <div
                            style={!(metricsLoading || studentListsLoading || eventLoading) ? quickActionButtonStyle("ai") : quickActionButtonStyle("default")}
                            onClick={() => {
                                if (!(metricsLoading || studentListsLoading || eventLoading))
                                    setTask('summary')
                            }}
                        >
                            · ✦ · AI Summary
                        </div>
                    </Tooltip> */}

                    <Tooltip title="View Event" arrow>
                        <div
                            style={quickActionButtonStyle("view")}
                            onClick={() => { window.open(`/event/${_id}`) }}
                        >
                            <OpenInNew style={iconStyle} />View Event
                        </div>
                    </Tooltip>


                </div>

                {/* // Page Content */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "50px",
                    height: `calc(100vh - 300px)`

                }}>

                    <div style={buttonGroupStyle}>
                        <MetricButton
                            label="Clicks"
                            count={event?.click_count}
                            active={currentMetric === 'clicks'}
                            setMetric={() => setCurrentMetric('clicks')}
                            isLoading={metricsLoading}
                        />
                        <MetricButton
                            label="Saves"
                            count={event?.save_count}
                            active={currentMetric === 'saved'}
                            setMetric={() => setCurrentMetric('saved')}
                            isLoading={metricsLoading}
                        />
                        <MetricButton
                            label="Registrations"
                            count={event?.registration_count}
                            active={currentMetric === 'registration'}
                            setMetric={() => setCurrentMetric('registration')}
                            isLoading={metricsLoading}
                        />
                        <MetricButton
                            label="Attended"
                            count={event?.attendance_count}
                            active={currentMetric === 'attendance'}
                            setMetric={() => setCurrentMetric('attendance')}
                            isLoading={metricsLoading}
                        />
                    </div>

                    <div>
                        {metricsLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <div style={chartContainerStyle}>
                                <div style={chartRowStyle}>
                                    <div id="event-chart1" style={individualChartStyle} />
                                    <div id="event-chart2" style={individualChartStyle} />
                                </div>
                                <div style={chartRowStyle}>
                                    <div id="event-chart3" style={individualChartStyle} />
                                    <div id="event-chart4" style={individualChartStyle} />
                                </div>
                            </div>
                        )}
                    </div>

                    {task === "report" && <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div style={{ ...titleStyle, color: "#065F46" }}>Your Report is Ready</div>
                        <div style={{
                            width: "100",
                            height: "300px",
                            borderRadius: "100px",
                            backgroundColor: "#D1FAE5",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            alignItems: "center"
                        }}>

                            <ArrowDownward style={{ width: "200px", height: "200px", color: "#065F46" }} />
                        </div>
                    </div>}








                </div>
            </div>

            <div>

                {task === "summary" && <SummaryComponent />}




            </div>


        </div>

       
    );

};



export default _EventAnalytics;