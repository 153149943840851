import React, { useContext, useEffect, useState } from "react";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useQueryClient } from 'react-query';
import axios from 'axios';

// Create axios instance
export const axiosInstance = axios.create({
    baseURL: 'https://us-central1-resevents-e239e.cloudfunctions.net'
});

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [idToken, setIdToken] = useState(null);
    const queryClient = useQueryClient();

    const [showSessionAlert, setShowSessionAlert] = useState(false);


    // Token refresh function
    const refreshToken = async () => {
        try {
            if (!currentUser) {
                throw new Error("No currentUser found");
            }
            
            const token = await currentUser.getIdToken(true);
            setIdToken(token);
            
            // Invalidate and refetch queries that depend on the token
            queryClient.invalidateQueries('events');
            
            return token;
        } catch (error) {
            console.error("Error refreshing token:", error);
    
            // Show the session alert
            setShowSessionAlert(true);
    
            // Clear user-related states
            setCurrentUser(null);
            setIdToken(null);
            setUserLoggedIn(false);
            
            throw error; // Ensure the error is still propagated
        }
    };
    

    // Set up axios interceptors
    useEffect(() => {
        const interceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                
                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    
                    try {
                        const newToken = await refreshToken();
                        originalRequest.headers.Authorization = `Bearer ${newToken}`;
                        return axiosInstance(originalRequest);
                    } catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }
                
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.response.eject(interceptor);
        };
    }, [currentUser]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, []);

    async function initializeUser(user) {
        try {
            if (user) {
                const token = await user.getIdToken();
                setCurrentUser({ ...user });
                setIdToken(token);
                setUserLoggedIn(true);
            } else {
                setCurrentUser(null);
                setIdToken(null);
                setUserLoggedIn(false);
            }
        } catch (error) {
            console.error("Error initializing user:", error);
            setCurrentUser(null);
            setIdToken(null);
            setUserLoggedIn(false);
        } finally {
            setLoading(false);
        }
    }

    const value = {
        currentUser,
        userLoggedIn,
        loading,
        idToken
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}