
import './App.css';
import Dash from "../src/components/Admin_Dashboard/Dash.js";
import AdminLogin from "../src/components/Admin_Dashboard/AdminLogin.js";
import { AuthProvider } from './contexts/authContext';
import { AliveScope, KeepAlive } from 'react-activation';
import { HelmetProvider } from 'react-helmet-async'; // Add this import


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EventPage from './components/Admin_Dashboard/_EventView.js';
import PrivacyPolicy from './components/Policy/PrivacyPolicy.js';
import Landing from './components/Landing/Landing.js';
import EventAnalytics from './components/EventAnalyics/EventAnalytics.js';
import EventsPage from './components/Admin_Dashboard/Events.js';
import AdminSignup from './components/Admin_Dashboard/AdminSignup.js';
import RegisterView from './components/Admin_Dashboard/_RegisterView.js';
import { ThemeProvider } from './contexts/authContext/ThemeContext.js';
import { UserProvider } from './contexts/authContext/UserContext.js';
function App() {
  return (

    <UserProvider>
      <ThemeProvider>
     <HelmetProvider>
      <AliveScope>


        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthProvider><AdminLogin /></AuthProvider>} />
            <Route path="/admin-signup" element={<AuthProvider><AdminSignup /></AuthProvider>} />

            <Route path="/admin-dashboard" element={<AuthProvider><Dash /></AuthProvider>} />

            <Route path="/admin-dashboard/event" element={<KeepAlive><AuthProvider><EventsPage /></AuthProvider></KeepAlive>} />
            <Route path="/admin-dashboard/event/:eventID" element={<AuthProvider><EventAnalytics /></AuthProvider>} />


            <Route path="/event/:eventID/register" element={<AuthProvider><RegisterView /></AuthProvider>} />
            <Route path="/event/:eventID" Component={EventPage} />
            <Route path="/privacy_policy" Component={PrivacyPolicy} />
          </Routes>
        </BrowserRouter>
      </AliveScope>
    </HelmetProvider>

   </ThemeProvider>
    </UserProvider>
   

  );
}

export default App;
