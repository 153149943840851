import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import { 
  Card, 
  CardContent, 
  IconButton, 
  Tooltip, 
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Chart from 'react-apexcharts';

const fetchEventsPerWeek = async ({ queryKey }) => {
  const [_key, { timestamp, idToken }] = queryKey;
  const response = await axios.post(
    'https://us-central1-resevents-e239e.cloudfunctions.net/getEventsPerWeek',
    { startDate: timestamp },
    { 
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${idToken}` 
      } 
    }
  );
  return response.data;
};

const _EventsPerWeek = ({ semesterInfo }) => {
  const { idToken } = useAuth();
  
  const { data: eventsData = Array(13).fill(0), isLoading, refetch } = useQuery(
    ['eventsPerWeek', { timestamp: semesterInfo.timestamp, idToken }],
    fetchEventsPerWeek,
    {
      staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
      refetchOnWindowFocus: false,
    }
  );

  const weekLabels = Array.from({ length: 13 }, (_, i) => `Week ${i + 1}`);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        }
      },
      fontFamily: 'inherit',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      }
    },
    stroke: {
      curve: 'smooth',
      width: [2],
      dashArray: [0]
    },
    xaxis: {
      categories: weekLabels,
      title: {
        text: 'Weeks',
        style: {
          fontSize: '12px',
          fontWeight: 500
        }
      }
    },
    yaxis: {
      title: {
        text: 'Number of Events',
        style: {
          fontSize: '12px',
          fontWeight: 500
        }
      },
      labels: {
        formatter: (value) => Math.round(value)
      }
    },
    markers: {
      size: 6,
      hover: {
        size: 9,
        sizeOffset: 3
      },
      strokeWidth: 0,
      shape: 'circle',
      colors: ['#2E86C1'] // Using the deep blue from the multiline chart
    },
    tooltip: {
      shared: false,
      intersect: true,
      theme: 'light',
      y: {
        formatter: (val) => `${val.toLocaleString()} events`
      },
      marker: {
        show: true
      },
      onDatasetHover: {
        highlightDataSeries: true
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 10,
      fontSize: '10px',
      labels: {
        colors: '#969696'
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 12,
        offsetX: -2
      },
      onItemHover: {
        highlightDataSeries: true
      },
      onItemClick: {
        toggleDataSeries: true
      }
    },
    grid: {
      borderColor: '#E1E3E6',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    colors: ['#2E86C1'], // Using the deep blue from the education category
    responsive: [{
      breakpoint: 768,
      options: {
        legend: {
          position: 'bottom',
          offsetY: 0
        }
      }
    }]
  };
  return (
    <Card sx={{ width: '100%', borderRadius: "10px", bgcolor: '#F3F4F6',   height: 'min(55vh, 600px)', minHeight: '300px'  }} elevation={0}>
  <CardContent sx={{ p: 0, height: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3,
          pl: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              size="small"
              onClick={() => refetch()}
              sx={{ ml: 1 }}
            >
              {isLoading ? (
                <CircularProgress size={16} thickness={10} />
              ) : (
                <RefreshIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
          
          <Tooltip 
            title="Total Number of Events hosted in each week of the semester. It shows events that were going on in a particular week as well."
            arrow
            sx={{
              '& .MuiTooltip-tooltip': {
                fontSize: '12px',
                padding: '15px',
                maxWidth: '250px',
                bgcolor: '#fff',
                color: '#333',
                borderRadius: '10px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
              }
            }}
          >
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ p: 2.5, height: 'calc(100% - 90px)'  }}>
          <Chart
            options={chartOptions}
            series={[{ name: 'Number of Events', data: eventsData }]}
            type="line"
            height="100%"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default _EventsPerWeek;