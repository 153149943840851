import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { Add, Check, Replay } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';
import EventAnalytics from '../EventAnalyics/EventAnalytics';
import { UnfoldMore } from '@mui/icons-material';
import { ArrowUpward } from '@mui/icons-material';
import { ArrowDownward } from '@mui/icons-material';
import _EventAnalytics from './_EventAnalytics';
import { axiosInstance } from '../../contexts/authContext';
import { ArrowBack } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import _EventForm from './_EventForm';
import { useTheme } from '../../contexts/authContext/ThemeContext';
import { colors } from '../color';
import { useUser } from '../../contexts/authContext/UserContext';

const libraries = ['places'];
const apiKey = 'AIzaSyCm70028pa9kb8ojrhxMRtrOMxfJat0U_I';



const quickActionButtonStyle = (label) => {
  const colors = {
    edit: { bg: "#F3F4F6", text: "#333333" }, // Light Gray background, Dark Gray text
    delete: { bg: "#FCE8E8", text: "#D32F2F" }, // Light Red background, Dark Red text
    create: { bg: "#E6F4EA", text: "#2E7D32" }, // Light Green background, Dark Green text
    ai: { bg: "#EFE6FF", text: "#5E35B1" }, // Light Purple background, Dark Purple text
    view: { bg: "#E3F2FD", text: "#1565C0" }, // Light Blue background, Dark Blue text
    default: { bg: "#F3F4F6", text: "#333333" }, // Neutral fallback
  };



  const { bg, text } = colors[label] || colors.default;

  return {
    backgroundColor: bg,
    color: text,
    border: "none",
    padding: "5px 15px", // Simplified shorthand
    width: "fit-content",
    borderRadius: "20px",
    fontSize: "12px",
    display: 'flex',
    alignItems: "center",
    gap: "8px",
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: "#D1FAE5", // A light green hover for consistency (optional)
    }
  };
};

const overlayContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'auto',
  padding: '20px'
};
const closeButton = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  marginRight: "20px",
  backgroundColor: 'white',
  borderRadius: '50%',
  padding:"5px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  zIndex: 1001
};
const createEventButton = {
  position: 'fixed',
  top: '20px',
  right: '70px',
  marginRight: "20px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  zIndex: 1001
};

const newEventButton = {
  backgroundColor: "#E6F4EA", // Light Green
  border: "none",
  color: "#2E7D32", // Dark Green
  padding: "8px 20px",
  borderRadius: "20px",
  fontSize: "14px",
  display: 'flex',
  alignItems: "center",
  gap: "8px",
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: "#D1FAE5" // Softer Green for Hover
  }
};

const actionButton = {
  backgroundColor: "#F9F9F9", // Light Neutral Gray
  color: "#4A4A4A", // Darker Neutral Gray
  padding: "8px 20px",
  border: "none",
  borderRadius: "360px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "background-color 0.2s",
  '&:hover': {
    backgroundColor: "#EDEDED" // Slightly Darker Neutral for Hover
  }
};

const backButton = {
  backgroundColor: "#F3F4F6", // Light Gray
  border: "none",
  color: "#333333", // Dark Gray
  padding: "8px 20px",
  borderRadius: "20px",
  fontSize: "14px",
  display: 'flex',
  alignItems: "center",
  gap: "8px",
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: "#E5E5E5" // Slightly Darker Gray for Hover
  }
};


// Styles
const baseTableCell = {
  backgroundColor: '#fff',
  color: '#333',
  padding: '12px 16px',
  borderRadius: '6px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '13px',
  cursor: "pointer",
  border: '1px solid #f0f0f0',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: '#f8f9fa'
  }
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',

    padding: '0px'
  },
  buttonGroup: {
    display: 'flex',
    gap: '15px',
    marginBottom: '10px',
    marginTop: "10px"
  },
  newEventButton: {
    backgroundColor: "#61cf90",
    border: "none",
    color: "white",
    padding: "8px 20px",
    borderRadius: "20px",
    fontSize: "14px",
    display: 'flex',
    alignItems: "center",
    gap: "8px",
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: "#50be7f"
    }
  },
  actionButton: {
    backgroundColor: '#f5f5f5',
    color: '#666',
    padding: '8px 20px',
    border: 'none',
    borderRadius: '360px',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    }
  },

  backButton: {
    backgroundColor: "#141414",
    border: "none",
    color: "white",
    padding: "8px 20px",
    borderRadius: "20px",
    fontSize: "14px",
    display: 'flex',
    alignItems: "center",
    gap: "8px",
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: "#50be7f"
    }
  },
  tableContainer: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 2fr repeat(6, 1fr)',
    gap: '12px',
    marginTop: '24px',
    overflowY: 'auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
  tableColumn: {
    backgroundColor: '#f8f9fa',
    color: '#444',
    flex: 1,
    padding: '12px 16px',
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#f0f1f2'
    }
  },

  sortIcon: {
    fontSize: '12px',
    color: '#666',
    marginLeft: '4px'
  },

  tableCell: {
    ...baseTableCell
  },
  titleCell: {
    ...baseTableCell,
    textAlign: 'start',

  },
};

// API function
export const fetchEventList = async ({ queryKey }) => {
  const [, currentUser, idToken] = queryKey;
  const response = await axiosInstance.post(
    '/getEventList',
    { organizer_id: currentUser.uid },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      }
    }
  );

  return response.data;
};

const EventsPage = () => {
const { isDarkMode, toggleTheme } = useTheme();
  
  const semHeader = {
    fontSize: "50px",
    fontWeight: "600",
    color: colors.header(isDarkMode)
  };
  const { currentUser, idToken } = useAuth();
  const navigate = useNavigate();

  const [createEvent, setCreateEvent] = useState(false);
  const [sortField, setSortField] = useState('birthDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [sortedEvents, setSortedEvents] = useState([]); // New state for sorted events
  const [selectedEvent, setSelectedEvent] = useState();

  const { userInfo, updateUserInfo } = useUser();
  


  // React Query hook
  const {
    data: eventList = [],
    isLoading,
    refetch
  } = useQuery(
    ['events', currentUser, idToken],
    fetchEventList,
    {
      enabled: !!currentUser && !!idToken
    }
  );

  // Effect to update sortedEvents when eventList changes or sort configuration changes
  useEffect(() => {
    if (eventList) {
      const sorted = [...eventList].sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];
        return sortOrder === 'asc'
          ? valueA > valueB ? 1 : -1
          : valueA < valueB ? 1 : -1;
      });
      setSortedEvents(sorted);
    }
  }, [eventList, sortField, sortOrder]);

  const handleRefresh = async () => {
    setRefreshLoading(true);
    await refetch();
    setRefreshLoading(false);
  };

  const handleSort = (field) => {
    const newOrder = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newOrder);
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) {
      return <UnfoldMore className="text-gray-400" style={{ fontSize: '18px' }} />;
    }
    return sortOrder === 'asc' ?
      <ArrowUpward className="text-blue-500" style={{ fontSize: '18px' }} /> :
      <ArrowDownward className="text-blue-500" style={{ fontSize: '18px' }} />;
  };



  // Load Google Maps Script
  const { isLoaded: mapsLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  if (!mapsLoaded) {
    return (
      <div style={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  function selectEvent(event) {
    setSelectedEvent(event);

  }



  return (
    <div className="bg-gray-50" style={styles.container}>

      {createEvent && <div style={overlayContainer}>
      <div style={closeButton} onClick={() => setCreateEvent(false)}>
            <Close />
          </div>
        <_EventForm event={{}} type={"create"} />
      </div>}

      {
        selectedEvent ?
          (
            <>
              <div style={styles.buttonGroup}>
                <div
                  style={quickActionButtonStyle("edit")}
                  onClick={() => { setSelectedEvent() }}
                >
                  <ArrowBack style={{ width: "15px" }} /> Return to Events
                </div>
              </div>
              <_EventAnalytics {...selectedEvent} />
            </>
          ) :

          // Events Table Body
          (
            <div>
               <div style={semHeader}>
                    Events
                </div>
              <div style={styles.buttonGroup}>
                <div
                  style={quickActionButtonStyle("create")}
                  onClick={() => {
                  
                    if (userInfo.uni !== '')
                      setCreateEvent(true);
                  }}
                >
                  <Add style={{ width: "15px" }} /> Create Event
                </div>
                <div
                  style={quickActionButtonStyle("edit")}
                  onClick={handleRefresh}
                >
                  {refreshLoading ? (
                    <CircularProgress size={16} thickness={4} />
                  ) : (
                    <ReplayIcon style={{ width: "15px" }} />
                  )}
                </div>
              </div>

              <div style={{...styles.tableContainer, backgroundColor: isDarkMode ? '#4A5555' : 'white'}}>
                {/* Table Headers */}
                <div className="font-semibold" style={styles.tableColumn}>ID</div>
                <div className="font-semibold" style={styles.tableColumn}>Title</div>

                {[
                  { field: 'birthDate', label: 'Created On' },
                  { field: 'click_count', label: 'Clicks' },
                  { field: 'save_count', label: 'Saves' },
                  { field: 'registration_count', label: 'Registrations' },
                  { field: 'attendance_count', label: 'Attended' }
                ].map(({ field, label }) => (
                  <div
                    key={field}
                    className="font-semibold hover:bg-gray-100 transition-colors cursor-pointer"
                    style={styles.tableColumn}
                    onClick={() => handleSort(field)}
                  >
                    {label}
                    <SortIcon field={field} />
                  </div>
                ))}

                <div className="font-semibold" style={styles.tableColumn}>Status</div>

                {/* Table Content */}
                {isLoading ? (
                  <div className="col-span-8 flex justify-center items-center h-32">
                    <CircularProgress thickness={4} />
                  </div>
                ) : (
                  sortedEvents.map(event => (
                    <React.Fragment key={event._id} >
                      <div className="font-mono text-sm"
                        style={styles.tableCell} onClick={() => { selectEvent(event) }}
                      >
                        {event._id}
                      </div>
                      <div className="text-left" style={styles.titleCell} onClick={() => { selectEvent(event) }}>
                        {event.title}
                      </div>
                      <div style={styles.tableCell} onClick={() => { selectEvent(event) }}>
                        {new Date(event.birthDate * 1000).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric'
                        })}
                      </div>
                      <div style={styles.tableCell} onClick={() => { selectEvent(event) }}>{event.click_count}</div>
                      <div style={styles.tableCell} onClick={() => { selectEvent(event) }}>{event.save_count}</div>
                      <div style={styles.tableCell} onClick={() => { selectEvent(event) }}>{event.registration_count}</div>
                      <div style={styles.tableCell} onClick={() => { selectEvent(event) }}>{event.attendance_count}</div>
                      <div
                        style={{
                          ...styles.tableCell,
                          color: event.status === 'Happening soon' ? '#22c55e' :
                            event.status === 'Happening Now' ? '#3b82f6' :
                              event.status === 'Finished' ? '#9ca3af' : '#333'
                        }}
                        onClick={() => { selectEvent(event) }}
                      >
                        {event.status}
                      </div>
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          )
      }

    </div>
  );
};

export default EventsPage;