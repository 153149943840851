import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import axios from 'axios';
import { useAuth } from "../../contexts/authContext";
import "./AdminSignup.css"; // Import your CSS file for styling
import { v4 as uuidv4 } from 'uuid'; // Import the v4 method from uuid to generate a random UUID



function AdminSignup() {
    const navigate = useNavigate();
    const { currentUser, idToken } = useAuth();


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [faculty, setFaculty] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const type = 'organizer';
    const version = '6.7.7+39';
    const image = 'https://raw.githubusercontent.com/GirishVerm/turnUp_codebase/fd7e9094f087d54520d7f6564cc9a415d58e4706/assets/images/image-2.png?token=GHSAT0AAAAAAB5PZABB3HJY4LO2MM7O7VOQY7GSPAA';
    const [isSigningUp, setIsSigningUp] = useState(false);

    const faculties = [
        'Faculty of Business Administration',
        'Faculty of Education',
        'Faculty of Engineering and Applied Science',
        'Faculty of Humanities and Social Sciences',
        'Faculty of Medicine',
        'Faculty of Nursing',
        'Faculty of Science',
        'School of Graduate Studies',
        'School of Human Kinetics and Recreation',
        'School of Music',
        'School of Pharmacy',
        'School of Social Work',
        'Career Development Office',
        'Student Experience Office',
        'Internationalization Office',
        'Student Volunteer Bureau'
    ];

    const categories = [
        "Automotive",
        "Business & Professional",
        "Charity & Causes",
        "Family & Education",
        "Fashion & Beauty",
        "Film, Media & Entertainment",
        "Food & Drink",
        "Government & Politics",
        "Health & Wellness",
        "Hobbies & Special Interest",
        "Music",
        "Performing & Visual Arts",
        "Religion & Spirituality",
        "School Activities",
        "Science & Technology",
        "Seasonal & Holiday",
        "Sports & Fitness",
        "Travel & Outdoor"
    ];
    
    
    const onSubmit = async (e) => {
        e.preventDefault();
        if (isSigningUp) return;

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        setIsSigningUp(true);
        try {
            // Implement your signup logic here, for example:
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;



            const _id = user.uid;

            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/submitUserData',
                { _id:_id, name:name, email:email, username:username, faculty:faculty, type:type, uni:"MUN-SJ", version: version, image: image, categories: categories },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
            );

            // Clear form and navigate to admin dashboard or login page
            setName('');
            setEmail('');
            setUsername('');
            setFaculty('');
            setPassword('');
            setConfirmPassword('');
            navigate("/admin-dashboard");

        } catch (error) {
            console.error('Signup Error:', error);
            setErrorMessage(error.message);
        } finally {
            setIsSigningUp(false);
        }
    };

    return (
        <div className="signup-container" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <form onSubmit={onSubmit} className="signup-form">
                <h1 className="signup-title">Admin Signup</h1>
                <input
                    type="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="input-field"
                />
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input-field"
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field"
                />
               
                <select
                    value={faculty}
                    onChange={(e) => setFaculty(e.target.value)}
                    className="input-field"
                >
                    <option value="">Select Faculty or Department</option>
                    {faculties.map((faculty, index) => (
                        <option key={index} value={faculty}>{faculty}</option>
                    ))}
                </select>


                
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field"
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="input-field"
                />
                <div className="button-container">
                    <button type="submit" className="submit-button">
                        {isSigningUp ? "Signing Up..." : "Sign Up"}
                    </button>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
            <h5 style={{color: "white"}}>uniconn 2024</h5>
        </div>
    );
}

export default AdminSignup;
