import React, { useEffect, useState } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';

import axios from 'axios';

import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/authContext';
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Image from "@mui/icons-material/Image";

import "./Dash.css"
import CircularProgress from '@mui/material/CircularProgress'; // Importing Material-UI Spinner
import './EventDetailsForm.css'; // Import the CSS file
import Divider from "@mui/material/Divider";

import { useLoadScript } from '@react-google-maps/api';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { v4 as uuidv4 } from 'uuid'; // Import the v4 method from uuid to generate a random UUID

const libraries = ['places']; // Include 'places' for Google Places API
const apiKey = 'AIzaSyCm70028pa9kb8ojrhxMRtrOMxfJat0U_I'; // Replace with your actual API key




function EventsPage() {

    const { currentUser, idToken } = useAuth();
    const [eventList, setEventList] = useState([]);

    const [refreshLoading, setRefreshLoading] = useState(true);
    const [loading, setLoading] = useState(true); // State for loading
    const [createEvent, setCreateEvent] = useState(false); // New state to toggle the modal

    const [sortField, setSortField] = useState(null); // Field by which to sort (e.g., 'birthDate')
    const [sortOrder, setSortOrder] = useState('asc');

    const navigate = useNavigate();


    const [refresh, setRefresh] = useState(false); // Toggle this to trigger useEffect

    const handleRefresh = () => {
        setRefreshLoading(true);
        setRefresh(prev => !prev); // Toggle the `refresh` state to re-trigger useEffect
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getEventList',
                    { organizer_id: currentUser.uid },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );
                setEventList(response.data);
                setRefreshLoading(false);
                setLoading(false);

            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        fetchEvents();
        handleSort("birthDate", "desc");
    }, [refresh]);

    const handleSort = (field, order) => {
        const sortedEvents = [...eventList].sort((a, b) => {
            const valueA = a[field];
            const valueB = b[field];

            if (order === 'asc') {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });

        setEventList(sortedEvents);
        setSortField(field);
        setSortOrder(order);
    };

    const toggleSortOrder = (field) => {
        if (sortField === field) {
            const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            handleSort(field, newOrder);
        } else {
            handleSort(field, 'asc');
        }
    };

    const handleCreateEventToggle = () => {
        setCreateEvent(prev => !prev); // Toggle the modal state
    };

    const handleRightClick = (event, eventId) => {
        event.preventDefault();  // Prevent the default context menu
        window.open(`/admin-dashboard/event/${eventId}`, '_blank');
    };


    return (
        <div className={`container`}>

            {/* Modal window for creating a new event */}
            {createEvent && (
                <div className="modal" onClick={handleCreateEventToggle}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()} // Prevent the event from bubbling up
                    >
                        <EventDetailsForm event={{}} toggle={handleCreateEventToggle} refresh={handleRefresh} />
                    </div>
                </div>

            )}

            {/* Navbar */}
            <div className='navbarItem'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div className='navbar'>
                        <div className='section' onClick={() => { navigate("/admin-dashboard") }} >
                            Overview
                        </div>

                        <div className='section' onClick={() => { navigate("/admin-dashboard/event") }} style={{ fontWeight: "bold", color: "#5B64EB" }}>
                            My Events
                        </div>
{/* 
                        <div className='section' onClick={() => { navigate("/admin-dashboard/Support") }}>
                            Support
                        </div> */}
                    </div>
                    <div>
                        {currentUser.photoURL ? (
                            <img
                                src={currentUser.photoURL}
                                alt="User Profile"
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                            />
                        ) : (
                            <img
                                src="https://via.placeholder.com/50"  // Fallback image
                                alt="Default Profile"
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                            />
                        )}
                    </div>
                </div>
                <Divider style={{margin: "10px"}}/>


                <div className="my-events-container">
                    <div className="button-group">
                        <button className="new-event-button" onClick={handleCreateEventToggle}>
                            New Event +
                        </button>
                        <button className="action-button" onClick={handleRefresh}>
                            {refreshLoading ? (
                                <CircularProgress size={"16px"} thickness={10}/>
                            ) : (
                                <ReplayIcon style={{ fontSize: '16px' }} />
                            )}
                        </button>
                    </div>

                    {/* Table */}
                    <div className="table-container" >
                        <div className="table-header">
                            <div className="table-column">Event ID</div>
                            <div className="table-column">Title</div>

                            <div className="table-column" style={{ cursor: "pointer" }} onClick={() => { toggleSortOrder('birthDate'); }}>
                                Created On
                                <span className="sort-icon">
                                    {sortField === 'birthDate' ? (sortOrder === 'asc' ? '▲' : '▼') : '⚫'}
                                </span>
                            </div>

                            <div className="table-column" style={{ cursor: "pointer" }} onClick={() => { toggleSortOrder('click_count'); }}>
                                Clicks
                                <span className="sort-icon">
                                    {sortField === 'click_count' ? (sortOrder === 'asc' ? '▲' : '▼') : '⚫'}
                                </span>
                            </div>

                            <div className="table-column" style={{ cursor: "pointer" }} onClick={() => { toggleSortOrder('save_count'); }}>
                                Saves
                                <span className="sort-icon">
                                    {sortField === 'save_count' ? (sortOrder === 'asc' ? '▲' : '▼') : '⚫'}
                                </span>
                            </div>

                            <div className="table-column" style={{ cursor: "pointer" }} onClick={() => { toggleSortOrder('registration_count'); }}>
                                Registrations
                                <span className="sort-icon">
                                    {sortField === 'registration_count' ? (sortOrder === 'asc' ? '▲' : '▼') : '⚫'}
                                </span>
                            </div>

                            <div className="table-column" style={{ cursor: "pointer" }} onClick={() => { toggleSortOrder('attendance_count'); }}>
                                Attended
                                <span className="sort-icon">
                                    {sortField === 'attendance_count' ? (sortOrder === 'asc' ? '▲' : '▼') : '⚫'}
                                </span>
                            </div>

                            <div className="table-column">Status</div>
                        </div>

                        {/* Table content */}
                        <div className="table-body">
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                    <CircularProgress thickness={10} /> {/* MUI CircularProgress for spinner */}
                                </div>
                            ) : (
                                eventList.map(event => (
                        
                                    <div
                                        key={event._id}
                                        className="table-row"
                                        onClick={() => navigate(`/admin-dashboard/event/${event._id}`, { state: { event } })}
                                    >
                                        <div className="table-cell">
                                            {event._id}
                                        </div>
                                        <div className="table-cell" style={{ textAlign: "left" }}>
                                            {event.title}
                                        </div>
                                        <div className="table-cell">
                                            {new Date(event.birthDate * 1000).toLocaleDateString('en-GB', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric'
                                            })}
                                        </div>
                                        <div className="table-cell">{event.click_count}</div>
                                        <div className="table-cell">{event.save_count}</div>
                                        <div className="table-cell">{event.registration_count}</div>
                                        <div className="table-cell">{event.attendance_count}</div>
                                        <div
                                            className="table-cell"
                                            style={{
                                                color: event.status === 'Happening soon' ? 'green' :
                                                    event.status === 'Happening Now' ? 'blue' :
                                                        event.status === 'Finished' ? 'lightgrey' : 'black'
                                            }}
                                        >
                                            {event.status}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EventDetailsForm = ({ event, toggle, refresh }) => {

    const { currentUser, idToken } = useAuth();
    const [creatingEvent, setCreatingEvent] = useState(false);

    async function createEvent(event) {
        setCreatingEvent(true);
        event._id = uuidv4();

        // Check Dates & assign startDate and endDate
        const validateDateTimeArray = (dateTimeArray) => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            const maxEndDate = now + 30 * 24 * 60 * 60; // 30 days in the future from now (in seconds)

            if (dateTimeArray.length === 0) return null; // Handle empty array case

            // Initialize smallest start and largest end timestamps
            let overallStartDate = dateTimeArray[0].start;
            let overallEndDate = dateTimeArray[0].end;

            // Loop through each object in dateTimeArray to perform checks
            for (let i = 0; i < dateTimeArray.length; i++) {
                const { start, inform, end } = dateTimeArray[i];

                // Check if 'end' date is before 'start' date
                if (end < start) {
                    return false;
                }

                // Check if 'end' date is more than 30 days from now
                if (end > maxEndDate) {
                    return false;
                }

                // Update overall start date (smallest start timestamp)
                if (start < overallStartDate) {
                    overallStartDate = start;
                }

                // Update overall end date (largest end timestamp)
                if (end > overallEndDate) {
                    overallEndDate = end;
                }
            }

            // If all checks pass, return true and also return the overall start and end timestamps
            return {
                isValid: true,
                overallStartDate,
                overallEndDate,
            };
        };


        // Profanity Clearance
        const profanityClearance = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/eventProfanityClearance',
                    {
                        type: 'event',
                        data: {
                            title: event.title,
                            desc: event.desc
                        }
                    },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                return response.data['result'];


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        // Upload Image
        const uploadPosterToFirebase = async (file, eventId) => {
            if (!file) return;

            try {
                const storageRef = ref(storage, `eventPosters/${eventId}`);
                const metadata = {
                    contentType: file.type, // Use the file's type for correct MIME type
                };
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                // Rest of the upload logic...
                const snapshot = await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            // You can track upload progress here if you want
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            console.error("Upload failed:", error);
                            reject(error);
                        },
                        () => {
                            // Handle successful uploads
                            resolve(uploadTask.snapshot);
                        }
                    );
                });

                // Get the download URL for the uploaded file
                const downloadURL = await getDownloadURL(snapshot.ref);
                return downloadURL;
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        };

        // Update/Upload document
        const submitEvent = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/submitEventData',
                    event,
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                return response.data;


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };


        console.log("Date Validity Check");
        const dateCheck = validateDateTimeArray(event.dateTime);
        if (dateCheck.isValid) {
            event.startDate = dateCheck.overallStartDate;
            event.endDate = dateCheck.overallEndDate;
            console.log("Dates Valid");
            console.log("Profanity Check");
            const clear = profanityClearance(event);
            if (clear) {
                console.log("Profanity Clear!");
                event.poster = await uploadPosterToFirebase(event.poster, event._id);
            } else {
                console.log("Profanity Not Clear!");
                alert("Event Data contains profane or unsupported language, please identify potential profanity, broken links or unsupported language or text!");
                return;
            }

            console.log("Final Doc!");

            submitEvent(event);
            return;
        } else {
            alert("Invalid Event Dates");
            return;
        }






    }



    // Determine initial form data based on whether event is empty or not
    const initialFormData = event._id ? {
        // Event is not empty
        _id: event?._id || '',
        organizer_id: currentUser.uid,


        postsToggle: event.postsToggle,
        postPictures: event.postPictures,
        postReplies: event.postReplies,

        ticketToggle: event.ticketToggle,

        title: event.title,
        desc: event.desc,
        type: event.type,

        limit: event.limit,
        adult: event.adult,

        platformType: event.platformType,
        online: event?.online || [],
        inPerson: event?.inPerson || [],

        dateTime: event.dateTime,
        endDate: event.endDate,
        startDate: event.startDate,
        categories: event.categories,
        poster: event.poster,
    } : {
        // Event is empty
        _id: '',

        textColor: "white",
        organizer_id: currentUser.uid,
        going: [],
        invites: [
            {
                groupID: 'defaultInvites',
                groupName: 'defaultInvites',
                groupList: []
            }
        ],

        visible: true,
        posts: [],
        postsToggle: true,
        postPictures: true,
        postReplies: true,

        ticketToggle: true,

        flagged: [],
        title: null,
        desc: null,
        type: 'public',
        birthDate: 0,

        limit: 20,
        adult: false,

        platformType: 'inPerson',
        online: [],
        inPerson: [],
        hybrid: {
            inPerson: [],
            online: []
        },

        dateTime: [
            {
                start: Math.floor(Date.now() / 1000), // Default to current timestamp if not set
                end: Math.floor(Date.now() / 1000),   // Default to current timestamp if not set
                inform: Math.floor(Date.now() / 1000),
            }
        ],
        endDate: 0,
        startDate: 0,
        categories: [],
        tags: [],
        poster: '',
    };

    // Now call useState with the determined initialFormData
    const [formData, setFormData] = useState(initialFormData);


    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);

    // Load Google Places API with useLoadScript hook
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    // Ensure the hook isn't conditionally rendered.
    if (!isLoaded) {
        // Render early loading state if the script isn't loaded yet.
        return <div>Loading Google Maps...</div>;
    }

    // Handler for input change
    const handleInputChange = (field, value) => {

        if (field === 'limit') {
            setFormData({
                ...formData,
                [field]: parseInt(value),
            });
        } else {
            setFormData({
                ...formData,
                [field]: value,
            });
        }




    };

    const predictLocation = (value) => {

        if (!value.trim()) {
            // If the input is empty or just spaces, clear the predictions
            setPredictions([]);
            return; // Don't call the predictLocation function
        }

        // If platformType is 'inPerson', fetch predictions
        if (formData.platformType === 'inPerson' && value) {
            fetchPlacePredictions(value);
        }
    };

    // Function to fetch place predictions
    const fetchPlacePredictions = async (input) => {
        setLoading(true);
        try {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                { input, types: ['establishment'], componentRestrictions: { country: 'ca' } },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(predictions);
                    } else {
                        setPredictions([]);
                    }
                    setLoading(false);
                }
            );
        } catch (error) {
            console.error('Error fetching predictions:', error);
            setLoading(false);
        }
    };

    // Handler when a user selects a place from predictions
    const handlePlaceSelect = async (place) => {


        const details = await fetchPlaceDetails(place.place_id);
        let placeObject = {
            lng: details.geometry.location.lng(),
            lat: details.geometry.location.lat(),
            name: details.name,
            formattedAddress: details.formatted_address,
        };


        setFormData({
            ...formData,
            inPerson: [placeObject],
        });
        setPredictions([]); // Clear predictions after selection
    };

    const fetchPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails(
                { placeId },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(place);  // Resolve with the detailed place information
                    } else {
                        reject('Error fetching place details');
                    }
                }
            );
        });
    };

    // Handle selecting/deselecting categories
    const handleCategoryChange = (category) => {
        const updatedCategories = formData.categories.includes(category)
            ? formData.categories.filter((c) => c !== category) // Deselect
            : [...formData.categories, category]; // Select

        setFormData({
            ...formData,
            categories: updatedCategories,
        });
    };

    const onlineURlChange = (value) => {
        setFormData({
            ...formData,
            online: [value],
        });
    }

    const categories = [
        'Automotive',
        'Business & Professional',
        'Charity & Causes',
        'Family & Education',
        'Fashion & Beauty',
        'Film, Media & Entertainment',
        'Food & Drink',
        'Government & Politics',
        'Health & Wellness',
        'Hobbies & special interest',
        'Music',
        'Performing & visual arts',
        'Religion & spirituality',
        'School activities',
        'Science & technology',
        'Sports & fitness',
        'Travel & outdoor',
    ];

    const eventTypes = [
        'Appearance or Signing',
        'Attraction',
        'Camp, Trip or Retreat',
        'Class, Training or Workshop',
        'Concert or Performance',
        'Conference',
        'Convention',
        'Dinner or Gala',
        'Festival or Fair',
        'Game or Competition',
        'Party or Social Gathering',
        'Race or Endurance Event',
        'Rally',
        'Screening',
        'Seminar or Talk',
        'Tour',
        'Tournament',
        'Trade Show, Consumer Show or Expo',
        'Other',
    ];


    const formatDateToLocal = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleDateChange = (index, field, value) => {
        const updatedDates = formData.dateTime.map((date, idx) => {
            if (idx === index) {
                // Handle 'start' field changes
                if (field === 'start') {
                    const newInform = value - 24 * 60 * 60; // Set 'inform' 24 hours before the start

                    // Update 'start' and 'inform'
                    return {
                        ...date,
                        start: value,
                        inform: newInform
                    };
                }

                // Handle 'end' field changes
                if (field === 'end') {
                    // Update 'end' date
                    return {
                        ...date,
                        end: value
                    };
                }
            }
            return date; // Return unchanged date objects if they are not being updated
        });

        // Update the state with the modified dateTime array
        setFormData({
            ...formData,
            dateTime: updatedDates
        });
    };



    return (
        <div className="event-details">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ position: 'relative', width: '30%' }}>
                    <div
                        style={{
                            backgroundColor: formData.poster ? 'transparent' : '#f9f9f9', // Show red box only if no image
                            width: '100%',
                            paddingBottom: '100%', // Ensures a square box
                            position: 'relative', // Ensures elements inside are positioned correctly
                            borderRadius: '15px',
                            overflow: 'hidden', // Ensures the image stays inside the square
                        }}
                    >
                        {formData.poster && (

                            <img
                                src={
                                    formData.poster instanceof File
                                        ? URL.createObjectURL(formData.poster) // If it's a File, create a local URL
                                        : formData.poster // If it's a string (URL), use it directly
                                }
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover', // Ensures the image covers the entire square
                                    borderRadius: '15px',
                                }}
                            />
                        )}

                        {!formData.poster && (
                            <Image style={{
                                color: "#f0f0f0",
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '50%',
                                height: '50%',
                                borderRadius: '15px',
                            }} />
                        )}
                    </div>

                    <button
                        onClick={() => document.querySelector('input[type="file"]').click()}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1,
                            padding: '8px 12px',
                            backgroundColor: 'white',
                            color: '#007bff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Edit
                    </button>

                    <input
                        type="file"
                        onChange={(e) => handleInputChange('poster', e.target.files[0])}
                        style={{ display: 'none' }}
                    />

                    <div className="picker" style={{ marginTop: "20px" }}>
                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.adult}
                                onChange={() => setFormData({ ...formData, adult: !formData.adult })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Adult Event (19+)
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postsToggle}
                                onChange={() => setFormData({ ...formData, postsToggle: !formData.postsToggle })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Posts
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postPictures}
                                onChange={() => setFormData({ ...formData, postPictures: !formData.postPictures })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Pictures in Posts
                        </label>



                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postReplies}
                                onChange={() => setFormData({ ...formData, postReplies: !formData.postReplies })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Replies in Posts
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.ticketToggle}
                                onChange={() => setFormData({ ...formData, ticketToggle: !formData.ticketToggle })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Ticket Scanning
                        </label>
                    </div>

                    <button
                        className="create-event-button"
                        onClick={() => {
                            console.log(formData);

                            // Helper function to check if an array is empty
                            const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

                            // Validation messages
                            if (isEmptyArray(formData.categories)) {
                                return alert("Select Categories!");
                            }

                            if (formData.platformType === "inPerson" && isEmptyArray(formData.inPerson)) {
                                return alert("Enter a Location!");
                            }

                            if (formData.platformType === "online" && isEmptyArray(formData.online)) {
                                return alert("Enter a URL for this online event!");
                            }

                            if (formData.limit === 0) {
                                return alert("Limit cannot be 0!");
                            }

                            if (!formData.poster) {
                                return alert("Add an Image before uploading event!");
                            }

                            if (!formData.title) {
                                return alert("Don't forget to add a Title!");
                            }

                            if (!formData.desc) {
                                return alert("Don't forget to add an event Description!");
                            }

                            // If all validations pass, create the event
                            createEvent(formData).then(()=>{
                                alert("Event Created Successfully!");
                                refresh();
                                toggle();
                            });
                        }}
                    >
                        {creatingEvent ? (
        <span>Creating Event...</span> // Show progress text or spinner
    ) : (
        <span>Create Event +</span> // Original button text
    )}
                    </button>



                </div>


                <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'column', width: '70%' }}>
                    <input
                        type="text"
                        className="textfield"
                        value={formData.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                        placeholder="Event Title"
                    />
                    <input
                        type="text"
                        className="registration-textfield"
                        value={formData.registrationLink}
                        onChange={(e) => handleInputChange('registrationLink', e.target.value)}
                        placeholder="Registration Form Link"
                    />
                    <textarea
                        value={formData.desc}
                        className="desc-textfield"
                        onChange={(e) => handleInputChange('desc', e.target.value)}
                        placeholder="Event Description"
                    ></textarea>

                    {/* Limit */}
                    <input
                        type="number" // Use 'number' for numerical input
                        list="limit-options" // Link to the datalist
                        value={formData.limit} // Update this to match your state field
                        onChange={(e) => handleInputChange('limit', e.target.value)} // Update the field name accordingly
                        placeholder="Select or enter a limit"
                        className='limit-textfield'
                    />

                    <div className='datetime-container'>
                        {formData.dateTime && formData.dateTime.map((date, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                                    <label>Start</label>

                                    <label>End  </label>

                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <input
                                        type="datetime-local"
                                        className='datetime-picker'
                                        value={formatDateToLocal(date.start)}
                                        onChange={(e) => handleDateChange(index, 'start', new Date(e.target.value).getTime() / 1000)}
                                    />
                                    <input
                                        type="datetime-local"
                                        className='datetime-picker'
                                        value={formatDateToLocal(date.end)}
                                        onChange={(e) => handleDateChange(index, 'end', new Date(e.target.value).getTime() / 1000)}
                                    />
                                </div>


                            </div>
                        ))}
                    </div>


                    <Divider style={{ padding: "10px" }} />




                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "10px", gap: "10px" }}>
                        {/* Platform Type */}
                        <div>
                            <select
                                value={formData.platformType}
                                className="picker"
                                onChange={(e) => handleInputChange('platformType', e.target.value)}
                            >
                                <option value="inPerson">In-Person</option>
                                <option value="online">Online</option>
                            </select>
                        </div>

                        {/* Location Search Field */}
                        {formData.platformType === 'inPerson' && (
                            <div>
                                <input
                                    type="text"
                                    className="place-textfield"
                                    placeholder="Search a Place"
                                    onChange={(e) => { predictLocation(e.target.value) }}
                                />
                                {/* Prediction List */}
                                {
                                    formData.inPerson.length > 0 && (
                                        <div >
                                            {formData.inPerson.map((place) => (
                                                <div
                                                    key={place.lng}
                                                    className="place-item-active"
                                                    style={{ marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                                                >
                                                    {place.name}
                                                    <Delete
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                inPerson: [],
                                                            });
                                                        }} />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {loading ? (
                                    <div className='place-textfield'>Loading predictions...</div>
                                ) : (
                                    predictions.length > 0 && (
                                        <div >
                                            {predictions.map((prediction) => (
                                                <div
                                                    key={prediction.place_id}
                                                    className="place-item"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={() => handlePlaceSelect(prediction)}
                                                >
                                                    {prediction.description}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {formData.platformType === 'online' && (
                            <div>
                                <input
                                    type="text"
                                    className="place-textfield"
                                    placeholder="Paste a URL"
                                    onChange={(e) => { onlineURlChange(e.target.value) }}
                                />
                            </div>
                        )}


                        <Divider />

                        {/* Event Type Dropdown */}
                        <div>
                            <select
                                id="eventType"
                                className="picker"
                                value={formData.eventType}
                                onChange={(e) => handleInputChange('eventType', e.target.value)}
                            >
                                <option value="" disabled>
                                    Select an event type
                                </option>
                                {eventTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            {/* Categories Dropdown */}
                            <div className="picker">
                                {categories.map((category) => (
                                    <label className="custom-checkbox-label">
                                        <input
                                            type="checkbox"
                                            checked={formData.categories && formData.categories.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                            className="custom-checkbox"
                                        />
                                        <span className="custom-checkbox-box"></span>
                                        {category}
                                    </label>
                                ))}
                            </div>





                        </div>




                    </div>





                </div>
            </div>














        </div>
    );
};

export default EventsPage;
