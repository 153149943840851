import React, { useEffect, useState, useRef } from 'react';
import './EventAnalytics.css';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import { useAuth } from '../../contexts/authContext';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI Spinner
import ReplayIcon from '@mui/icons-material/Replay';

import Delete from "@mui/icons-material/Delete";
import Image from "@mui/icons-material/Image";
import '../Admin_Dashboard/EventDetailsForm.css'; // Import the CSS file
import Divider from "@mui/material/Divider";

import { useLoadScript } from '@react-google-maps/api';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { v4 as uuidv4 } from 'uuid'; // Import the v4 method from uuid to generate a random UUID
import { Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const libraries = ['places']; // Include 'places' for Google Places API
const apiKey = 'AIzaSyCm70028pa9kb8ojrhxMRtrOMxfJat0U_I'; // Replace with your actual API key

// Helper function to create Pie Charts
function createPieChart(elementId, data, title) {
    const labels = Object.keys(data);
    const series = Object.values(data);

    const options = {
        chart: {
            type: 'donut',
            fontFamily: 'Lexend, Century Gothic, sans-serif',
            width: "130%",
        },
        series,
        labels,
        legend: {
            show: false,
            position: 'bottom',
            fontSize: '10px',
            labels: {
                colors: '#333',
                useSeriesColors: false,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return opts.w.globals.series[opts.seriesIndex] + '\n (' + val.toFixed(1) + '%)';
            },
            style: {
                fontSize: '10px',
                colors: ['#fff'],
            },
        },
        tooltip: {
            theme: 'dark',
            style: {
                fontSize: '10px',
                fontFamily: 'Lexend, Century Gothic, sans-serif',
            },
            y: {
                formatter: function (value) {
                    return value + ' clicks';
                },
            },
        },
        colors: ['#8BC1F7', '#519DE9', '#06C', '#004B95', '#002F5D'],
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                },
            },
        },
        title: {
            text: title,
            align: 'center',
            margin: 20,
            offsetY: 10,
            style: {
                fontSize: '10px',
                fontFamily: 'Lexend, Century Gothic, sans-serif',
                fontWeight: '500',
                color: '#333',
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'center',
                },
            },
        }],
    };

    const chart = new ApexCharts(document.querySelector(elementId), options);
    chart.render();
    return chart;
}

const EventAnalytics = (eventObject) => {
    // Context and State Initialization
    const { currentUser, idToken } = useAuth();
    const { eventID } = eventObject._id;
    const location = useLocation();
    // Get the event from the location (from previous page)
    

    // Create state to hold the event data
    const [event, setEvent] = useState(eventObject);
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(false); // Toggle this to trigger useEffect


    // Chart states
    const [chartData, setChartData] = useState({
        series: [{
            name: 'Clicks',
            data: [],
        }],
        options: {
            chart: {
                type: 'line',
                height: 350,
                toolbar: { show: false },
                background: '#FFFFFF',
            },
            stroke: { curve: 'smooth', width: 2.5 },
            markers: { size: 6, hover: { sizeOffset: 4 } },
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'Date',
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                labels: {
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                tooltip: { enabled: false },
            },
            yaxis: {
                title: {
                    text: 'Number of Clicks',
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                labels: {
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Lexend, Century Gothic, sans-serif',
                        fontWeight: '500',
                        color: '#333',
                    },
                },
                tickAmount: 1,
                tooltip: { enabled: false },
            },
            tooltip: {
                theme: 'dark',
                x: { format: 'ddd, MMM dd, yyyy' },
                y: {
                    formatter: (val) => val,
                },
                style: {
                    fontSize: '12px',
                    fontFamily: 'Century Gothic, Lexend, Arial, sans-serif',
                    color: 'Black',
                },
            },
            colors: ['#519DE9'],
            grid: { show: false },
        },
    });

    const size = "15vw";
    const [currentMetric, setCurrentMetric] = useState('clicks');
    const [eventLoading, setEventLoading] = useState(true);
    const [piesLoading, setPiesLoading] = useState(true);
    const [clickRateLoading, setClickRateLoading] = useState(true);
    const [studentTableLoading, setStudentTableLoading] = useState(true);

    // Metrics states
    const [clickRateMetrics, setClickRateMetrics] = useState();
    const [clicksMetrics, setClickMetrics] = useState();
    const [savedMetrics, setSavedMetrics] = useState();
    const [registrationMetrics, setRegistrationMetrics] = useState();
    const [attendanceMetrics, setAttendanceMetrics] = useState();
    const [studentLists, setStudentLists] = useState();

    // References for charts to allow destruction of previous charts
    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);
    const chart3Ref = useRef(null);
    const chart4Ref = useRef(null);

    // Function to create pie charts for metrics
    function PieCharts(metrics) {
        if (chart1Ref.current) chart1Ref.current.destroy();
        if (chart2Ref.current) chart2Ref.current.destroy();
        if (chart3Ref.current) chart3Ref.current.destroy();
        if (chart4Ref.current) chart4Ref.current.destroy();

        chart1Ref.current = createPieChart('#event-chart1', metrics.faculty, "Faculty");
        chart2Ref.current = createPieChart('#event-chart2', metrics.origin, "Student Type");
        chart3Ref.current = createPieChart('#event-chart3', metrics.level, "Program");
        chart4Ref.current = createPieChart('#event-chart4', metrics.yearJoined, "Year Joined");
    }

    // Fetch event data to update event details
    useEffect(() => {
        setEventLoading(true);
        const fetchEventData = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getEventData',
                    { eventID: event?._id }, // Use initial event ID for the fetch
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                // Update the event state with the fresh data
                setEvent(response.data);
                setVisible(response.data.visible);
                setEventLoading(false);
            } catch (err) {
                console.error(`Error fetching Event: ${err}`);
            }
        };

        // Only fetch if initialEvent exists
        if (event?._id) {
            fetchEventData();
        }
    }, [event, idToken, refresh]);

    // Fetch Metrics for Event
    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getEventMetrics',
                    { eventID: event._id },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                setClickMetrics(response.data.clickMetrics);
                setSavedMetrics(response.data.savedMetrics);
                setRegistrationMetrics(response.data.registrationMetrics);
                setAttendanceMetrics(response.data.attendanceMetrics);
                setPiesLoading(false);
            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        fetchMetrics();
    }, [event._id, idToken, refresh]);

    // Fetch Student Lists for Event
    useEffect(() => {
        const fetchStudentLists = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getStudentLists',
                    { eventID: event._id },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                setStudentLists(response.data);
                setStudentTableLoading(false);
            } catch (err) {
                console.error(`Error fetching Student Lists: ${err}`);
            }
        };

        fetchStudentLists();
    }, [event._id, idToken, refresh]);

    // Handle metric selection and update charts
    useEffect(() => {
        if (currentMetric === 'clicks' && clicksMetrics) {
            PieCharts(clicksMetrics);
        } else if (currentMetric === 'saved' && savedMetrics) {
            PieCharts(savedMetrics);
        } else if (currentMetric === 'registration' && registrationMetrics) {
            PieCharts(registrationMetrics);
        } else if (currentMetric === 'attendance' && attendanceMetrics) {
            PieCharts(attendanceMetrics);
        }
    }, [currentMetric, clicksMetrics, savedMetrics, registrationMetrics, attendanceMetrics, refresh]);

    // Fetch Click Rate Data
    useEffect(() => {
        const fetchClickRate = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/getClickRateMetrics',
                    { eventID: event._id },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                setClickRateMetrics(response.data);
                setClickRateLoading(false);
            } catch (err) {
                console.error(`Error fetching Click Rate Metrics: ${err}`);
            }
        };

        fetchClickRate();
    }, [event._id, idToken, refresh]);

    // Transform Click Rate Data for Line Chart
    useEffect(() => {
        if (clickRateMetrics && clickRateMetrics.length > 0) {
            const transformedData = clickRateMetrics.map(item => {
                const [date, count] = Object.entries(item)[0];
                return {
                    x: new Date(date).toISOString(),
                    y: count,
                };
            });

            setChartData(prevState => ({
                ...prevState,
                series: [{ ...prevState.series[0], data: transformedData }],
            }));
        }
    }, [clickRateMetrics, refresh]);

    const handleRefresh = () => {
        setEventLoading(true);
        setRefresh(prev => !prev); // Toggle the `refresh` state to re-trigger useEffect
    };

    const [editEvent, setEditEvent] = useState(false); // New state to toggle the modal
    const handleEditEventToggle = () => {
        setEditEvent(prev => !prev); // Toggle the modal state
    };

    const [visible, setVisible] = useState();
    const [visibilityChanging, setVisibilityChanging] = useState(false);
    const toggleVisibility = async () => {
        setVisibilityChanging(true);
        try {
            const response = await axios.post(
                'https://us-central1-resevents-e239e.cloudfunctions.net/toggleEventVisibility',
                { eventID: event._id },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
            );

            setVisible(response.data.visible);
            setVisibilityChanging(false);

            
        } catch (err) {
            console.error(`Error fetching Click Rate Metrics: ${err}`);
        }

    }



    return (
        <div className="event-container">

            {editEvent && (
                <div className="modal" onClick={handleEditEventToggle}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()} // Prevent the event from bubbling up
                    >
                        <EventDetailsForm event={event} toggle={handleEditEventToggle} refresh={handleRefresh} setEvent={setEvent} />
                    </div>
                </div>

            )}

            {/* Section 1 */}
            <div className="event-section event-section1">
                <Button onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                </Button>
                <div style={{ paddingLeft: "20px" }}>
                    <h2 style={{ display: 'flex', alignItems: 'center' }}>
                        {event.title}

                        <a
                            href={`/event/host/${event._id}`} // Correct string interpolation
                            style={{ marginLeft: '8px', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
                        >
                            <LinkIcon />
                        </a>
                    </h2>


                    {/* Action Buttons */}
                    <div className='event-button-group'>
                        <button className="event-action-button" style={{ backgroundColor: "black", color: "white" }} onClick={() => handleEditEventToggle()}>
                            {eventLoading ? (
                                <CircularProgress size={"16px"} thickness={10}/>
                            ) : (
                                "Edit"

                            )}
                        </button>
                        <button className="event-action-button" onClick={toggleVisibility}>
                            <Tooltip title="Toggles the visibility of the event in the student application">
                                {visibilityChanging ? (
                                    <CircularProgress size={"16px"} thickness={10} />
                                ) : (
                                    visible ? "Hide" : "Show"
                                )}
                            </Tooltip>
                        </button>

                        <button className="event-tag-button" onClick={() => { alert("This feature is still being developed!") }}>AI Analytics ✦</button>
                        <button className="event-action-button" onClick={() => { if (!eventLoading) handleRefresh() }}>
                            {eventLoading ? (
                                <CircularProgress size={"16px"} thickness={10} />
                            ) : (
                                <ReplayIcon style={{ fontSize: '16px' }} />

                            )}
                        </button>
                    </div>

                    {/* Metric Selection Buttons */}
                    <div className='event-button-group'>
                        <MetricButton
                            label="Clicks"
                            count={event.click_count}
                            active={currentMetric === 'clicks'}
                            setMetric={() => { setCurrentMetric('clicks') }}
                            changeChart={() => { PieCharts(clicksMetrics) }}
                            piesLoading={piesLoading}

                        />
                        <MetricButton
                            label="Saves"
                            count={event.save_count}
                            active={currentMetric === 'saved'}
                            setMetric={() => { setCurrentMetric('saved') }}
                            changeChart={() => { PieCharts(savedMetrics) }}
                            piesLoading={piesLoading}
                        />
                        <MetricButton
                            label="Registrations"
                            count={event.registration_count}
                            active={currentMetric === 'registration'}
                            setMetric={() => { setCurrentMetric('registration') }}
                            changeChart={() => { PieCharts(registrationMetrics) }}
                            piesLoading={piesLoading}
                        />
                        <MetricButton
                            label="Attended"
                            count={event.attendance_count}
                            active={currentMetric === 'attendance'}
                            setMetric={() => { setCurrentMetric('attendance') }}
                            changeChart={() => { PieCharts(attendanceMetrics) }}
                            piesLoading={piesLoading}
                        />
                    </div>

                    {/* Pie Charts */}
                    {piesLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
                                <div id="event-chart1" className="event-chart" style={{ width: size, height: size, display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                                <div id="event-chart2" className="event-chart" style={{ width: size, height: size, display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
                                <div id="event-chart3" className="event-chart" style={{ width: size, height: size, display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                                <div id="event-chart4" className="event-chart" style={{ width: size, height: size, display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Section 2: Table and Line Chart */}
            <div className="event-section event-section2" style={{ flex: "1", padding: "20px", boxSizing: "border-box" }}>
                <h2>Section 2</h2>

                {/* Table */}
                <div className="event-table-container" style={{height: currentMetric === "clicks" ? "40vh": "80vh"}}>
                    <TableHeader />
                    <div className="event-table-body">
                        {studentTableLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <StudentTable currentMetric={currentMetric} studentLists={studentLists} />
                        )}
                    </div>
                </div>

                {/* Click Rate Chart */}
                {currentMetric === "clicks" && (
    clickRateLoading ? (
        <LoadingSpinner />
    ) : (
        <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
        />
    )
)}

            </div>
        </div>
    );
}

function MetricButton({ label, count, active, setMetric, changeChart, piesLoading }) {
    return (
        <div 
            className={`event-metric-button ${active ? 'event-metric-button-active' : ''}`} 
            onClick={() => {
                if (!piesLoading) { // Only allow the onClick if piesLoading is false
                    setMetric();
                    if (changeChart) changeChart();
                }
            }} 
            style={{
                opacity: piesLoading ? 0.5 : 1, // Dim the button when loading
                pointerEvents: piesLoading ? 'none' : 'auto', // Disable interaction when loading
                cursor: piesLoading ? 'not-allowed' : 'pointer', // Show 'not-allowed' cursor when loading
            }}
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ textAlign: "start", fontSize: "10px", color: "#B6B6B6" }}>{label}</div>
                <div style={{ textAlign: "center", fontSize: "50px", fontWeight: "700" }}>{count}</div>
            </div>
        </div>
    );
}


// Separate StudentTable Component
function StudentTable({ currentMetric, studentLists }) {
    const getStudentList = () => {
        if (currentMetric === 'clicks') return studentLists.clickStudents;
        if (currentMetric === 'saved') return studentLists.savedStudents;
        if (currentMetric === 'registration') return studentLists.registrationStudents;
        if (currentMetric === 'attendance') return studentLists.attendanceStudents;
        return [];
    };

    return getStudentList()?.map(student => (
        <div key={student._id} className="event-table-row">
            <div className="event-table-cell">{student.studentNumber}</div>
            <div className="event-table-cell" style={{ textAlign: "left" }}>{student.name}</div>
            <div className="event-table-cell">{student.email}</div>
            <div className="event-table-cell">{student.yearJoined}</div>
            <div className="event-table-cell">{student.origin}</div>
            <div className="event-table-cell">{student.level}</div>
        </div>
    ));
}

// Separate TableHeader Component
function TableHeader() {
    return (
        <div className="event-table-header">
            <div className="event-table-column">Student ID</div>
            <div className="event-table-column">Name</div>
            <div className="event-table-column">Email</div>
            <div className="event-table-column">Year Joined</div>
            <div className="event-table-column">Student Type</div>
            <div className="event-table-column">Level</div>
        </div>
    );
}

// Reusable LoadingSpinner Component
function LoadingSpinner() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CircularProgress thickness={10}/>
        </div>
    );
}


//Edit Event
const EventDetailsForm = ({ event, toggle, refresh, setEvent }) => {

    const { currentUser, idToken } = useAuth();
    const [editEvent, setEditEvent] = useState(false);

    async function updateEvent(event) {
        setEditEvent(true);

        // Check Dates & assign startDate and endDate
        const validateDateTimeArray = (dateTimeArray) => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            const maxEndDate = now + 30 * 24 * 60 * 60; // 30 days in the future from now (in seconds)

            if (dateTimeArray.length === 0) return null; // Handle empty array case

            // Initialize smallest start and largest end timestamps
            let overallStartDate = dateTimeArray[0].start;
            let overallEndDate = dateTimeArray[0].end;

            // Loop through each object in dateTimeArray to perform checks
            for (let i = 0; i < dateTimeArray.length; i++) {
                const { start, inform, end } = dateTimeArray[i];

                // Check if 'end' date is before 'start' date
                if (end < start) {
                    return false;
                }

                // Check if 'end' date is more than 30 days from now
                if (end > maxEndDate) {
                    return false;
                }

                // Update overall start date (smallest start timestamp)
                if (start < overallStartDate) {
                    overallStartDate = start;
                }

                // Update overall end date (largest end timestamp)
                if (end > overallEndDate) {
                    overallEndDate = end;
                }
            }

            // If all checks pass, return true and also return the overall start and end timestamps
            return {
                isValid: true,
                overallStartDate,
                overallEndDate,
            };
        };


        // Profanity Clearance
        const profanityClearance = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/eventProfanityClearance',
                    {
                        type: 'event',
                        data: {
                            title: event.title,
                            desc: event.desc
                        }
                    },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                return response.data['result'];


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        // Upload Image
        const uploadPosterToFirebase = async (file, eventId) => {
            if (!file) return;

            try {
                const storageRef = ref(storage, `eventPosters/${eventId}`);
                const metadata = {
                    contentType: file.type, // Use the file's type for correct MIME type
                };
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                // Rest of the upload logic...
                const snapshot = await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            // You can track upload progress here if you want
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            console.error("Upload failed:", error);
                            reject(error);
                        },
                        () => {
                            // Handle successful uploads
                            resolve(uploadTask.snapshot);
                        }
                    );
                });

                // Get the download URL for the uploaded file
                const downloadURL = await getDownloadURL(snapshot.ref);

                return downloadURL;
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        };

        // Update/Upload document
        const updateEvent = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/updateEventData',
                    event,
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                setEvent(response.data);
                return;


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };


        console.log("Date Validity Check");
        const dateCheck = validateDateTimeArray(event.dateTime);

        if (dateCheck.isValid) {
            event.startDate = dateCheck.overallStartDate;
            event.endDate = dateCheck.overallEndDate;
            console.log("Dates Valid");
            console.log("Profanity Check");
            const clear = profanityClearance(event);
            if (clear) {
                console.log("Profanity Clear!");
                event.poster = await uploadPosterToFirebase(event.poster, event._id);
            } else {
                console.log("Profanity Not Clear!");
                alert("Event Data contains profane or unsupported language, please identify potential profanity, broken links or unsupported language or text!");
                return;
            }

            console.log("Final Doc!");

            updateEvent(event);
            return;
        } else {
            alert("Invalid Event Dates");
            return;
        }






    }



    // Determine initial form data based on whether event is empty or not
    const initialFormData = event._id ? {
        // Event is not empty
        _id: event?._id || '',
        organizer_id: currentUser.uid,


        postsToggle: event.postsToggle,
        postPictures: event.postPictures,
        postReplies: event.postReplies,

        ticketToggle: event.ticketToggle,

        title: event.title,
        desc: event.desc,
        type: event.type,

        limit: event.limit,
        adult: event.adult,

        platformType: event.platformType,
        online: event?.online || [],
        inPerson: event?.inPerson || [],

        dateTime: event.dateTime,
        endDate: event.endDate,
        startDate: event.startDate,
        categories: event.categories,
        poster: event.poster,
        registrationLink: event.registrationLink,
    } : {
        // Event is empty
        _id: '',

        textColor: "white",
        organizer_id: currentUser.uid,
        going: [],
        invites: [
            {
                groupID: 'defaultInvites',
                groupName: 'defaultInvites',
                groupList: []
            }
        ],

        visible: true,
        posts: [],
        postsToggle: true,
        postPictures: true,
        postReplies: true,

        ticketToggle: true,

        flagged: [],
        title: null,
        desc: null,
        type: 'public',
        birthDate: 0,

        limit: 20,
        adult: false,

        platformType: 'inPerson',
        online: [],
        inPerson: [],
        hybrid: {
            inPerson: [],
            online: []
        },

        dateTime: [
            {
                start: Math.floor(Date.now() / 1000), // Default to current timestamp if not set
                end: Math.floor(Date.now() / 1000),   // Default to current timestamp if not set
                inform: Math.floor(Date.now() / 1000),
            }
        ],
        endDate: 0,
        startDate: 0,
        categories: [],
        tags: [],
        poster: '',
    };

    // Now call useState with the determined initialFormData
    const [formData, setFormData] = useState(initialFormData);


    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);

    // Load Google Places API with useLoadScript hook
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    // Ensure the hook isn't conditionally rendered.
    if (!isLoaded) {
        // Render early loading state if the script isn't loaded yet.
        return <div>Loading Google Maps...</div>;
    }

    // Handler for input change
    const handleInputChange = (field, value) => {

        if (field === 'limit') {
            setFormData({
                ...formData,
                [field]: parseInt(value),
            });
        } else {
            setFormData({
                ...formData,
                [field]: value,
            });
        }




    };

    const predictLocation = (value) => {

        if (!value.trim()) {
            // If the input is empty or just spaces, clear the predictions
            setPredictions([]);
            return; // Don't call the predictLocation function
        }

        // If platformType is 'inPerson', fetch predictions
        if (formData.platformType === 'inPerson' && value) {
            fetchPlacePredictions(value);
        }
    };

    // Function to fetch place predictions
    const fetchPlacePredictions = async (input) => {
        setLoading(true);
        try {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                { input, types: ['establishment'], componentRestrictions: { country: 'ca' } },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(predictions);
                    } else {
                        setPredictions([]);
                    }
                    setLoading(false);
                }
            );
        } catch (error) {
            console.error('Error fetching predictions:', error);
            setLoading(false);
        }
    };

    // Handler when a user selects a place from predictions
    const handlePlaceSelect = async (place) => {


        const details = await fetchPlaceDetails(place.place_id);
        let placeObject = {
            lng: details.geometry.location.lng(),
            lat: details.geometry.location.lat(),
            name: details.name,
            formattedAddress: details.formatted_address,
        };


        setFormData({
            ...formData,
            inPerson: [placeObject],
        });
        setPredictions([]); // Clear predictions after selection
    };

    const fetchPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails(
                { placeId },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(place);  // Resolve with the detailed place information
                    } else {
                        reject('Error fetching place details');
                    }
                }
            );
        });
    };

    // Handle selecting/deselecting categories
    const handleCategoryChange = (category) => {
        const updatedCategories = formData.categories.includes(category)
            ? formData.categories.filter((c) => c !== category) // Deselect
            : [...formData.categories, category]; // Select

        setFormData({
            ...formData,
            categories: updatedCategories,
        });
    };

    const onlineURlChange = (value) => {
        setFormData({
            ...formData,
            online: [value],
        });
    }

    const categories = [
        'Automotive',
        'Business & Professional',
        'Charity & Causes',
        'Family & Education',
        'Fashion & Beauty',
        'Film, Media & Entertainment',
        'Food & Drink',
        'Government & Politics',
        'Health & Wellness',
        'Hobbies & special interest',
        'Music',
        'Performing & visual arts',
        'Religion & spirituality',
        'School activities',
        'Science & technology',
        'Sports & fitness',
        'Travel & outdoor',
    ];

    const eventTypes = [
        'Appearance or Signing',
        'Attraction',
        'Camp, Trip or Retreat',
        'Class, Training or Workshop',
        'Concert or Performance',
        'Conference',
        'Convention',
        'Dinner or Gala',
        'Festival or Fair',
        'Game or Competition',
        'Party or Social Gathering',
        'Race or Endurance Event',
        'Rally',
        'Screening',
        'Seminar or Talk',
        'Tour',
        'Tournament',
        'Trade Show, Consumer Show or Expo',
        'Other',
    ];


    const formatDateToLocal = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleDateChange = (index, field, value) => {
        const updatedDates = formData.dateTime.map((date, idx) => {
            if (idx === index) {
                // Handle 'start' field changes
                if (field === 'start') {
                    const newInform = value - 24 * 60 * 60; // Set 'inform' 24 hours before the start

                    // Update 'start' and 'inform'
                    return {
                        ...date,
                        start: value,
                        inform: newInform
                    };
                }

                // Handle 'end' field changes
                if (field === 'end') {
                    // Update 'end' date
                    return {
                        ...date,
                        end: value
                    };
                }
            }
            return date; // Return unchanged date objects if they are not being updated
        });

        // Update the state with the modified dateTime array
        setFormData({
            ...formData,
            dateTime: updatedDates
        });
    };



    return (
        <div className="event-details">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ position: 'relative', width: '30%' }}>
                    <div
                        style={{
                            backgroundColor: formData.poster ? 'transparent' : '#f9f9f9', // Show red box only if no image
                            width: '100%',
                            paddingBottom: '100%', // Ensures a square box
                            position: 'relative', // Ensures elements inside are positioned correctly
                            borderRadius: '15px',
                            overflow: 'hidden', // Ensures the image stays inside the square
                        }}
                    >
                        {formData.poster && (

                            <img
                                src={
                                    formData.poster instanceof File
                                        ? URL.createObjectURL(formData.poster) // If it's a File, create a local URL
                                        : formData.poster // If it's a string (URL), use it directly
                                }
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover', // Ensures the image covers the entire square
                                    borderRadius: '15px',
                                }}
                            />
                        )}

                        {!formData.poster && (
                            <Image style={{
                                color: "#f0f0f0",
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '50%',
                                height: '50%',
                                borderRadius: '15px',
                            }} />
                        )}
                    </div>

                    <button
                        onClick={() => document.querySelector('input[type="file"]').click()}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 1,
                            padding: '8px 12px',
                            backgroundColor: 'white',
                            color: '#007bff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Edit
                    </button>

                    <input
                        type="file"
                        onChange={(e) => handleInputChange('poster', e.target.files[0])}
                        style={{ display: 'none' }}
                    />

                    <div className="picker" style={{ marginTop: "20px" }}>
                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.adult}
                                onChange={() => setFormData({ ...formData, adult: !formData.adult })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Adult Event (19+)
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postsToggle}
                                onChange={() => setFormData({ ...formData, postsToggle: !formData.postsToggle })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Posts
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postPictures}
                                onChange={() => setFormData({ ...formData, postPictures: !formData.postPictures })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Pictures in Posts
                        </label>



                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.postReplies}
                                onChange={() => setFormData({ ...formData, postReplies: !formData.postReplies })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Replies in Posts
                        </label>

                        <label className="custom-checkbox-label">
                            <input
                                type="checkbox"
                                checked={formData.ticketToggle}
                                onChange={() => setFormData({ ...formData, ticketToggle: !formData.ticketToggle })}
                                className="custom-checkbox"
                            />

                            <span className="custom-checkbox-box"></span>
                            Allow Ticket Scanning
                        </label>
                    </div>

                    <button
                        className="create-event-button"
                        onClick={() => {
                            console.log(formData);

                            // Helper function to check if an array is empty
                            const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

                            // Validation messages
                            if (isEmptyArray(formData.categories)) {
                                return alert("Select Categories!");
                            }

                            if (formData.platformType === "inPerson" && isEmptyArray(formData.inPerson)) {
                                return alert("Enter a Location!");
                            }

                            if (formData.platformType === "online" && isEmptyArray(formData.online)) {
                                return alert("Enter a URL for this online event!");
                            }

                            if (formData.limit === 0) {
                                return alert("Limit cannot be 0!");
                            }

                            if (!formData.poster) {
                                return alert("Add an Image before uploading event!");
                            }

                            if (!formData.title) {
                                return alert("Don't forget to add a Title!");
                            }

                            if (!formData.desc) {
                                return alert("Don't forget to add an event Description!");
                            }

                            // If all validations pass, create the event
                            updateEvent(formData).then(()=>{
                                alert("Event Edited Successfully!");
                                refresh();
                                toggle();
                            });
                        }}
                    >
                        {editEvent ? (
        <span>Editing Event...</span> // Show progress text or spinner
    ) : (
        <span>Submit Changes</span> // Original button text
    )}
                    </button>



                </div>


                <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'column', width: '70%' }}>
                    <input
                        type="text"
                        className="textfield"
                        value={formData.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                        placeholder="Event Title"
                    />
                    <input
                        type="text"
                        className="registration-textfield"
                        value={formData.registrationLink}
                        onChange={(e) => handleInputChange('registrationLink', e.target.value)}
                        placeholder="Registration Form Link"
                    />
                    <textarea
                        value={formData.desc}
                        className="desc-textfield"
                        onChange={(e) => handleInputChange('desc', e.target.value)}
                        placeholder="Event Description"
                    ></textarea>

                    {/* Limit */}
                    <input
                        type="number" // Use 'number' for numerical input
                        list="limit-options" // Link to the datalist
                        value={formData.limit} // Update this to match your state field
                        onChange={(e) => handleInputChange('limit', e.target.value)} // Update the field name accordingly
                        placeholder="Select or enter a limit"
                        className='limit-textfield'
                    />

                    <div className='datetime-container'>
                        {formData.dateTime && formData.dateTime.map((date, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                                    <label>Start</label>

                                    <label>End  </label>

                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <input
                                        type="datetime-local"
                                        className='datetime-picker'
                                        value={formatDateToLocal(date.start)}
                                        onChange={(e) => handleDateChange(index, 'start', new Date(e.target.value).getTime() / 1000)}
                                    />
                                    <input
                                        type="datetime-local"
                                        className='datetime-picker'
                                        value={formatDateToLocal(date.end)}
                                        onChange={(e) => handleDateChange(index, 'end', new Date(e.target.value).getTime() / 1000)}
                                    />
                                </div>


                            </div>
                        ))}
                    </div>


                    <Divider style={{ padding: "10px" }} />




                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "10px", gap: "10px" }}>
                        {/* Platform Type */}
                        <div>
                            <select
                                value={formData.platformType}
                                className="picker"
                                onChange={(e) => handleInputChange('platformType', e.target.value)}
                            >
                                <option value="inPerson">In-Person</option>
                                <option value="online">Online</option>
                            </select>
                        </div>

                        {/* Location Search Field */}
                        {formData.platformType === 'inPerson' && (
                            <div>
                                <input
                                    type="text"
                                    className="place-textfield"
                                    placeholder="Search a Place"
                                    onChange={(e) => { predictLocation(e.target.value) }}
                                />
                                {/* Prediction List */}
                                {
                                    formData.inPerson.length > 0 && (
                                        <div >
                                            {formData.inPerson.map((place) => (
                                                <div
                                                    key={place.lng}
                                                    className="place-item-active"
                                                    style={{ marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                                                >
                                                    {place.name}
                                                    <Delete
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                inPerson: [],
                                                            });
                                                        }} />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                                {loading ? (
                                    <div className='place-textfield'>Loading predictions...</div>
                                ) : (
                                    predictions.length > 0 && (
                                        <div >
                                            {predictions.map((prediction) => (
                                                <div
                                                    key={prediction.place_id}
                                                    className="place-item"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={() => handlePlaceSelect(prediction)}
                                                >
                                                    {prediction.description}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {formData.platformType === 'online' && (
                            <div>
                                <input
                                    type="text"
                                    className="place-textfield"
                                    placeholder="Paste a URL"
                                    onChange={(e) => { onlineURlChange(e.target.value) }}
                                />
                            </div>
                        )}


                        <Divider />

                        {/* Event Type Dropdown */}
                        <div>
                            <select
                                id="eventType"
                                className="picker"
                                value={formData.eventType}
                                onChange={(e) => handleInputChange('eventType', e.target.value)}
                            >
                                <option value="" disabled>
                                    Select an event type
                                </option>
                                {eventTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            {/* Categories Dropdown */}
                            <div className="picker">
                                {categories.map((category) => (
                                    <label className="custom-checkbox-label">
                                        <input
                                            type="checkbox"
                                            checked={formData.categories && formData.categories.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                            className="custom-checkbox"
                                        />
                                        <span className="custom-checkbox-box"></span>
                                        {category}
                                    </label>
                                ))}
                            </div>





                        </div>




                    </div>





                </div>
            </div>














        </div>
    );
};

export default EventAnalytics;

