import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async'; // Add this import
import { Divider, Skeleton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import app_logo from '../../app_logo.png';
import ColorThief from 'colorthief';
import { text } from '@fortawesome/fontawesome-svg-core';


export const getDominantColorWithOpacity = async (imageUrl, opacity = 0.7) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = imageUrl;

        img.onload = () => {
            try {
                const colorThief = new ColorThief();
                const dominantColor = colorThief.getColor(img); // Returns [r, g, b]

                // Ensure the opacity value is valid
                const clampedOpacity = Math.max(0, Math.min(opacity, 1));

                // Return the color in rgba format with the specified opacity
                resolve(`rgba(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]}, ${clampedOpacity})`);
            } catch (error) {
                reject('Failed to extract dominant color: ' + error.message);
            }
        };

        img.onerror = () => {
            reject('Failed to load image.');
        };
    });
};
const EventPage = () => {

    const navigate = useNavigate();

    const { eventID } = useParams(); // Get eventID from URL params
    const [eventObject, setEventObject] = useState(null); // Initialize state as null
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    const [backgroundColor, setBackgroundColor] = useState('#fafafa');
    const [textColor, setTextColor] = useState("#141414");




    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axios.post(
                    `https://us-central1-resevents-e239e.cloudfunctions.net/getEventInfoHttps?eventID=${eventID}`
                );

                if (response.data.poster) {
                    getDominantColorWithOpacity(response.data.poster)
                        .then((color) => {
                            setBackgroundColor(color);
                            setTextColor("#f3f3f3")
                        })
                        .catch((error) => console.error(error));
                    setEventObject(response.data);
                }

            } catch (err) {
                console.error('Error fetching event data:', err);
                setError('Failed to load event data.');
            } finally {
                setLoading(false);
            }
        };
        fetchEvent();
    }, [eventID]);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const formattedDate = date.toLocaleDateString("en-US", {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
        return `${formattedDate} at ${formattedTime}`;
    };

    const parseLinksInText = (text) => {
        const urlRegex = /\b(https?:\/\/\S+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#6fa8dc" }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    const formatLocations = (locations, type = 'inPerson') => {
        if (!locations || locations.length === 0) return 'No locations available';
        return locations.map((location, index) => (
            <div key={index} style={{ marginBottom: '5px' }}>
                <a
                    href={`http://maps.google.com/maps?z=12&t=m&q=loc:${location.lat}+${location.lng}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#141414", textDecoration: "none" }}
                >
                    {location.formattedAddress}
                </a>
            </div>
        ));
    };

    function callToAction() {
        if (isMobile)
            return (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div
                        style={{
                            backgroundColor: "#4a148c",
                            borderRadius: "12px",
                            padding: "10px 20px",

                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            marginBottom: "20px",
                        }}
                    >
                        {/* Left Section */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={app_logo}
                                alt="App Logo"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "8px",
                                    marginRight: "15px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                            />
                            <div>
                                <h4
                                    style={{
                                        margin: 0,
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        fontFamily: "Lexend, sans-serif",
                                        fontSize: "18px",
                                    }}
                                >
                                    Download Engage
                                </h4>
                                <p
                                    style={{
                                        margin: 0,
                                        color: "#e0e0e0",
                                        fontSize: "14px",
                                        fontFamily: "Lexend, sans-serif",
                                    }}
                                >
                                    Stay connected with your campus!
                                </p>
                            </div>
                        </div>

                        {/* Right Section */}
                        <button
                            onClick={() =>
                                window.open(
                                    "https://us-central1-resevents-e239e.cloudfunctions.net/redirectToAppStore"
                                )
                            }
                            style={{
                                backgroundColor: "#ffffff",
                                borderRadius: "20px",
                                height: "40px",
                                width: "80px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "grey",
                                //color: "#4a148c",
                                fontWeight: "400",
                                border: "none",
                                cursor: "pointer",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = "scale(1.05)";
                                e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.3)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = "scale(1)";
                                e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
                            }}
                        >
                            Get
                        </button>
                    </div>
                </div>


            );
    }

    const generateMetaDescription = (event) => {
        if (!event) return '';
        const dateStr = event.dateTime[0] ? formatDateTime(event.dateTime[0].start) : '';
        const desc = event.desc || '';
        // Limit description to ~155 characters for SEO
        return `${dateStr}. ${desc.substring(0, 155)}...`;
    };




    if (loading) {

        if (isMobile) {
            return (

                <>
                    <Helmet>
                        <title>Loading Event... | Engage</title>
                    </Helmet>
                    <div style={{ backgroundColor: "#fafafa", height: "100vh", justifyContent: "center", marginTop: "0px", overflowX: "hidden" }}>
                        <div style={{ width: "100%", marginTop: "50px", display: "flex", flexDirection: "column", alignItems: "center" }}>

                            <Skeleton variant="rectangular" width={300} height={300} style={{ borderRadius: "20px", marginBottom: "20px" }} />
                            <Skeleton variant="text" width={300} height={40} style={{ marginBottom: "10px" }} />
                            <Skeleton variant="text" width={200} height={30} style={{ marginBottom: "10px" }} />
                            <Skeleton variant="text" width={300} height={20} style={{ marginBottom: "10px" }} />
                            <Skeleton variant="text" width={300} height={20} style={{ marginBottom: "10px" }} />
                        </div>
                    </div>
                </>

            );

        } else {
            return (

                <>
                    <Helmet>
                        <title>Loading Event... | Engage</title>
                    </Helmet>
                    <div style={{ backgroundColor: "#fafafa", height: "100vh", display: "flex", justifyContent: "center", alignItems: "flex-start", marginTop: "0px", overflowX: "hidden" }}>
                        <div style={{ width: "90%", maxWidth: "800px", marginTop: "50px" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Skeleton variant="rectangular" width={300} height={300} style={{ borderRadius: "20px", marginBottom: "20px", marginRight: "20px" }} />
                                <div>
                                    <Skeleton variant="text" width={500} height={40} style={{ marginBottom: "10px" }} />
                                    <Skeleton variant="text" width={200} height={30} style={{ marginBottom: "10px" }} />
                                    <Skeleton variant="text" width={500} height={20} style={{ marginBottom: "10px" }} />
                                    <Skeleton variant="text" width={300} height={20} style={{ marginBottom: "10px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            );
        }

    }

    if (error) {

        return (
            <>
                <Helmet>
                    <title>Event Not Found | Engage</title>
                    <meta name="description" content="This event could not be found or is no longer available." />
                </Helmet>
                <div style={{ textAlign: "center", marginTop: "50px" }}>{error}</div>
            </>
        );
    }

    return (

        <>
            {eventObject && (
                <Helmet>
                    <title>{`${eventObject.title} | Engage`}</title>
                    <meta name="description" content={generateMetaDescription(eventObject)} />

                    {/* OpenGraph meta tags */}
                    <meta property="og:type" content="event" />
                    <meta property="og:title" content={eventObject.title} />
                    <meta property="og:description" content={generateMetaDescription(eventObject)} />
                    <meta property="og:image" content={eventObject.poster} />
                    <meta property="og:url" content={`https://engage.lunisity.com/event/${eventID}`} />
                    <meta property="og:site_name" content="Engage" />

                    {/* Twitter Card meta tags */}
                    <meta name="twitter:card" content="app_icon" />
                    <meta name="twitter:title" content={eventObject.title} />
                    <meta name="twitter:description" content={generateMetaDescription(eventObject)} />
                    <meta name="twitter:image" content={eventObject.poster} />

                    {/* Event schema markup for SEO */}
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Event",
                            "name": eventObject.title,
                            "description": eventObject.desc,
                            "startDate": new Date(eventObject.dateTime[0]?.start * 1000).toISOString(),
                            "endDate": new Date(eventObject.dateTime[0]?.end * 1000).toISOString(),
                            "location": {
                                "@type": eventObject.platformType === "online" ? "VirtualLocation" : "Place",
                                "name": eventObject.platformType === "online" ? "Online Event" :
                                    eventObject.inPerson?.[0]?.formattedAddress || "Location TBA"
                            },
                            "image": eventObject.poster,
                            "organizer": {
                                "@type": "Organization",
                                "name": "Lunisity Software Inc.",
                                "url": "https://www.lunisity.com"
                            }
                        })}
                    </script>
                </Helmet>
            )}
            <div
                style={{
                    backgroundColor: backgroundColor,
                    minHeight: "100vh", // Ensure full viewport height
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // Space between content and footer
                    alignItems: "center",
                }}
            >
                {/* Top Section (Event Content) */}
                <div
                    style={{
                        width: "100%",
                        maxWidth: "800px",
                        marginTop: "20px",
                        flex: "1", // Allows it to take available space
                    }}
                >
                    {/* Call to Download */}
                    {callToAction()}

                    {/* Event Content */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            flexWrap: isMobile ? "wrap" : "nowrap",
                            marginBottom: "20px",
                            paddingLeft: "20px",
                            paddingRight: "40px",
                        }}
                    >
                        {/* Event Poster */}
                        {eventObject.poster && (
                            <div
                                style={{
                                    position: 'relative',
                                    width: !isMobile ? "150%" : "100%",
                                    height: !isMobile ? "150%" : "",
                                    aspectRatio: "1/1", // This maintains a square aspect ratio. Use other ratios like "16/9" for different shapes
                                    borderRadius: "10px",
                                    marginBottom: "20px",
                                    marginRight: "20px",
                                    overflow: "hidden"
                                }}
                            >
                                {/* Background expanded image with dark overlay */}
                                <img
                                    src={eventObject.poster}
                                    alt="Event Poster"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        filter: 'brightness(0.7)',
                                        transform: 'scale(1.1)',
                                        zIndex: 1
                                    }}
                                />
                                {/* Main centered image */}
                                <img
                                    src={eventObject.poster}
                                    alt="Event Poster"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        zIndex: 2
                                    }}
                                />
                            </div>
                        )}

                        {/* Event Details */}
                        <div>
                            <h1
                                style={{
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    color: textColor,
                                }}
                            >
                                {eventObject.title}
                            </h1>

                            <div style={{ marginBottom: "10px", color: textColor, fontSize: "14px" }}>
                                {eventObject.categories && <span>{eventObject.categories.join(", ")}</span>}
                            </div>

                            <div style={{ marginBottom: "10px", color: textColor }}>
                                <h4>Date & Time</h4>
                                {eventObject.dateTime.map((dt, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            marginBottom: "5px",
                                            fontSize: "12px",
                                            color: textColor,
                                        }}
                                    >
                                        {formatDateTime(dt.start)} - {formatDateTime(dt.end)}
                                    </div>
                                ))}
                            </div>

                            {/* Register Button */}
                            <button
                                onClick={() =>
                                    navigate(`/event/${eventID}/register`, {
                                        state: { title: eventObject.title, eventID: eventID, uni: eventObject.uni, registrationLink: eventObject.registrationLink },
                                    })
                                }
                                style={{
                                    backgroundColor: "#8c52ff",
                                    width: "90%",
                                    color: "#fff",
                                    padding: "10px 25px",

                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "background-color 0.3s, transform 0.2s",
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "black";
                                    e.target.style.color = "white";
                                    e.target.style.transform = "scale(1.05)";
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#8c52ff";
                                    e.target.style.transform = "scale(1)";
                                }}
                            >
                                <EventIcon style={{ marginRight: "10px", width: "15px" }} /> Register
                            </button>

                            <div style={{ marginBottom: "10px", fontSize: "12px" }}>
                                <p style={{ color: textColor }}>{parseLinksInText(eventObject.desc)}</p>
                            </div>

                             {/* Locations */}
                        {eventObject.platformType === 'inPerson' && (
                                <div style={{ marginBottom: "20px", color: textColor }}>
                                    <h4>Location</h4>
                                    <a
                                        style={{ fontSize: "12px", display: "block", color: "#6fa8dc", marginBottom: "5px", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "10px" }}
                                    >
                                        {formatLocations(eventObject.inPerson)}
                                    </a>

                                </div>
                            )}

                            {eventObject.platformType === 'online' && (
                                <div style={{ marginBottom: "50px", color: textColor }}>
                                    <h4>Location</h4>
                                    {eventObject.online.map((link, index) => (
                                        <a
                                            key={index}
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"

                                            style={{ display: "block", color: "blue", marginBottom: "5px" }}
                                        >
                                            {link}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>

                       

                        

                        
                    </div>
                </div>

                {/* Footer */}
                <footer
                    style={{
                        backgroundColor: "#4a148c",
                        color: "white",
                        padding: "30px 20px",
                        width: "100%",
                        borderTop: "4px solid #8c52ff",
                        textAlign: "center",
                    }}
                >
                    &copy; {new Date().getFullYear()} Engage. All rights reserved.
                </footer>
            </div>



        </>

    );
};

export default EventPage;
