import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import { 
  Card, 
  CardContent, 
  IconButton, 
  Tooltip, 
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Chart from 'react-apexcharts';

const fetchStudentLifeCycleReport = async ({ queryKey }) => {
  const [_key, { timestamp, idToken }] = queryKey;
  const response = await axios.post(
    'https://us-central1-resevents-e239e.cloudfunctions.net/getStudentLifeCycleReport',
    { startDate: timestamp },
    { 
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${idToken}` 
      } 
    }
  );
  return response.data;
};

const LifeCycleChart = ({ semesterInfo }) => {
  const { idToken } = useAuth();
  
  const { data: categoriesData, isLoading, refetch } = useQuery(
    ['studentLifeCycle', { timestamp: semesterInfo.timestamp, idToken }],
    fetchStudentLifeCycleReport,
    {
      staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
      refetchOnWindowFocus: false,
    }
  );

  // Chart configuration
  const lineSeries = categoriesData ? Object.keys(categoriesData).map(category => ({
    name: category,
    data: categoriesData[category]
  })) : [];

  const weekLabels = Array.from({ length: 13 }, (_, i) => `Week ${i + 1}`);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        }
      },
      fontFamily: 'inherit',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      }
    },
    stroke: {
      curve: 'smooth',
      width: [2], // Single consistent line width for simplicity
      dashArray: [0], // Keep solid lines for spikes visualization
    },
    xaxis: {
      categories: weekLabels,
      title: {
        text: 'Weeks',
        style: {
          fontSize: '12px', // Adjusted font size for better readability
          fontWeight: 500
        }
      }
    },
    yaxis: {
      title: {
        text: 'Clicks',
        style: {
          fontSize: '12px', // Adjusted font size for better readability
          fontWeight: 500
        }
      },
      labels: {
        formatter: (value) => Math.round(value) // Round to whole numbers
      }
    },
    markers: {
      size: 6,
      hover: {
        size: 9,
        sizeOffset: 3
      },
      strokeWidth: 0,
      shape: [
        'circle', 'square', 'triangle', 'diamond', 'pentagon', 'star', 'hexagon'
      ], // Expanded shapes for better distinction
      colors: [
        // Education & Professional
        '#2E86C1', // Business & Professional - Deep Blue
        '#3498DB', // Family & Education - Light Blue
        '#85C1E9', // Science & technology - Sky Blue
        
        // Entertainment & Arts
        '#8E44AD', // Film, Media & Entertainment - Purple
        '#9B59B6', // Music - Light Purple
        '#C39BD3', // Performing & visual arts - Lavender
        
        // Health & Wellness
        '#27AE60', // Health & Wellness - Green
        '#2ECC71', // Sports & fitness - Light Green
        
        // Community & Social
        '#E67E22', // Charity & Causes - Orange
        '#F39C12', // Government & Politics - Light Orange
        '#F1C40F', // Religion & spirituality - Yellow
        
        // Lifestyle
        '#E74C3C', // Food & Drink - Red
        '#EC7063', // Fashion & Beauty - Light Red
        
        // Other
        '#34495E', // Automotive - Dark Gray
        '#16A085'  // Travel & outdoor - Teal
      ], // Varying marker colors
      filter: {
        enabled: true,
        type: 'spike', // Filter to show only spikes (significant changes)
        threshold: 10, // Customize threshold to define spikes
      }
    },
    tooltip: {
      shared: false, // Disable shared tooltips
      intersect: true, // Enable intersect mode for single marker value display
      theme: 'light',
      y: {
        formatter: (val) => `${val.toLocaleString()} clicks`
      },
      marker: {
        show: true,
      },
      onDatasetHover: {
        highlightDataSeries: true,
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 10,
      fontSize: '10px', // Reduced font size for cleaner labels
      labels: {
        colors: '#969696' // Adjusted text color for legend labels
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 12,
        offsetX: -2, // Added space between marker and label
      },
      onItemHover: {
        highlightDataSeries: true
      },
      onItemClick: {
        toggleDataSeries: true
      }
    },
    grid: {
      borderColor: '#E1E3E6',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    colors: [
        // Education & Professional
        '#2E86C1', // Business & Professional - Deep Blue
        '#3498DB', // Family & Education - Light Blue
        '#85C1E9', // Science & technology - Sky Blue
        
        // Entertainment & Arts
        '#8E44AD', // Film, Media & Entertainment - Purple
        '#9B59B6', // Music - Light Purple
        '#C39BD3', // Performing & visual arts - Lavender
        
        // Health & Wellness
        '#27AE60', // Health & Wellness - Green
        '#2ECC71', // Sports & fitness - Light Green
        
        // Community & Social
        '#E67E22', // Charity & Causes - Orange
        '#F39C12', // Government & Politics - Light Orange
        '#F1C40F', // Religion & spirituality - Yellow
        
        // Lifestyle
        '#E74C3C', // Food & Drink - Red
        '#EC7063', // Fashion & Beauty - Light Red
        
        // Other
        '#34495E', // Automotive - Dark Gray
        '#16A085'  // Travel & outdoor - Teal
      ],
    responsive: [{
      breakpoint: 768,
      options: {
        legend: {
          position: 'bottom',
          offsetY: 0
        }
      }
    }]
  };
  

  return (
    <Card sx={{ width: '100%', borderRadius: "10px", bgcolor: '#F3F4F6',  height: 'min(55vh, 600px)', minHeight: '400px'}} elevation={0}>
  <CardContent sx={{ p: 0, height: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              size="small"
              onClick={() => refetch()}
              sx={{ ml: 1 }}
            >
              {isLoading ? (
                <CircularProgress size={16} thickness={10} />
              ) : (
                <RefreshIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
          
          <Tooltip 
            title="Total Number of Clicks received by different categories of events in each week in a semester."
            arrow
          >
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ p: 2.5, height: 'calc(100% - 90px)' }} >
        <Chart
          options={chartOptions}
          series={lineSeries}
          type="line"
          height="100%"
        />
        </Box>
        
      </CardContent>
    </Card>
  );
};

export default LifeCycleChart;