import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import Email from '@mui/icons-material/Email';
import Google from '@mui/icons-material/Google';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import {
  doCreateUserWithEmailAndPassword,
  doPasswordReset,
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
  doSignOut
} from "../../firebase/auth";
import { auth } from "../../firebase/firebase";

const TextField = ({ label, id, type = "text", placeholder, value, onChange, error }) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "90%", maxWidth: "400px", marginLeft: "20px" }}>
    <label htmlFor={id} style={{ fontSize: "14px", fontWeight: "500", color: "#4a4a4a" }}>{label}</label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        padding: "12px 16px",
        fontSize: "16px",
        border: error ? "1px solid #ff4d4f" : "1px solid #ccc",
        borderRadius: "10px",
        outline: "none",
        width: "100%",
        boxSizing: "border-box",
        transition: "border-color 0.2s, box-shadow 0.2s"
      }}
      onFocus={(e) => {
        e.target.style.borderColor = error ? "#ff4d4f" : "#007bff";
        e.target.style.boxShadow = error ? "0 0 4px rgba(255,77,79,0.2)" : "0 0 4px #8c52ff";
      }}
      onBlur={(e) => {
        e.target.style.borderColor = error ? "#ff4d4f" : "#ccc";
        e.target.style.boxShadow = "none";
      }}
    />
    {error && <span style={{ color: "#ff4d4f", fontSize: "12px" }}>{error}</span>}
  </div>
);


const SelectField = ({ label, id, options, value, onChange }) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "90%", maxWidth: "400px", marginLeft: "20px" }}>
    <label htmlFor={id} style={{ fontSize: "14px", fontWeight: "500", color: "#4a4a4a" }}>{label}</label>
    <select
      id={id}
      value={value}
      onChange={onChange}
      style={{
        padding: "12px 16px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        outline: "none",
        width: "100%",
        boxSizing: "border-box",
        transition: "border-color 0.2s, box-shadow 0.2s",
        backgroundColor: "white",
        cursor: "pointer"
      }}
      onFocus={(e) => {
        e.target.style.borderColor = "#007bff";
        e.target.style.boxShadow = "0 0 4px #8c52ff";
      }}
      onBlur={(e) => {
        e.target.style.borderColor = "#ccc";
        e.target.style.boxShadow = "none";
      }}
    >
      <option value="">Select {label}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

const ActionButton = ({ onClick, label, icon, loading, disabled }) => (
  <button
    onClick={onClick}
    disabled={loading || disabled}
    style={{
      backgroundColor: disabled ? "#d9d9d9" : "#141414",
      color: "white",
      width: "90%",
      maxWidth: "400px",
      padding: "12px 16px",
      borderRadius: "10px",
      cursor: disabled ? "not-allowed" : "pointer",
      border: "none",
      marginLeft: "20px",
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      transition: "background-color 0.2s",
      fontSize: "14px",
      fontWeight: "500"
    }}
  >
    {loading ? (
      <CircularProgress size={20} style={{ color: "white" }} />
    ) : (
      icon
    )}
    {label}
  </button>
);

const SuccessView = ({ email }) => (
  <div style={{ 
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    gap: "20px",
    padding: "40px 20px",
    textAlign: "center" 
  }}>
    <CheckCircleRounded style={{ color: "#52c41a", fontSize: "64px" }} />
    <h2 style={{ margin: 0, fontSize: "24px", fontWeight: "600" }}>Registration Successful!</h2>
    <p style={{ margin: 0, color: "#595959", fontSize: "16px", maxWidth: "400px", lineHeight: "1.5" }}>
      Your Engage account is ready and a registration email with your QR code ticket
      has been sent to <strong>{email}</strong>
    </p>
  </div>
);

const CheckboxField = ({ label, checked, onChange }) => (
  <div style={{ 
    display: "flex", 
    alignItems: "center", 
    gap: "8px", 
    width: "90%", 
    maxWidth: "400px", 
    marginLeft: "20px",
    marginTop: "10px" 
  }}>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      style={{ 
        width: "16px", 
        height: "16px",
        cursor: "pointer"
      }}
    />
    <label style={{ 
      fontSize: "14px", 
      color: "#4a4a4a",
      cursor: "pointer" 
    }}>
      {label} <a 
        href="https://www.lunisity.com/privacy_policy" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ color: "#1890ff" }}
      >
        Privacy Policy
      </a>
    </label>
  </div>
);

const RegisterView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventID } = useParams();
  const { title, uni, registrationLink } = location.state || {};

  const [email, setEmail] = useState("");
  const [studentNumber, setStudentNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [studentNumberError, setStudentNumberError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [studentType, setStudentType] = useState("");
  const [level, setLevel] = useState("");
  const [faculty, setFaculty] = useState("");

  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);



  const STUDENT_TYPES = [
    'International Student',
    'Domestic Student',
    'Exchange Student',
    'Other'
  ];
  
  const LEVELS = [
    "Diploma",
    "Graduate Diploma",
    "Certificate",
    "Undergraduate",
    "Graduate",
    "PhD"
  ];
  
  const FACULTIES = [
    "Faculty of Business Administration",
    "Faculty of Education",
    "Faculty of Engineering and Applied Science",
    "Faculty of Humanities and Social Sciences",
    "Faculty of Medicine",
    "Faculty of Nursing",
    "Faculty of Science",
    "Marine Institute",
    "School of Arctic and Subarctic Studies",
    "School of Graduate Studies",
    "School of Human Kinetics and Recreation",
    "School of Music",
    "School of Pharmacy",
    "School of Social Work",
    "School of Arts & Social Science",
    "School of Fine Arts",
    "School of Science and the Environment"
  ];

  

  const createUserMutation = useMutation(
    async ({ userObject, token }) => {


      await axios.post(
        'https://us-central1-resevents-e239e.cloudfunctions.net/createUserAndRegisterWeb',
        {
          user: userObject,
          event: { _id: eventID, title, uni }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
    },
    {
      onSuccess: async (_, { userObject, token }) => {
        try {
          await axios.post(
            'https://us-central1-resevents-e239e.cloudfunctions.net/sendWelcomeEmail',
            {
              user: userObject,
              event: { _id: eventID, title }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              }
            }
          );
          await doSignOut(auth);
          if (registrationLink) {
            setIsSuccess(true);
            window.open(registrationLink);
          } else {
            setIsSuccess(true);
          }
        } catch (error) {
          console.error("Welcome email error:", error);
        }
      }
    }
  );

  const validateStudentNumber = (value) => {
    if (!value) return "";
    return value.length !== 9 ? "Student Number must be exactly 9 digits." : "";
  };

  const handleSubmit = async () => {
    
    if (!email || !studentNumber || !studentType || !level || !faculty) return;
    const error = validateStudentNumber(studentNumber);
    if (error) {
      setStudentNumberError(error);
      return;
    }
   
    try {
      const randomPassword = Math.random().toString(36).slice(-8);
      const userCredential = await doCreateUserWithEmailAndPassword(email, randomPassword);
      const user = userCredential.user;
      const userObject = {
        _id: user.uid,
        name: user.email,
        email: user.email,
        username: user.email,
        uni: uni,
        type: "student",
        web_user: true,
        studentNumber,
        faculty,
        level,
        studentType,
        lastLogin: Math.floor(Date.now() / 1000)
      };
      const token = await user.getIdToken();
      await createUserMutation.mutateAsync({ userObject, token });
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setShowPassword(true);
        setErrorMessage("An account with this email already exists. Please sign in or reset your password."); // Add this line

      }
    }
  };

  const handleExistingUserSignIn = async () => {
    if (!email || !password || !studentNumber || !studentType || !level || !faculty) return;
    const error = validateStudentNumber(studentNumber);
    if (error) {
      setStudentNumberError(error);
      return;
    }
    
    try {
      const userCredential = await doSignInWithEmailAndPassword(email, password);
      const user = userCredential.user;
      const userObject = {
        _id: user.uid,
        name: user.email,
        email: user.email,
        uni: uni,
        username: user.email,
        type: "student",
        studentNumber,
        faculty,
        level,
        studentType,
        lastLogin: Math.floor(Date.now() / 1000)
      };
      const token = await user.getIdToken();
      await createUserMutation.mutateAsync({ userObject, token });
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        alert("Incorrect password. Please try again or reset your password.");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    if (!studentNumber || !studentType || !level || !faculty) {
      setStudentNumberError("Please enter your student number first");
      return;
    }
    const error = validateStudentNumber(studentNumber);
    if (error) {
      setStudentNumberError(error);
      return;
    }

    try {
      const result = await doSignInWithGoogle();
      const user = result.user;
      const userObject = {
        _id: user.uid,
        name: user.displayName,
        uni: uni,
        email: user.email,
        username: user.email,
        type: "student",
        studentNumber,
        faculty,
        level,
        studentType,
        lastLogin: Math.floor(Date.now() / 1000)

      };
      const token = await user.getIdToken();
      await createUserMutation.mutateAsync({ userObject, token });
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  useEffect(() => {
    if (!title) {
      navigate(`/event/${eventID}`, { replace: true });
    }
  }, [title, eventID, navigate]);

  if (!title) return <div>Redirecting...</div>;
  if (isSuccess) return <SuccessView email={email} />;

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", paddingBottom: "100px" }}>
      <Button onClick={() => navigate(-1)} style={{ margin: "10px" }}>
        <ArrowBackIcon />
      </Button>
      
      <div style={{ marginLeft: "20px" }}>
        <div style={{ color: "#8c8c8c", fontSize: "14px" }}>Registration For</div>
        <h1 style={{ margin: "4px 0", fontSize: "28px", fontWeight: "600" }}>{title}</h1>
        <div style={{ color: "#8c8c8c", fontSize: "12px" }}>Use your School Details!</div>
      </div>

      <div style={{ width: "100%", marginTop: "24px", display: "flex", flexDirection: "column", gap: "16px" }}>
        <TextField
          id="email"
          type="email"
          label="Email"
          placeholder="Your School Email"
          value={email}
          onChange={(e) => {
            const value = e.target.value;
            setEmail(value);
            if (!value) {
              setShowPassword(false);
              setPassword("");
              setErrorMessage("");
            }
          }}
        />

        <TextField
          id="student_number"
          type="text"
          label="Student Number"
          placeholder="Student Number"
          value={studentNumber}
          onChange={(e) => {
            const value = e.target.value;
            setStudentNumber(value);
            setStudentNumberError(validateStudentNumber(value));
          }}
          error={studentNumberError}
        />

<SelectField
          id="student_type"
          label="Student Type"
          options={STUDENT_TYPES}
          value={studentType}
          onChange={(e) => setStudentType(e.target.value)}
        />

        <SelectField
          id="level"
          label="Level"
          options={LEVELS}
          value={level}
          onChange={(e) => setLevel(e.target.value)}
        />

        <SelectField
          id="faculty"
          label="Faculty"
          options={FACULTIES}
          value={faculty}
          onChange={(e) => setFaculty(e.target.value)}
        />


        {showPassword && (
          <TextField
            id="password"
            type="password"
            label="Password"
            placeholder="Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}

        {errorMessage && (
          <div style={{ marginLeft: "20px", color: "#ff4d4f", fontSize: "14px" }}>
            {errorMessage}
          </div>
        )}

<CheckboxField
  label="I agree to the"
  checked={privacyPolicyAccepted}
  onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
/>

        {showPassword ? (
          <>
            <ActionButton
              onClick={handleExistingUserSignIn}
              label="Sign In"
              icon={<ArrowForward style={{ width: '15px' }} />}
              loading={createUserMutation.isLoading}
              disabled={!email || !password || !studentNumber || !!studentNumberError || !privacyPolicyAccepted}
            />
            <ActionButton
              onClick={handleGoogleSignIn}
              label="Sign in with Google"
              icon={<Google style={{ width: '15px' }} />}
              loading={createUserMutation.isLoading}
              disabled={!studentNumber || !!studentNumberError || !privacyPolicyAccepted}
            />
            <div 
              onClick={() => doPasswordReset(email)}
              style={{ 
                marginLeft: "20px", 
                color: "#1890ff", 
                fontSize: "14px",
                cursor: "pointer",
                textDecoration: "underline"
              }}
            >
              Forgot password?
            </div>
          </>
        ) : (
          <>
            <ActionButton
              onClick={handleSubmit}
              label="Register"
              icon={<CheckCircleRounded style={{ width: '15px' }} />}
              loading={createUserMutation.isLoading}
              disabled={!email || !studentNumber || !!studentNumberError || !privacyPolicyAccepted}
            />
            <ActionButton
              onClick={handleGoogleSignIn}
              label="Sign in with Google"
              icon={<Google style={{ width: '15px' }} />}
              loading={createUserMutation.isLoading}
              disabled={!studentNumber || !!studentNumberError || !privacyPolicyAccepted}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RegisterView;