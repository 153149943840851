import React, { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useLoadScript } from '@react-google-maps/api';
import { useAuth } from '../../contexts/authContext';
import { useQuery, useQueryClient } from 'react-query';

import { CheckCircle, Link } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';

import { Close } from '@mui/icons-material';

import Delete from "@mui/icons-material/Delete";
import Image from "@mui/icons-material/Image";

import "./Dash.css"
import CircularProgress from '@mui/material/CircularProgress'; // Importing Material-UI Spinner
import './EventDetailsForm.css'; // Import the CSS file
import Divider from "@mui/material/Divider";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { v4 as uuidv4 } from 'uuid'; // Import the v4 method from uuid to generate a random UUID
import { useUser } from '../../contexts/authContext/UserContext';

const libraries = ['places']; // Include 'places' for Google Places API
const apiKey = 'AIzaSyCm70028pa9kb8ojrhxMRtrOMxfJat0U_I'; // Replace with your actual API key



const styles = {
    textfield: {
        border: 'none',
        fontSize: '30px',
        flexWrap: 'wrap',
        fontWeight: 600,
        fontFamily: "'Lexend', sans-serif"
    },
    descTextfield: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '10px',
        marginTop: '10px',
        minHeight: '100px',
        border: 'none',
        maxWidth: '100%',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        fontFamily: "'Lexend', sans-serif"
    },
    limitTextfield: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '10px',
        marginTop: '10px',
        border: 'none',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        fontFamily: "'Lexend', sans-serif"
    },
    datetimeContainer: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '10px',
        marginTop: '10px',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 500,
        fontFamily: "'Lexend', sans-serif"
    },
    datetimePicker: {
        backgroundColor: 'rgb(249, 249, 249)',
        border: 'none',
        padding: '5px',
        borderRadius: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 300,
        fontFamily: "'Lexend', sans-serif",
        width: '100%',
        boxSizing: 'border-box'
    },
    picker: {
        backgroundColor: 'rgb(249, 249, 249)',
        border: 'none',
        padding: '10px',
        borderRadius: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        marginTop: '10px',
        fontFamily: "'Lexend', sans-serif",
        width: '100%',
        boxSizing: 'border-box'
    },
    placeTextfield: {
        backgroundColor: 'rgb(249, 249, 249)',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginTop: '5px',
        border: 'none',
        width: '100%',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        fontFamily: "'Lexend', sans-serif"
    },
    placeItem: {
        backgroundColor: 'rgb(249, 249, 249)',
        padding: '10px',
        border: 'none',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        fontFamily: "'Lexend', sans-serif",
        transition: 'background-color 0.2s, color 0.2s',
        '&:hover': {
            backgroundColor: 'rgb(0, 0, 0)',
            color: 'white',
            cursor: 'pointer'
        }
    },
    placeItemActive: {
        backgroundColor: 'rgb(0, 0, 0)',
        padding: '10px',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '10px',
        paddingLeft: '10px',
        fontSize: '12px',
        flexWrap: 'wrap',
        fontWeight: 400,
        fontFamily: "'Lexend', sans-serif"
    },
    mainContainer: {
        backgroundColor: "white",
        borderRadius: "10px",
        width: "50%",
        height: "fit-content",
        padding: "20px"
    },
    imageSection: {
        position: 'relative',
        width: '30%'
    },
    imageBox: {
        backgroundColor: '#f8f9fa',
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        borderRadius: '15px',
        overflow: 'hidden'
    },
    formSection: {
        paddingLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '70%'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        padding: '1rem',
        backgroundColor: "#fafafa",
    },
    imageContainer: {
        position: 'relative',
        width: '30%',
        minWidth: '300px',
    },
    imageBox: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        borderRadius: '15px',
        overflow: 'hidden',
        border: '2px dashed #e5e7eb',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '15px',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    input: {
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontSize: '1rem',
        width: '100%',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#3b82f6',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    
};

const iconStyle = {
    width: "15px",
    marginRight: "0px"
}
const _EventForm = ({ event, type }) => {
        const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

    const quickActionButtonStyle = (label) => {
        const colors = {
            edit: { bg: "#F3F4F6", text: "#333333" }, // Light Gray background, Dark Gray text
            hide: { bg: "#FCE8E8", text: "#D32F2F" }, // Light Red background, Dark Red text
            create: { bg: "#E6F4EA", text: "#2E7D32" }, // Light Green background, Dark Green text
            ai: { bg: "#EFE6FF", text: "#5E35B1" }, // Light Purple background, Dark Purple text
            view: { bg: "#E3F2FD", text: "#1565C0" }, // Light Blue background, Dark Blue text
            default: { bg: "#F3F4F6", text: "#333333" }, // Neutral fallback
        };



        const { bg, text } = colors[label] || colors.default;

        return {
            backgroundColor: bg,
            color: text,
            border: "none",
            padding: "5px 15px", // Simplified shorthand
            width: "fit-content",
            borderRadius: "20px",
            fontSize: "12px",
            display: 'flex',
            alignItems: "center",
            gap: "8px",
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            '&:hover': {
                backgroundColor: "#D1FAE5", // A light green hover for consistency (optional)
            }
        };
    };

    const { currentUser, idToken } = useAuth();
    const [creatingEvent, setCreatingEvent] = useState(false);
    const [eventHandled, setEventHandled] = useState(false);
    const queryClient = useQueryClient();

    const { userInfo, updateUserInfo } = useUser();
    
    

    


    async function handleEvent(event) {
        setCreatingEvent(true);

        if (type == "create")
            event._id = uuidv4();

        // Check Dates & assign startDate and endDate
        const validateDateTimeArray = (dateTimeArray) => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            const maxEndDate = now + 30 * 24 * 60 * 60; // 30 days in the future from now (in seconds)

            if (dateTimeArray.length === 0) return null; // Handle empty array case

            // Initialize smallest start and largest end timestamps
            let overallStartDate = dateTimeArray[0].start;
            let overallEndDate = dateTimeArray[0].end;

            // Loop through each object in dateTimeArray to perform checks
            for (let i = 0; i < dateTimeArray.length; i++) {
                const { start, inform, end } = dateTimeArray[i];

                // Check if 'end' date is before 'start' date
                if (end <= start) {
                    return false;
                }

                // Check if 'end' date is more than 30 days from now
                if (end > maxEndDate) {
                    return false;
                }

                // Update overall start date (smallest start timestamp)
                if (start < overallStartDate) {
                    overallStartDate = start;
                }

                // Update overall end date (largest end timestamp)
                if (end > overallEndDate) {
                    overallEndDate = end;
                }
            }

            // If all checks pass, return true and also return the overall start and end timestamps
            return {
                isValid: true,
                overallStartDate,
                overallEndDate,
            };
        };


        // Profanity Clearance
        const profanityClearance = async () => {
            try {
                const response = await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/eventProfanityClearance',
                    {
                        type: 'event',
                        data: {
                            title: event.title,
                            desc: event.desc
                        }
                    },
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                return response.data['result'];


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };

        // Upload Image
        const uploadPosterToFirebase = async (file, eventId) => {

            if (typeof file === 'string') return file;
            if (typeof file === 'object') {
                try {
                const storageRef = ref(storage, `eventPosters/${eventId}`);
                const metadata = {
                    contentType: file.type, // Use the file's type for correct MIME type
                };
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);

                // Rest of the upload logic...

                console.log("UPLOADING IMAGE TO FIREBASE FOR SOME REASON??????")
                const snapshot = await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            // You can track upload progress here if you want
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log("Upload is " + progress + "% done");
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            console.error("Upload failed:", error);
                            reject(error);
                        },
                        () => {
                            // Handle successful uploads
                            resolve(uploadTask.snapshot);
                        }
                    );
                });

                // Get the download URL for the uploaded file
                const downloadURL = await getDownloadURL(snapshot.ref);
                return downloadURL;
            } catch (error) {
                console.error("Error uploading file:", error);
            }
            }

      

          
        };

        // Update/Upload document
        const submitEvent = async () => {
            try {

                const response = type === "create" ?
                await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/submitEventData',
                    event,
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                ):
                await axios.post(
                    'https://us-central1-resevents-e239e.cloudfunctions.net/updateEventData',
                    event,
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` } }
                );

                return response.data;


            } catch (err) {
                console.error(`Error fetching Events: ${err}`);
            }
        };


        console.log("Date Validity Check");
        const dateCheck = validateDateTimeArray(event.dateTime);
        if (dateCheck.isValid) {
            event.startDate = dateCheck.overallStartDate;
            event.endDate = dateCheck.overallEndDate;
            console.log("Dates Valid");
            console.log("Profanity Check");
            const clear = profanityClearance(event);
            if (clear) {
                console.log("Profanity Clear!");
                event.poster = await uploadPosterToFirebase(event.poster, event._id);
            } else {
                console.log("Profanity Not Clear!");
                alert("Event Data contains profane or unsupported language, please identify potential profanity, broken links or unsupported language or text!");
                return;
            }

            console.log("Final Doc!");
            await submitEvent(event);

            try {
                console.log("Invalidating queries...");
                await Promise.all([
                    queryClient.invalidateQueries(['events', currentUser, idToken]),
                    queryClient.invalidateQueries(['event', event._id, idToken]),
                    queryClient.invalidateQueries(['metrics', event._id]),
                    queryClient.invalidateQueries(['studentLists', event._id])
                ]);
                console.log("Queries invalidated successfully");
    
                // Force refetch the queries
                await Promise.all([
                    queryClient.refetchQueries(['events', currentUser, idToken]),
                    queryClient.refetchQueries(['event', event._id, idToken]),
                    queryClient.refetchQueries(['metrics', event._id]),
                    queryClient.refetchQueries(['studentLists', event._id])
                ]);
                console.log("Queries refetched successfully");
    
            } catch (error) {
                console.error("Error invalidating/refetching queries:", error);
            }
            setCreatingEvent(false);
            setEventHandled(true);
            return;
        } else {
            alert("Invalid Event Dates");
            return;
        }






    }



    // Determine initial form data based on whether event is empty or not
    const initialFormData = event._id ? {
        // Event is not empty
        _id: event?._id || '',
        organizer_id: currentUser.uid,


        postsToggle: event.postsToggle,
        postPictures: event.postPictures,
        postReplies: event.postReplies,

        ticketToggle: event.ticketToggle,

        title: event.title,
        desc: event.desc,
        type: event.type,

        limit: event.limit,
        adult: event.adult,

        platformType: event.platformType,
        online: event?.online || [],
        inPerson: event?.inPerson || [],

        dateTime: event.dateTime,
        endDate: event.endDate,
        startDate: event.startDate,
        categories: event.categories,
        poster: event.poster,
        registrationLink: event.registrationLink,
        uni: event.uni
    } : {
        // Event is empty
        _id: '',

        textColor: "white",
        organizer_id: currentUser.uid,
        going: [],
        invites: [
            {
                groupID: 'defaultInvites',
                groupName: 'defaultInvites',
                groupList: []
            }
        ],

        visible: true,
        posts: [],
        postsToggle: true,
        postPictures: true,
        postReplies: true,

        ticketToggle: true,

        flagged: [],
        title: null,
        desc: null,
        type: 'public',
        birthDate: 0,

        limit: 20,
        adult: false,
        eventType: null,
        platformType: 'inPerson',
        online: [],
        inPerson: [],
        hybrid: {
            inPerson: [],
            online: []
        },
        uni: userInfo.uni,
        dateTime: [
            {
                start: Math.floor(Date.now() / 1000), // Default to current timestamp if not set
                end: Math.floor(Date.now() / 1000),   // Default to current timestamp if not set
                inform: Math.floor(Date.now() / 1000),
            }
        ],
        endDate: 0,
        startDate: 0,
        categories: [],
        tags: [],
        poster: '',
        registrationLink: ''
    };

    // Now call useState with the determined initialFormData
    const [formData, setFormData] = useState(initialFormData);


    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [inputValue, setInputValue] = useState('');


    // Load Google Places API with useLoadScript hook
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    // Ensure the hook isn't conditionally rendered.
    if (!isLoaded) {
        // Render early loading state if the script isn't loaded yet.
        return <div>Loading Google Maps...</div>;
    }

    // Handler for input change
    const handleInputChange = (field, value) => {
        setEventHandled(false);
        if (field === 'limit') {
            setFormData({
                ...formData,
                [field]: parseInt(value),
            });
        } else {
            setFormData({
                ...formData,
                [field]: value,
            });
        }




    };


    const handleAdultToggle = () => {
        setFormData(prev => ({
            ...prev,
            adult: !prev.adult
        }));
    };


    const isValidUrl = (input) => {
        try {
            const url = new URL(input);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch {
            return false;
        }
    };

    const handleLocationInputChange = async (value) => {
        setInputValue(value);

        if (isValidUrl(value)) {
            setFormData(prev => ({
                ...prev,
                platformType: 'online',
                online: [value],
                inPerson: []
            }));
            setPredictions([]);
        } else {
            setFormData(prev => ({
                ...prev,
                platformType: 'inPerson',
                online: []
            }));
            await predictLocation(value);
        }
    };

    const predictLocation = (value) => {

        if (!value.trim()) {
            // If the input is empty or just spaces, clear the predictions
            setPredictions([]);
            return; // Don't call the predictLocation function
        }

        // If platformType is 'inPerson', fetch predictions
        if (formData.platformType === 'inPerson' && value) {
            fetchPlacePredictions(value);
        }
    };

    // Function to fetch place predictions
    const fetchPlacePredictions = async (input) => {
        setLoading(true);
        try {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                { input, types: ['establishment'], componentRestrictions: { country: 'ca' } },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(predictions);
                    } else {
                        setPredictions([]);
                    }
                    setLoading(false);
                }
            );
        } catch (error) {
            console.error('Error fetching predictions:', error);
            setLoading(false);
        }
    };

    // Handler when a user selects a place from predictions
    const handlePlaceSelect = async (place) => {


        const details = await fetchPlaceDetails(place.place_id);
        let placeObject = {
            lng: details.geometry.location.lng(),
            lat: details.geometry.location.lat(),
            name: details.name,
            formattedAddress: details.formatted_address,
        };


        setFormData({
            ...formData,
            inPerson: [placeObject],
        });
        setPredictions([]); // Clear predictions after selection
    };

    const fetchPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails(
                { placeId },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(place);  // Resolve with the detailed place information
                    } else {
                        reject('Error fetching place details');
                    }
                }
            );
        });
    };

    // Handle selecting/deselecting categories
    const handleCategoryChange = (category) => {
        const updatedCategories = formData.categories.includes(category)
            ? formData.categories.filter((c) => c !== category) // Deselect
            : [...formData.categories, category]; // Select

        setFormData({
            ...formData,
            categories: updatedCategories,
        });
    };

    const onlineURlChange = (value) => {
        setFormData({
            ...formData,
            online: [value],
        });
    }

    const categories = [
        'Automotive',
        'Business & Professional',
        'Charity & Causes',
        'Family & Education',
        'Fashion & Beauty',
        'Film, Media & Entertainment',
        'Food & Drink',
        'Government & Politics',
        'Health & Wellness',
        'Hobbies & special interest',
        'Music',
        'Performing & visual arts',
        'Religion & spirituality',
        'School activities',
        'Science & technology',
        'Sports & fitness',
        'Travel & outdoor',
    ];

    const eventTypes = [
        'Appearance or Signing',
        'Attraction',
        'Camp, Trip or Retreat',
        'Class, Training or Workshop',
        'Concert or Performance',
        'Conference',
        'Convention',
        'Dinner or Gala',
        'Festival or Fair',
        'Game or Competition',
        'Party or Social Gathering',
        'Race or Endurance Event',
        'Rally',
        'Screening',
        'Seminar or Talk',
        'Tour',
        'Tournament',
        'Trade Show, Consumer Show or Expo',
        'Other',
    ];


    const formatDateToLocal = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleDateChange = (index, field, value) => {
        const updatedDates = formData.dateTime.map((date, idx) => {
            if (idx === index) {
                // Handle 'start' field changes
                if (field === 'start') {
                    const newInform = value - 24 * 60 * 60; // Set 'inform' 24 hours before the start

                    // Update 'start' and 'inform'
                    return {
                        ...date,
                        start: value,
                        inform: newInform
                    };
                }

                // Handle 'end' field changes
                if (field === 'end') {
                    // Update 'end' date
                    return {
                        ...date,
                        end: value
                    };
                }
            }
            return date; // Return unchanged date objects if they are not being updated
        });

        // Update the state with the modified dateTime array
        setFormData({
            ...formData,
            dateTime: updatedDates
        });
    };

    const formatTimestamp = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric', // Simplified from '2-digit' for compatibility
            hour12: true, // For AM/PM format
        }).format(date);
    };

    // Test with a sample timestamp

    const handleCategoryAdd = (category) => {
        setFormData(prev => {
            const updatedCategories = prev.categories.includes(category)
                ? prev.categories.filter(cat => cat !== category) // Remove if already exists
                : [...prev.categories, category]; // Add if doesn't exist

            return {
                ...prev,
                categories: updatedCategories
            };
        });
    };



  // Add these new handler functions
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleInputChange('poster', files[0]);
    }
  };

    return (
        <div style={{
            backgroundColor: "white",
            borderRadius: "10px",
            width: "70%",
            height: "fit-content",
            padding: "20px"
        }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                <div style={{ position: 'relative', width: '30%' }}>
                    <div
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        style={{
                            backgroundColor: isDragging ? '#e2e8f0' : formData.poster ? '#f8f9fa' : '#f8f9fa',
                            width: '100%',
                            paddingBottom: '100%',
                            position: 'relative',
                            borderRadius: '15px',
                            overflow: 'hidden',
                            border: isDragging ? '2px dashed #3b82f6' : formData.poster ? 'none' : '2px dashed #e5e7eb',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                        }}
                    >
                        {formData.poster && (
                            <>
                                {/* Background expanded image with dark overlay */}
                                <img
                                    src={
                                        formData.poster instanceof File
                                            ? URL.createObjectURL(formData.poster)
                                            : formData.poster
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        filter: 'brightness(0.7)',
                                        transform: 'scale(1.1)',
                                        zIndex: 1
                                    }}
                                />
                                {/* Main centered image */}
                                <img
                                    src={
                                        formData.poster instanceof File
                                            ? URL.createObjectURL(formData.poster)
                                            : formData.poster
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        zIndex: 2
                                    }}
                                />
                            </>
                        )}

                        {!formData.poster && (
                            <Image style={{
                                color: "#f0f0f0",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '50%',
                                height: '50%',
                                borderRadius: '15px',
                            }} />
                        )}
                    </div>

                    {/* Edit/Delete controls - always visible */}
                    <div style={{
                        position: 'relative',
                        marginTop: "20px",
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                        zIndex: 3,
                    }}>
                        <button
                            onClick={() => document.querySelector('input[type="file"]').click()}
                            style={{
                                padding: '12px',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                color: '#ffffff',
                                border: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                width: '50px',
                                height: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                            }}
                        >
                            <Edit style={{ width: '20px', height: '20px' }} />
                        </button>

                        {formData.poster && (
                            <button
                                onClick={() => handleInputChange('poster', null)}
                                style={{
                                    padding: '12px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    color: '#ffffff',
                                    border: 'none',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    width: '50px',
                                    height: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                                }}
                            >
                                <Delete style={{ width: '20px', height: '20px' }} />
                            </button>
                        )}
                    </div>

                    <input
                        type="file"
                        onChange={(e) => handleInputChange('poster', e.target.files[0])}
                        style={{ display: 'none' }}
                    />
                </div>







                <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'column', width: '70%' }}>
                    <input
                        type="text"
                        style={styles.textfield}
                        value={formData.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                        placeholder="Event Title"
                    />


                    {/* <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px"
                    }}>
                        <div style={quickActionButtonStyle("view")} onClick={() => {}}><Add style={{width: "15px"}}/> Add Form</div>
                    </div> */}


                    <input
                        type="text" // Use 'number' for numerical input
                        value={formData.registrationForm} // Update this to match your state field
                        onChange={(e) => handleInputChange('registrationLink', e.target.value)} // Update the field name accordingly
                        placeholder="External Registration Form"
                        style={styles.limitTextfield}
                    />
                    <textarea
                        value={formData.desc}
                        style={styles.descTextfield}
                        onChange={(e) => handleInputChange('desc', e.target.value)}
                        placeholder="Event Description"
                    ></textarea>

                    {/* Limit */}
                    <div style={{ fontSize: "12px", marginTop: "20px", color: "grey" }}>Limit</div>
                    <input
                        type="number" // Use 'number' for numerical input
                        list="limit-options" // Link to the datalist
                        value={formData.limit} // Update this to match your state field
                        onChange={(e) => handleInputChange('limit', e.target.value)} // Update the field name accordingly
                        placeholder="Can't leave this empty!"
                        style={styles.limitTextfield}
                    />

                    <div style={{ fontSize: "12px", marginTop: "20px", color: "grey" }}>Date & Time</div>
                    <div style={styles.datetimeContainer}>
                        {formData.dateTime && formData.dateTime.map((date, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                                    <label style={{ color: "grey" }}>Start</label>

                                    <label style={{ color: "grey" }}>End  </label>

                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <input
                                        type="datetime-local"
                                        style={styles.datetimePicker}
                                        value={formatDateToLocal(date.start)}
                                        onChange={(e) => handleDateChange(index, 'start', new Date(e.target.value).getTime() / 1000)}
                                    />
                                    <input
                                        type="datetime-local"
                                        style={styles.datetimePicker}
                                        value={formatDateToLocal(date.end)}
                                        onChange={(e) => handleDateChange(index, 'end', new Date(e.target.value).getTime() / 1000)}
                                    />
                                </div>


                            </div>
                        ))}
                    </div>


                    <div style={{ fontSize: "12px", marginTop: "20px", color: "grey" }}>Location</div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "0px", gap: "10px", marginBottom: "10px" }}>
                        {/* Location Search Field */}
                        {formData.online.length > 0 &&
                            <div style={{
                                backgroundColor: "#fafafa", marginTop: "5px", width: "100%", color: "#007bff", borderRadius: "10px", fontSize: "12px", paddingTop: "20px", paddingBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center"
                            }}>
                                <Link style={{ width: "15px", marginRight: "10px" }} /> {formData.online[0]}
                            </div>
                        }
                        <div>
                            <input
                                type="text"
                                style={styles.placeTextfield}
                                placeholder="Add a Location or Link"
                                onChange={(e) => { handleLocationInputChange(e.target.value) }}
                            />
                            {/* Prediction List */}
                            {
                                formData.inPerson.length > 0 && (
                                    <div >
                                        {formData.inPerson.map((place) => (
                                            <div
                                                key={place.lng}
                                                style={{ ...styles.placeItemActive, marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                                            >
                                                {place.name}
                                                <Close
                                                    style={{ width: "15px" }}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            inPerson: [],
                                                        });
                                                    }} />
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                            {loading ? (
                                <div style={styles.placeTextfield}>Loading predictions...</div>
                            ) : (
                                predictions.length > 0 && (
                                    <div >
                                        {predictions.map((prediction) => (
                                            <div
                                                key={prediction.place_id}
                                                style={{ ...styles.placeItem, marginTop: "10px" }}
                                                onClick={() => handlePlaceSelect(prediction)}
                                            >
                                                {prediction.description}
                                            </div>
                                        ))}
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div style={{ fontSize: "12px", marginTop: "10px", color: "grey" }}>Attributes</div>
                    {/* Event Type Dropdown */}
                    <div>
                        <select
                            id="eventType"
                            style={styles.picker}
                            value={formData.eventType}
                            onChange={(e) => handleInputChange('eventType', e.target.value)}
                        >
                            <option value="" disabled>
                                Select an event type
                            </option>
                            {eventTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div >
                        <select
                            style={styles.picker}
                            onChange={(e) => handleCategoryAdd(e.target.value)}
                            value=""
                        >
                            <option value="" disabled>Select Categories</option>
                            {categories.map((category) => (
                                <option
                                    key={category}
                                    value={category}
                                    disabled={formData.categories.includes(category)}
                                >
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{
                        marginTop: '8px',
                        marginBottom: "10px",
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                        gap: '8px',
                        width: '100%'
                    }}>
                        {formData.categories.map((category) => (
                            <div
                                key={category}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#e2f5e9',
                                    padding: '8px 12px',
                                    borderRadius: '8px',
                                    border: 'none',
                                    minWidth: 'fit-content',
                                    transition: 'background-color 0.2s ease',
                                    cursor: 'default'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#d1e7dd'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#e2f5e9'}
                            >
                                <span style={{
                                    color: '#166534',
                                    fontWeight: 500,
                                    fontSize: '12px'
                                }}>{category}</span>
                                <button
                                    onClick={() => handleCategoryAdd(category)}
                                    style={{
                                        marginLeft: '8px',
                                        color: '#166534',
                                        cursor: 'pointer',
                                        border: 'none',
                                        background: 'none',
                                        padding: '4px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                    aria-label={`Remove ${category} category`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            width: '16px',
                                            height: '16px'
                                        }}
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>



                    <div style={{
                        borderRadius: "10px",
                        cursor: "pointer",
                        backgroundColor: "#E3F2FD",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "#1565C0",
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                        onClick={() => {
                            const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

                            // Validation messages
                            if (isEmptyArray(formData.categories)) {
                                return alert("Select Categories!");
                            }

                            if (formData.platformType === "inPerson" && isEmptyArray(formData.inPerson)) {
                                return alert("Enter a Location!");
                            }

                            if (formData.platformType === "online" && isEmptyArray(formData.online)) {
                                return alert("Enter a URL for this online event!");
                            }

                            if (formData.limit === 0) {
                                return alert("Limit cannot be 0!");
                            }

                            if (!formData.poster) {
                                return alert("Add an Image before uploading event!");
                            }

                            if (!formData.title) {
                                return alert("Don't forget to add a Title!");
                            }

                            if (!formData.desc) {
                                return alert("Don't forget to add an event Description!");
                            }

                            handleEvent(formData);
                        }}
                    >
                        {
                         creatingEvent ? (
                            <>
                            <CircularProgress style={{ ...iconStyle, width: '10px', height: "10px", color: "#1565C0", marginRight: "10px" }} /> Submitting
                            </>
                         ) : eventHandled ? (<><CheckCircle style={{ ...iconStyle, width: "15px", color: "#1565C0", marginRight: "10px" }} /> Submitted</>) : (<div>Submit</div>)  
                        }
                    </div>





                </div>
            </div>














        </div>
    );
};


export default _EventForm;
