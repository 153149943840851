import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doSignInWithEmailAndPassword, doSignInWithGoogle, doSignOut } from "../../firebase/auth";
import { useAuth } from "../../contexts/authContext";
import { ArrowCircleRightRounded } from "@mui/icons-material";
import { QuestionMarkRounded } from "@mui/icons-material";
import { Google } from "@mui/icons-material";
import { CircularProgress, colors, Divider } from "@mui/material";
import { ArrowCircleRight } from "@mui/icons-material";
import { ArrowCircleLeft } from "@mui/icons-material";
import { doPasswordReset } from "../../firebase/auth";

import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { useMutation } from 'react-query';
import axios from 'axios';
const quickActionButtonStyle = (label) => {
  const colors = {
      edit: { bg: "#F3F4F6", text: "#333333" },
      delete: { bg: "#FCE8E8", text: "#D32F2F" },
      create: { bg: "#E6F4EA", text: "#2E7D32" },
      ai: { bg: "#EFE6FF", text: "#5E35B1" },
      view: { bg: "#E3F2FD", text: "#1565C0" },
      default: { bg: "#F3F4F6", text: "#333333" },
  };

  const { bg, text } = colors[label] || colors.default;

  return {
      backgroundColor: bg,
      color: text,
      border: "none",
      padding: "5px 15px",
      width: "fit-content",
      borderRadius: "20px",
      fontSize: "12px",
      display: 'flex',
      alignItems: "center",
      gap: "8px",
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
          backgroundColor: "#D1FAE5",
      }
  };
};

const ResetPassword = ({ onCancel }) => {
    const [email, setEmail] = useState("");
    const [isResetting, setIsResetting] = useState(false);
    const [message, setMessage] = useState("");
  
    const formContainerStyle = {
      width: "100%",
      maxWidth: "500px",
      padding: "40px",
    };
  
    const titleStyle = {
      fontSize: "60px",
      fontWeight: "600",
      color: "#1a1a1a",
      marginBottom: "8px",
    };
  
    const subtitleStyle = {
      fontSize: "12px",
      color: "#666",
      marginBottom: "32px",
    };
  
    const inputStyle = {
      width: "100%",
      padding: "12px 16px",
      marginBottom: "16px",
      border: "1px solid #e1e1e1",
      borderRadius: "8px",
      fontSize: "15px",
      outline: "none",
      transition: "border-color 0.2s",
    };
  
    const buttonStyle = {
      width: "50%",
      padding: "12px",
      borderRadius: "8px",
      border: "none",
      fontSize: "12px",
      fontWeight: "500",
      cursor: "pointer",
      transition: "background-color 0.2s",
      marginBottom: "12px",
    };
  
    const primaryButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#8c52ff",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    };
  
    const cancelButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#141414",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    };
  
    const messageStyle = {
      fontSize: "12px",
      textAlign: "center",
      marginTop: "16px",
      color: message.includes("error") ? "#dc2626" : "#059669",
    };

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
          case 'auth/user-not-found':
            return 'No account found with this email address';
          case 'auth/invalid-email':
            return 'Please enter a valid email address';
          case 'auth/missing-email':
            return 'Please enter an email address';
          case 'auth/too-many-requests':
            return 'Too many attempts. Please try again later';
          default:
            return 'An error occurred. Please try again';
        }
      };
  
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!email.trim()) {
          setMessage('Please enter an email address');
          return;
        }
        
        setIsResetting(true);
        try {
          await doPasswordReset(email);
          setMessage("success: Password reset email sent! Check your inbox.");
          setTimeout(() => onCancel(), 3000); // Return to login after successful reset
        } catch (error) {
          console.log('Password Reset Error:', error.code);
          const errorMessage = getErrorMessage(error.code);
          setMessage(errorMessage);
        } finally {
          setIsResetting(false);
        }
      };
  
    return (
      <div style={formContainerStyle}>
        <h1 style={titleStyle}>Reset Password</h1>
        <p style={subtitleStyle}>Enter your email to receive a password reset link</p>
        <form onSubmit={handleResetPassword}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={inputStyle}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <button
              type="submit"
              style={{...quickActionButtonStyle('ai'), padding: "10px", flex:"1", justifyContent: "center"}}
              disabled={isResetting}
            >
              {isResetting ? "Sending..." : "Send Reset Link"} 
              <ArrowCircleRight style={{ marginLeft: "20px", width: '20px' }}/>
            </button>
            <button
              onClick={onCancel}
              style={{...quickActionButtonStyle('default'), padding: "10px", flex:"1", justifyContent: "center"}}
              type="button"
            >
              Back to Login
            </button>
          </div>
          {message && <p style={messageStyle}>{message}</p>}
        </form>
      </div>
    );
  };

  
  const AdminSignupPanel = ({ onCancel }) => {
    const [formData, setFormData] = useState({
      username: '',
      name: '',
      email: '',
      faculty: '',
      password: '',
      confirmPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
  
    const faculties = [
      'Faculty of Business Administration',
      'Faculty of Education',
      'Faculty of Engineering and Applied Science',
      'Faculty of Humanities and Social Sciences',
      'Faculty of Medicine',
      'Faculty of Nursing',
      'Faculty of Science',
      'School of Graduate Studies',
      'School of Human Kinetics and Recreation',
      'School of Music',
      'School of Pharmacy',
      'School of Social Work',
      'Career Development Office',
      'Student Experience Office',
      'Internationalization Office',
      'Student Volunteer Bureau'
    ];
  
    const categories = [
      "Automotive", "Business & Professional", "Charity & Causes",
      "Family & Education", "Fashion & Beauty", "Film, Media & Entertainment",
      "Food & Drink", "Government & Politics", "Health & Wellness",
      "Hobbies & Special Interest", "Music", "Performing & Visual Arts",
      "Religion & Spirituality", "School Activities", "Science & Technology",
      "Seasonal & Holiday", "Sports & Fitness", "Travel & Outdoor"
    ];
  
    const submitUserData = async (userData) => {
      return axios.post(
        'https://us-central1-resevents-e239e.cloudfunctions.net/submitUserData',
        userData,
        { 
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${userData.accessToken}` 
          } 
        }
      );
    };
  
    const mutation = useMutation(submitUserData, {
      onSuccess: () => {
        setFormData({
          username: '',
          name: '',
          email: '',
          faculty: '',
          password: '',
          confirmPassword: ''
        });
        if (onCancel) onCancel();
      },
      onError: (error) => {
        console.error('Signup Error:', error);
        setErrorMessage(error.message);
      }
    });
  
    const formContainerStyle = {
      width: "100%",
      maxWidth: "500px",
      padding: "40px",
    };
  
    const titleStyle = {
      fontSize: "60px",
      fontWeight: "600",
      color: "#1a1a1a",
      marginBottom: "8px",
    };
  
    const subtitleStyle = {
      fontSize: "12px",
      color: "#666",
      marginBottom: "32px",
    };
  
    const inputStyle = {
      width: "100%",
      padding: "12px 16px",
      marginBottom: "16px",
      border: "1px solid #e1e1e1",
      borderRadius: "8px",
      fontSize: "15px",
      outline: "none",
      transition: "border-color 0.2s",
    };
  
    const buttonStyle = {
      width: "50%",
      padding: "12px",
      borderRadius: "8px",
      border: "none",
      fontSize: "12px",
      fontWeight: "500",
      cursor: "pointer",
      transition: "background-color 0.2s",
      marginBottom: "12px",
    };
  
    const primaryButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#8c52ff",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    };
  
    const cancelButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#141414",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    };
  
    const errorStyle = {
      color: "#dc2626",
      fontSize: "12px",
      marginTop: "8px",
      textAlign: "center",
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (formData.password !== formData.confirmPassword) {
        setErrorMessage('Passwords do not match.');
        return;
      }
  
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth, 
          formData.email, 
          formData.password
        );
        const user = userCredential.user;
  
        mutation.mutate({
          _id: user.uid,
          name: formData.name,
          email: formData.email,
          username: formData.username,
          faculty: formData.faculty,
          type: 'organizer',
          uni: "MUN-SJ",
          version: '6.7.7+39',
          image: 'https://raw.githubusercontent.com/GirishVerm/turnUp_codebase/fd7e9094f087d54520d7f6564cc9a415d58e4706/assets/images/image-2.png',
          categories,
          accessToken: user.accessToken
        });
  
      } catch (error) {
        console.error('Signup Error:', error);
        setErrorMessage(error.message);
      }
    };
  
    return (
      <div style={formContainerStyle}>
        <h1 style={titleStyle}>Create Account</h1>
        <p style={subtitleStyle}>Sign up for an Engage Admin account</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleInputChange}
            style={inputStyle}
          />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleInputChange}
            style={inputStyle}
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            style={inputStyle}
          />
          <select
            name="faculty"
            value={formData.faculty}
            onChange={handleInputChange}
            style={inputStyle}
          >
            <option value="">Select Faculty or Department</option>
            {faculties.map((faculty, index) => (
              <option key={index} value={faculty}>{faculty}</option>
            ))}
          </select>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            style={inputStyle}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            style={inputStyle}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <button
              type="submit"
              style={primaryButtonStyle}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? "Creating Account..." : "Create Account"} 
              <ArrowCircleRightRounded style={{ marginLeft: "20px", width: '20px' }}/>
            </button>
            <button
              onClick={onCancel}
              style={cancelButtonStyle}
              type="button"
            >
              Back to Login
            </button>
          </div>
          {errorMessage && <p style={errorStyle}>{errorMessage}</p>}
        </form>
      </div>
    );
  };
  



const AdminLogin = () => {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const containerStyle = {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
  };

  const leftPanelStyle = {
    flex: "0.5", // To maintain 16:9 ratio
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const leftPanelBodyStyle = {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center'
};

  const headerImageStyle = {
    width: "80%",
  };


  const rightPanelStyle = {
    flex: "0.5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px",
  };

  const formContainerStyle = {
    width: "100%",
    maxWidth: "500px",
    padding: "40px",
  };

  const titleStyle = {
    fontSize: "60px",
    fontWeight: "600",
    color: "#1a1a1a",
    marginBottom: "8px",
  };

  const subtitleStyle = {
    fontSize: "12px",
    color: "#666",
    marginBottom: "32px",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px 16px",
    marginBottom: "16px",
    border: "1px solid #e1e1e1",
    borderRadius: "8px",
    fontSize: "15px",
    outline: "none",
    transition: "border-color 0.2s",
  };

  const buttonStyle = {
    width: "50%",
    padding: "12px",
    borderRadius: "8px",
    border: "none",
    fontSize: "12px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "background-color 0.2s",
    marginBottom: "12px",
  };

  const primaryButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#8c52ff",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  };

  const forgotPasswordButtonStyle = {
    ...buttonStyle,
    backgroundColor: isSigningIn ? "grey" : "#141414",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  };

  const googleButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#f3f4f6",
    width: "100%",
    color: "#666",
    color: "#141414",
    display: "flex", 
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    alignItems: "center"
  };

  const signupButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#f3f4f6",
    width: "100%",
    color: "#141414",
  };

 
  const errorStyle = {
    color: "#dc2626",
    fontSize: "12px",
    marginTop: "10px",
    textAlign: "center",
  };

  const dividerStyle = {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  };

  const dividerLineStyle = {
    flex: 1,
    height: "1px",
    backgroundColor: "#e1e1e1",
  }


  const dividerTextStyle = {
    color: "#666",
    padding: "0 16px",
    fontSize: "14px",
  };

  const footer1 = {
    textAlign: "center",
    fontSize: "0.5em",
    marginBottom: "20px",
    color: "#666"

  }

  const footer2 = {
    textAlign: "center",
    fontSize: "0.5em",
    marginBottom: "20px",
    color: "#666",
    cursor: "pointer",

  }

  const onGoogleSignIn = async (e) => {
    setErrorMessage("");

    e.preventDefault();
    if (isSigningIn) return;
    setIsSigningIn(true);

    try {
      const result = await doSignInWithGoogle();
      const user = result.user;

      const response = await axios.post(
        'https://us-central1-resevents-e239e.cloudfunctions.net/checkAdminAccess',
        {
          userID: user.uid
        },
        {
          headers: {
            'Authorization': `Bearer ${await user.getIdToken()}`
          }
        }
      );

      if (response.data.hasAdminAccess) {
        setEmail('');
        setPassword('');
        navigate("/admin-dashboard");
      } else {
        await doSignOut();
        setErrorMessage("You do not have Admin Access");
      }


    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setErrorMessage(error.code);
    } finally {
      setIsSigningIn(false);
    }
  };
  
  const onSubmit = async (e) => {
    setErrorMessage("");

    e.preventDefault();
    if (isSigningIn) return;
    setIsSigningIn(true);

    try {
      const result = await doSignInWithEmailAndPassword(email, password);
      const user = result.user;

      const response = await axios.post(
        'https://us-central1-resevents-e239e.cloudfunctions.net/checkAdminAccess',
        {
          userID: user.uid
        },
        {
          headers: {
            'Authorization': `Bearer ${await user.getIdToken()}`
          }
        }
      );

      if (response.data.hasAdminAccess) {
        setEmail('');
        setPassword('');
        navigate("/admin-dashboard");
      } else {
        await doSignOut();
        setErrorMessage("You do not have Admin Access");
      }

      setEmail('');
      setPassword('');
      navigate("/admin-dashboard");
    } catch (error) {
      console.error('Email Sign-In Error:', error);
      setErrorMessage(error.code);
    } finally {
      setIsSigningIn(false);
    }
  };

  // State variables
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [taskName, setTaskName] = useState('');

  
  const rightPanelContent = taskName === 'reset' ? 
  (
    <ResetPassword onCancel={() => setTaskName('')} />
  ) : 
  
  taskName === 'signup' ? 
  (
    <AdminSignupPanel onCancel={() => setTaskName('')}/>
  ) : 
  
  // Login
  (<div style={formContainerStyle}>
    <h1 style={titleStyle}>Welcome Back</h1>
    <p style={subtitleStyle}>Sign in to access your Engage Admin account</p>
    <form onSubmit={onSubmit}>
      <input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={inputStyle}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={inputStyle}
      />
      
      {isSigningIn ? 
      (
      <div style={{...dividerTextStyle, justifyContent: "center", display: "flex", alignItems: "center", gap: "10px"}}> <CircularProgress size={12} thickness={4} color="#666"/> Signing In...</div>
      ) : (
        <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
     
     
      <button
        type="submit"
        style={{...quickActionButtonStyle('ai'), padding: "10px", flex:"1", justifyContent: "center"}}
        disabled={isSigningIn}
      >
        {isSigningIn ? "Signing In..." : "Sign In"} <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItemss: "flex-end"}}><ArrowCircleRightRounded style={{ marginLeft: "20px", width: '20px' }}/></div>
      </button>
      <button
          onClick={() => {
            setTaskName('reset')}}
        style={{...quickActionButtonStyle('default'), padding: "10px", flex:"1", justifyContent: "center"}}
        disabled={isSigningIn}
      >
        {isSigningIn ? "Forgot Password" : "Forgot Password"}
      
      
      </button>
      </div>
      )}

      
      

      <div style={dividerStyle}>
        <div style={dividerLineStyle}></div>
        <span style={dividerTextStyle}>or</span>
        <div style={dividerLineStyle}></div>
      </div>

      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <button
        onClick={onGoogleSignIn}
        style={{...quickActionButtonStyle('create'), padding: "10px", width: "100%", justifyContent: "center"}}
        disabled={isSigningIn}
        type="button"
      >
      <Google style={{ width: '20px' }}/> Sign In with Google
      </button>
      {errorMessage && <p style={errorStyle}>{errorMessage}</p>}
      {errorMessage === "You do not have Admin Access" ? (<button
        onClick={() => window.open("mailto:support@lunisity.com?subject=RE%3A%20Engage%20Admin%20Access%20Request&body=Hello%20Lunisity%20Support%20Team%2C%0A%0AI%20am%20NAME%2C%20representing%20DEPARTMENT%2FCLUB%20at%20UNIVERSITY.%20I%20am%20requesting%20admin%20access%20to%20the%20Engage%20platform%20to%20manage%20our%20organization's%20events%20and%20access%20analytics%20data.%0A%0ARole%3A%20ROLE%0ADepartment%2FClub%3A%20DEPARTMENT%2FCLUB%0AUniversity%3A%20UNIVERSITY%0AEmail%3A%20EMAIL%0A%0AThis%20access%20will%20help%20us%20better%20organize%20and%20track%20our%20events%2C%20as%20well%20as%20understand%20student%20engagement%20patterns.%0A%0AThank%20you%20for%20your%20assistance.%0A%0ABest%20regards%2C%0ANAME"        )}
        style={{...quickActionButtonStyle('view'), padding: "20px", width: "100%", justifyContent: "center"}}
        type="button"
      >
        Ask for Admin Access <ArrowCircleRight/>
      </button>) : (<div></div>)}
      </div>
      
    </form>
  </div>);


  return (
    <div style={containerStyle}>
      <div style={leftPanelStyle}>
        <div style={leftPanelBodyStyle}>
        <img src="https://raw.githubusercontent.com/GirishVerm/lunisity_assets/refs/heads/main/Untitled%20design%20(2)%20(1).png" style={headerImageStyle}/>
        </div>
      </div>


      <div style={rightPanelStyle}>
        {rightPanelContent}
        <footer style={footer1}>
    Lunisity Software Inc. · Engage {new Date().getFullYear()}
</footer>

        <footer style={footer2} onClick={()=>{navigate('/privacy_policy')}}>
            Privacy Policy
        </footer>
      </div>
      
    </div>
  );
};

export default AdminLogin;