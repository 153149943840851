import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { axiosInstance } from '../../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress';
import { UnfoldMore, ArrowUpward, ArrowDownward, Delete, Download } from '@mui/icons-material';
import { Add, Visibility, VisibilityOff } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import { Close } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import { usePDF } from 'react-to-pdf';
import generatePDF from 'react-to-pdf';

import { useTheme } from '../../contexts/authContext/ThemeContext';
import { colors } from '../color';



// Reuse the styling from EventsPage
const iconStyle = {
    width: "15px",
    marginRight: "0px"
}
const quickActionButtonStyle = (label) => {
    const colors = {
      edit: { bg: "#F3F4F6", text: "#333333" }, // Light Gray background, Dark Gray text
      delete: { bg: "#FCE8E8", text: "#D32F2F" }, // Light Red background, Dark Red text
      create: { bg: "#E6F4EA", text: "#2E7D32" }, // Light Green background, Dark Green text
      ai: { bg: "#EFE6FF", text: "#5E35B1" }, // Light Purple background, Dark Purple text
      view: { bg: "#E3F2FD", text: "#1565C0" }, // Light Blue background, Dark Blue text
      default: { bg: "#F3F4F6", text: "#333333" }, // Neutral fallback
    };
  
  
  
    const { bg, text } = colors[label] || colors.default;
  
    return {
      backgroundColor: bg,
      color: text,
      border: "none",
      padding: "5px 15px", // Simplified shorthand
      width: "fit-content",
      borderRadius: "20px",
      fontSize: "12px",
      display: 'flex',
      alignItems: "center",
      gap: "8px",
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: "#D1FAE5", // A light green hover for consistency (optional)
      }
    };
  };

const baseTableCell = {
    backgroundColor: '#fff',
    color: '#333',
    verticleAlign: "center",
    height: "fit-content",
    lineHeight: "1",
    padding: '12px 16px',
    borderRadius: '6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    cursor: "pointer",
    border: '1px solid #f0f0f0',
    transition: 'background-color 0.2s',
    '&:hover': {
        backgroundColor: '#f8f9fa'
    }
};

const styles = {
    parentContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'none',
        padding: '0px'
    },
    container: {
        display: 'flex',
        flex: "1",
        flexDirection: 'column',
        overflowY: 'none',
        padding: '0px'
    },
    buttonGroup: {
        display: 'flex',
        alignItems: "center",
        gap: '15px',
        marginTop: "10px",
        marginBottom: '10px',
    },
    tableContainer: {
        display: 'grid',
        gridTemplateColumns: '0.5fr 2fr 1fr 1fr 0.5fr',
        gap: '12px',
        marginTop: '10px',
        overflowY: 'auto',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    },
    tableColumn: {
        backgroundColor: '#f8f9fa',
        color: '#444',
        flex: 1,
        padding: '12px 16px',
        borderRadius: '6px',
        fontSize: '13px',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        '&:hover': {
            backgroundColor: '#f0f1f2'
        }
    },
    tableCell: {
        ...baseTableCell
    },
    titleCell: {
        ...baseTableCell,
        textAlign: 'start',
    },

    overlayContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'auto',
        padding: '20px'
    },
    closeButton: {
        position: 'fixed',
        top: '20px',
        right: '20px',
        backgroundColor: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 1001
    }
};

// API functions
const fetchReports = async ({ queryKey }) => {
    const [_, currentUser, idToken] = queryKey;
    const response = await axiosInstance.post(
        '/getReports',
        { userID: currentUser.uid },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        }
    );
    return response.data.reports;
};

const _Reports = () => {
    const { currentUser, idToken } = useAuth();
    const queryClient = useQueryClient();

    const { isDarkMode, toggleTheme } = useTheme();
    

    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [sortedReports, setSortedReports] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredReports, setFilteredReports] = useState([]);

    const [selectedReport, setSelectedReport] = useState();
    const [deletingReportId, setDeletingReportId] = useState(null);


    const reportRef = useRef();

    const semHeader = {
        fontSize: "50px",
        fontWeight: "600",
        color: colors.header(isDarkMode)
    };

    const handleCellClick = (report) => {

        setSelectedReport(report);
    };

    // Function to close overlay
    const handleCloseOverlay = () => {
        setSelectedReport(null);
    };

    // React Query hooks
    const {
        data: reports = [],
        isLoading,
        refetch
    } = useQuery(
        ['reports', currentUser, idToken],
        fetchReports,
        {
            enabled: !!currentUser && !!idToken
        }
    );

    // Delete report mutation
    const deleteReportMutation = useMutation(
        async (reportId) => {
            await axiosInstance.post(
                '/deleteReport',
                {
                    userID: currentUser.uid,
                    reportID: reportId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );
        },
        {
            onSuccess: async () => {
                await queryClient.refetchQueries(['reports', currentUser, idToken]);
                setDeletingReportId(null); // Clear the deleting state

            }
        }
    );

    useEffect(() => {
        if (sortedReports.length > 0 && searchTerm) {
            const filtered = sortedReports.filter(report => {
                const searchLower = searchTerm.toLowerCase();
                const dateStr = new Date(report.createdAt * 1000).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                }).toLowerCase();

                return (
                    report.event.title?.toLowerCase().includes(searchLower) ||
                    report.type?.toLowerCase().includes(searchLower)
                );
            });
            setFilteredReports(filtered);
        } else {
            setFilteredReports(sortedReports);
        }
    }, [sortedReports, searchTerm]);


    // Effect to update sortedReports when reports changes or sort configuration changes
    useEffect(() => {
        if (reports) {
            const sorted = [...reports].sort((a, b) => {
                const valueA = a[sortField];
                const valueB = b[sortField];
                return sortOrder === 'asc'
                    ? valueA > valueB ? 1 : -1
                    : valueA < valueB ? 1 : -1;
            });
            setSortedReports(sorted);
        }
    }, [reports, sortField, sortOrder]);

    const handleRefresh = async () => {
        setRefreshLoading(true);
        await refetch();
        setRefreshLoading(false);
    };

    const handleSort = (field) => {
        const newOrder = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newOrder);
    };

    const handleToggleVisibility = (reportId) => {
        setDeletingReportId(reportId);

        deleteReportMutation.mutate(reportId);
    };

    const SortIcon = ({ field }) => {
        if (sortField !== field) {
            return <UnfoldMore className="text-gray-400" style={{ fontSize: '18px' }} />;
        }
        return sortOrder === 'asc' ?
            <ArrowUpward className="text-blue-500" style={{ fontSize: '18px' }} /> :
            <ArrowDownward className="text-blue-500" style={{ fontSize: '18px' }} />;
    };


    const formatTimestamp = (unixTimestamp) => {

        const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };


    function ReportComponent({ report, reportRef }) {

        // Destructure the main objects from report
        const { event, metrics, studentLists } = report;

        // Destructure event details
        const {
            title,
            birthDate,
            startDate,
            endDate,
            attendeeCount,
            clickCount,
            registrationCount,
            saveCount
        } = event;

        // Destructure metrics
        const {
            attendanceMetrics,
            clickMetrics,
            registrationMetrics
        } = metrics;

        // Destructure student lists
        const {
            attendanceStudentList = [], // Providing default empty array
            registrationStudentList = [],
            clicksStudentList = []
        } = studentLists;


        // Create metrics array for the cards
        const metricsData = [
            { value: attendeeCount, label: 'Attendees' },
            { value: registrationCount, label: 'Registrations' },
            { value: clickCount, label: 'Clicks' },
            { value: saveCount, label: 'Saves' }
        ];

        // Format timestamps using the provided timestamps
        const createdDateTime = formatTimestamp(birthDate);
        const startDateTime = formatTimestamp(startDate);
        const endDateTime = formatTimestamp(endDate);

        // The rest of your component code remains the same, but now you can use
        // the destructured variables directly instead of accessing them with brackets


        // Styles
        const styles = {
            container: {
                width: "fit-content",

                margin: '0 auto',
                padding: '2rem',
                backgroundColor: '#F8FAFC',
                overflowY: "scroll",
                marginTop: "20px",
                border: "1px solid #103458", // Thin border for visual separation
                borderRadius: "10px",        // Border radius for rounded corners
            },
            reportContainer: {
                width: "800px",
                margin: '0 auto',
                padding: '2rem',
                backgroundColor: '#FFFFFF',
                transition: 'all 0.3s ease', // Smooth resizing for better user experience
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Optional shadow for visual hierarchy
            },
            header: {
                marginBottom: '2rem',
            },
            subheading: {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: '#64748B',
                textTransform: 'uppercase',
                letterSpacing: '0.05em',
                marginBottom: '0.5rem',
            },
            mainHeading: {
                fontSize: '2rem',
                fontWeight: 600,
                color: '#0F172A',
                lineHeight: 1.2,
            },
            card: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1.25rem',
                marginBottom: '2rem',
            },
            dateRow: {
                marginBottom: '0.75rem',
            },
            dateLabel: {
                fontSize: '0.75rem',
                fontWeight: 500,
                color: '#64748B',
                marginBottom: '0.25rem',
            },
            dateValue: {
                fontSize: '0.875rem',
                color: '#1E293B',
            },
            metricsGrid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '1rem',
                marginBottom: '2rem',
            },
            metricCard: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1.25rem',
                textAlign: 'center',
            },
            metricValue: {
                fontSize: '1.75rem',
                fontWeight: 600,
                color: '#0F172A',
                marginBottom: '0.5rem',
            },
            metricLabel: {
                fontSize: '0.875rem',
                color: '#64748B',
                fontWeight: 500,
            },
            sectionHeading: {
                fontSize: '1.125rem',
                fontWeight: 600,
                color: '#1E293B',
                marginBottom: '1rem',
            },
            demographicsGrid: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '1.5rem',
                marginBottom: '2rem',
            },
            demographicCard: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                overflow: 'hidden',
            },
            cardHeader: {
                padding: '1rem',
                backgroundColor: '#F8FAFC',
                borderBottom: '1px solid #E2E8F0',
            },
            cardTitle: {
                fontSize: '0.875rem',
                fontWeight: 600,
                color: '#1E293B',
            },
            table: {
                width: '100%',
                borderCollapse: 'collapse',
            },
            th: {
                textAlign: 'left',
                padding: '0.75rem 1rem',
                fontSize: '0.75rem',
                fontWeight: 500,
                color: '#64748B',
                borderBottom: '1px solid #E2E8F0',
            },
            td: {
                padding: '0.75rem 1rem',
                fontSize: '0.875rem',
                borderBottom: '1px solid #E2E8F0',
            },
            highlightRow: {
                backgroundColor: '#F1F5F9',
            },
            percentage: {
                color: '#64748B',
                fontSize: '0.75rem',
            },
            studentSection: {
                marginTop: '2rem',
            },
            studentTableWrapper: {
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #E2E8F0',
                padding: '1rem',
                marginBottom: '2rem',
            },
            tableGrid: {
                display: 'grid',
                gridTemplateColumns: 'minmax(120px, 1fr) minmax(200px, 2fr) minmax(200px, 2fr) minmax(100px, 1fr)',
                gap: '0.75rem',
            },
            tableHeader: {
                backgroundColor: '#F8FAFC',
                padding: '0.75rem 1rem',
                fontSize: '0.65rem',
                fontWeight: 600,
                height: "fit-content",
                borderRadius: '6px',
            },
            tableCell: {
                padding: '0.75rem 1rem',
                fontSize: 'clamp(0.65rem, 1vw, 0.7rem)', // Responsive font size
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                wordBreak: 'break-word', // Allows long words to break and wrap
                whiteSpace: 'normal',
            },
            footer: {
                textAlign: 'center',
                padding: '2rem',
                color: '#64748B',
                fontSize: '0.875rem',
            },
        };




        // Demographics Component
        const DemographicCard = ({ title, data }) => (


            <div style={styles.demographicCard}>
                <div style={styles.cardHeader}>
                    <div style={styles.cardTitle}>{title}</div>
                </div>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Category</th>
                            <th style={styles.th}>Count</th>
                            {/* <th style={styles.th}>%</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(data).map(([category, count], index) => (
                            <tr
                                key={index}
                                style={styles.td}
                            >
                                <td style={styles.td}>{category}</td>
                                <td style={styles.td}>{count}</td>
                                {/* Uncomment and calculate percentage if needed
              <td style={styles.td}>
                <span style={styles.percentage}>
                  {/* Calculate percentage based on total or any other logic * /}
                  {/* {(count / total) * 100}% */}
                                {/* </span>
              </td>
              */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );

        // Student Table
        const StudentTable = ({ title, students }) => (
            <div style={styles.studentSection}>
                <h3 style={styles.sectionHeading}>{title}</h3>
                <div style={styles.studentTableWrapper}>
                    <div style={styles.tableGrid}>
                        <div style={styles.tableHeader}>Student ID</div>
                        <div style={styles.tableHeader}>Name</div>
                        <div style={styles.tableHeader}>Email</div>
                        <div style={styles.tableHeader}>Enrollment Year</div>

                        {students.map((student, index) => (
                            <React.Fragment key={index}>
                                <div style={styles.tableCell}>{student['studentNumber']}</div>
                                <div style={styles.tableCell}>{student['name']}</div>
                                <div style={styles.tableCell}>{student['email']}</div>
                                <div style={styles.tableCell}>{student['yearJoined']}</div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );

        // Report Component
        const EventAnalyticsReport = () => {
            return (
                <div style={styles.container}>
                    <div style={styles.reportContainer} ref={reportRef}>
                        {/* Header Section */}
                        <div style={styles.header}>
                            <div style={styles.subheading}>Engage Events Analytics Report</div>
                            <h1 style={styles.mainHeading}>{title}</h1>
                            <div style={styles.dateLabel}>Report ID {report._id}</div>
                        </div>

                        {/* Date Section */}
                        <div style={styles.card}>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Report Created</div>
                                <div style={styles.dateValue}>{formatTimestamp(report.birthDate)}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event Post Date</div>
                                <div style={styles.dateValue}>{createdDateTime}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event Start</div>
                                <div style={styles.dateValue}>{startDateTime}</div>
                            </div>
                            <div style={styles.dateRow}>
                                <div style={styles.dateLabel}>Event End</div>
                                <div style={styles.dateValue}>{endDateTime}</div>
                            </div>
                        </div>

                        {/* Metrics Section */}
                        <div style={styles.metricsGrid}>
                            {metricsData.map((metric, index) => (
                                <div key={index} style={styles.metricCard}>
                                    <div style={styles.metricValue}>{metric.value}</div>
                                    <div style={styles.metricLabel}>{metric.label}</div>
                                </div>
                            ))}
                        </div>

                        {/* Demographics Sections - Now using proper object access */}
                        <h1 style={styles.header}>Demographics</h1>
                        <div>
                            <h2 style={styles.sectionHeading}>Attendees Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={attendanceMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={attendanceMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={attendanceMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={attendanceMetrics.yearJoined}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 style={styles.sectionHeading}>Registration Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={registrationMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={registrationMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={registrationMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={registrationMetrics.yearJoined}
                                />
                                {/* Add other demographic cards similarly */}
                            </div>

                        </div>

                        <div>
                            <h2 style={styles.sectionHeading}>Clicks Demographics</h2>
                            <div style={styles.demographicsGrid}>
                                <DemographicCard
                                    title="Faculty Distribution"
                                    data={clickMetrics.faculty}
                                />
                                <DemographicCard
                                    title="Student Origin"
                                    data={clickMetrics.origin}
                                />
                                <DemographicCard
                                    title="Program Level"
                                    data={clickMetrics.level}
                                />
                                <DemographicCard
                                    title="Enrollment Year"
                                    data={clickMetrics.yearJoined}
                                />
                                {/* Add other demographic cards similarly */}
                            </div>
                        </div>

                        {/* Similar updates for Registration and Clicks Demographics */}

                        {/* Student Lists Section */}
                        <h1 style={styles.header}>Student Lists</h1>
                        <div>
                            <StudentTable
                                title="Registered Students"
                                students={registrationStudentList}
                            />
                            <StudentTable
                                title="Attendees"
                                students={attendanceStudentList}
                            />
                            <StudentTable
                                title="Students Who Clicked"
                                students={clicksStudentList}
                            />
                        </div>

                        {/* Footer */}
                        <div style={styles.footer}>
                            Lunisity Software Inc. · Engage 2025
                        </div>
                    </div>
                </div>
            );
        };

        return <EventAnalyticsReport />;
    };


    return (
            <div style={styles.parentContainer}>
            {selectedReport && (
                <div style={styles.overlayContainer}>
                    <div style={styles.closeButton} onClick={handleCloseOverlay}>
                        <Close />
                    </div>
                    <div style={{ ...quickActionButtonStyle("default"), padding: "10px 15px" }} onClick={() => {
                        generatePDF(reportRef);
                    }}>

                        <Download style={iconStyle} /> Save Report
                    </div>
                    <ReportComponent report={selectedReport} reportRef={reportRef}
                    />
                </div>
            )}
            <div style={styles.container}>
            <div style={semHeader}>
                    Reports
                </div>

                <div style={styles.buttonGroup}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        backgroundColor: '#F3F4F6',
                        borderRadius: '20px',
                        marginTop: "0px",
                        padding: '5px 15px',
                        width: '300px'
                    }}>
                        <Search style={{ width: "15px", color: '#9ca3af' }} />
                        <input
                            type="text"
                            placeholder="Search reports..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                border: 'none',
                                outline: 'none',
                                width: '100%',
                                fontSize: '12px',
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                    <div
                        style={{...quickActionButtonStyle("edit"), paddingTop: refreshLoading || isLoading ? "10px" : "5px", paddingBottom: refreshLoading || isLoading ? "10px" : "5px"}}
                        onClick={handleRefresh}
                    >
                        {refreshLoading || isLoading? (
                            <CircularProgress size={16} thickness={4}/>
                        ) : (
                            <ReplayIcon style={{width: "15px"}} />
                        )}
                    </div>
                </div>

                <div style={{...styles.tableContainer, backgroundColor: isDarkMode ? '#4A5555' : 'white'}}>
                    {/* Table Headers */}
                    <div className="font-semibold" style={styles.tableColumn}>ID</div>
                    <div className="font-semibold" style={styles.tableColumn}>Title</div>
                    <div
                        className="font-semibold hover:bg-gray-100 transition-colors cursor-pointer"
                        style={styles.tableColumn}
                        onClick={() => handleSort('birthDate')}
                    >
                        Created On
                        <SortIcon field="birthDate" />
                    </div>
                    <div
                        className="font-semibold hover:bg-gray-100 transition-colors cursor-pointer"
                        style={styles.tableColumn}
                        onClick={() => handleSort('type')}
                    >
                        Type
                        <SortIcon field="type" />
                    </div>
                    <div className="font-semibold" style={styles.tableColumn}>Actions</div>

                    {/* Table Content */}
                    {isLoading ? (
                        <div className="col-span-5 flex justify-center items-center h-32">
                            <CircularProgress thickness={4} />
                        </div>
                    ) : (
                        filteredReports.map(report => (
                            <React.Fragment key={report._id}>
                                <div style={styles.tableCell} onClick={() => {
                                    handleCellClick(report);
                                }}>
                                    {report._id}
                                </div>
                                <div style={styles.titleCell} onClick={() => {
                                    handleCellClick(report)
                                }}>
                                    {report.event.title}
                                </div>
                                <div style={styles.tableCell} onClick={() => {
                                    handleCellClick(report)
                                }}>
                                    {formatTimestamp(report.birthDate)}
                                </div>
                                <div style={styles.tableCell} onClick={() => {
                                    handleCellClick(report)
                                }}>{report.type}</div>




                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent:"center" }}>
                                    <div
                                        style={quickActionButtonStyle("create")}
                                        onClick={() => {
                                            handleCellClick(report)
                                        }}
                                    >
                                        <Download style={{ width: "15px" }} /> Save
                                    </div>
                                    <div
                                        style={quickActionButtonStyle("delete")}
                                        onClick={() => !deleteReportMutation.isLoading && handleToggleVisibility(report._id)}
                                    >
                                        {deleteReportMutation.isLoading && deletingReportId === report._id ? (
                                            <CircularProgress size={16} thickness={4} color="#D32F2F"/> 
                                        ) : (
                                            <>
                                                <Delete style={{ width: "15px" }} /> Delete
                                            </>
                                        )}
                                    </div>

                                </div>
                            </React.Fragment>
                        ))
                    )}
                </div>
            </div>


        </div>
    
            
    );
        
};

export default _Reports;