import React, { createContext, useContext, useState } from 'react';
import { useTheme } from '../../contexts/authContext/ThemeContext';
import { colors } from '../color';

// Create a context for notifications
export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState({
    events: true,
    programs: true,
    reports: true,
    manage: true
  });

  const setNotification = (menuItem, value) => {
    setNotifications(prev => ({
      ...prev,
      [menuItem.toLowerCase()]: value
    }));
  };

  const clearNotification = (menuItem) => {
    setNotifications(prev => ({
      ...prev,
      [menuItem.toLowerCase()]: false
    }));
  };

  return (
    <NotificationContext.Provider value={{ notifications, setNotification, clearNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

const NavbarWithNotifications = ({ currentPage, setCurrentPage, navItems }) => {

  const { isDarkMode, toggleTheme } = useTheme();
  
  const { notifications, clearNotification } = useContext(NotificationContext);

  const navbarButtonStyle = (item) => ({
    padding: '8px 16px',
    marginRight: '0px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: currentPage === item.toLowerCase()  ? isDarkMode ? '#fafafa' :  '#141414' : '#969696',
    borderBottom: 'none',
    fontSize: currentPage === item.toLowerCase() ? '15px' : '12px',
    fontWeight: currentPage === item.toLowerCase() ? '600' : '400',
    transition: 'all 0.2s ease',
    position: 'relative'
  });

  const notificationDotStyle = {
    position: 'absolute',
    top: '0',
    right: '4px',
    width: '8px',
    height: '8px',
    backgroundColor: '#ff0000',
    borderRadius: '50%'
  };

  const handleClick = (item) => {
    setCurrentPage(item.toLowerCase());
    clearNotification(item.toLowerCase());
  };

  return (
    <div className="flex justify-center items-center p-5">
      {navItems.map((item) => (
        <button
          key={item}
          onClick={() => handleClick(item)}
          className="relative"
          style={navbarButtonStyle(item)}
        >
          {item}
          {notifications[item.toLowerCase()] && (
            <span style={notificationDotStyle} />
          )}
        </button>
      ))}
    </div>
  );
};

export default NavbarWithNotifications;