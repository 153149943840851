import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useMutation, useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthProvider, useAuth } from '../../contexts/authContext';
import axios from 'axios';
import { Add, ArrowDownward, ArrowUpward, Light } from '@mui/icons-material';
import { Article } from '@mui/icons-material';
import LifeCycleChart from './LifeCycleChart';
import _Events from './_Events';
import EventsPage from './Events';
import { Logout } from '@mui/icons-material';
import { doSignOut } from '../../firebase/auth';
import { useNavigate } from 'react-router-dom';
import engage from '../../assets/engage.png'; // Adjust the path
import engage_white from '../../assets/engage.png';
import NavbarWithNotifications, { NotificationProvider } from './Notification';
import { axiosInstance } from '../../contexts/authContext';
import {Select, MenuItem} from '@mui/material';
import SessionExpiredAlert from './_SessionExpired';
import _Reports from './_Reports';
import { Close } from '@mui/icons-material';
import _EventForm from './_EventForm';
import { jwtDecode } from 'jwt-decode';
import { LightMode } from '@mui/icons-material';
import { DarkMode } from '@mui/icons-material';
import { useTheme } from '../../contexts/authContext/ThemeContext';
import { colors } from '../color';
import _LifeCycleChart from './_LifeCycleChart';
import _EventsPerWeek from './_EventPerWeek';
import { Card, Box, Typography, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useUser } from '../../contexts/authContext/UserContext';


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 2, // 5 minutes
            cacheTime: 1000 * 60 * 30, // 30 minutes
        },
    },
});


const DashboardWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <NotificationProvider>
            <Dash />
        </NotificationProvider>

    </QueryClientProvider>
);


const Dash = () => {

    const { isDarkMode, toggleTheme } = useTheme();

    const supportOverlay = {
        position: 'fixed',
        width: "fit-content",
        top: '50px',  // Changed from top: 0 to bottom: 20px
        right: '20px',   // Changed from left: 0 to right: 20px
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'flex-end',    // Changed from center
        alignItems: 'flex-end',        // Changed from flex-start
        overflow: 'auto',
        padding: '20px'
    };
    const overlayContainer = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'auto',
        padding: '20px'
    };
    const closeButton = {
        position: 'fixed',
        top: '20px',
        right: '20px',
        marginRight: "20px",
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: "5px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 1001
    };


    const { userInfo, updateUserInfo } = useUser();

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState('overview');
    const { currentUser, idToken, userLoggedIn } = useAuth();
    const [showSessionAlert, setShowSessionAlert] = useState(false);

    const [createEvent, setCreateEvent] = useState(false);


    const { mutate: getUserInfo } = useMutation(
        async () => {
            const response = await axiosInstance.post(
                '/getAdminInfo',
                {
                    userID: currentUser.uid,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );

            return response.data;
        },
        {
            onSuccess: (data) => {
                // Update the UserContext with the received data
                updateUserInfo({
                    uni: data.uni,
                    email: data.email,
                    name: data.name
                });
            },
            onError: (error) => {
                console.error('Error fetching user info:', error);
                if (error.response?.status === 401) {
                    setShowSessionAlert(true);
                }
            },
        }
    );

    const checkTokenExpiration = () => {
        if (idToken) {
            const decodedToken = jwtDecode(idToken); // Decode the token to get its payload
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            if (decodedToken.exp < currentTime) {
                // Token is expired
                setShowSessionAlert(true);
            }
        }
    };

    
    useEffect(() => {
        if (currentUser && idToken) {
            getUserInfo();
        }
    }, [currentUser, idToken]);

    

    // Set an interval to check token expiration every minute
    useEffect(() => {
        const intervalId = setInterval(() => {
            checkTokenExpiration();
        }, 60000); // Run every 60 seconds

        return () => clearInterval(intervalId); // Clear the interval when component unmounts
    }, [idToken]);


    // React Query useMutation for marking last login
    const { mutate: markLastLogin } = useMutation(
        async () => {
            const response = await axiosInstance.post(
                '/markLastLogin',
                {
                    userID: currentUser.uid,
                    timestamp: Math.floor(Date.now() / 1000)
                },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );
            return response.data;
        },
        {
            onError: (error) => {
                console.error(`Error marking last login: ${error}`);
                if (error.response?.status === 401) {
                    setShowSessionAlert(true);
                }
            },
        }
    );

    useEffect(() => {
        if (currentUser && idToken) {
            markLastLogin();
        }
    }, [currentUser, idToken, markLastLogin]);

    const handleCloseAlert = () => {
        setShowSessionAlert(false);
    };

    // Call markLastLogin when component mounts
    useEffect(() => {
        if (currentUser && idToken) {
            markLastLogin();
        }
    }, [currentUser, idToken, markLastLogin]);

    // Add Programs, Manage
    const navItems = ['Overview', 'Events', 'Reports'];

    const parentStyle = {
        width: '100%',
        height: '100vh',
        backgroundColor: colors.background(isDarkMode),
        overflowY: 'hidden', // Enables vertical scrolling
        overflowX: 'hidden',

    }

    const navbarStyle = {
        width: '100%',
        height: '60px',
        backgroundColor: colors.background(isDarkMode),
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: 'center',
        padding: '0 20px',


    }

    const navbarButtonStyle = (item) => {
        return {
            padding: '8px 16px',
            marginRight: '16px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            color: currentPage === item.toLowerCase() ? isDarkMode ? '#fafafa' : '#141414' : '#969696',
            borderBottom: 'none',
            fontSize: currentPage === item.toLowerCase() ? '15px' : '12px',
            fontWeight: currentPage === item.toLowerCase() ? '600' : '400',
            transition: 'all 0.2s ease'
        }
    };

    const accountText = {
        marginLeft: "20px",
        cursor: 'pointer',
        width: "100%",
        flex: "1",
        color: '#141414',
        borderBottom: 'none',
        fontSize: '15px',
        fontWeight: '600',

    };

    const logoutFrame = {
        display: "flex",
        flexDirection: "row",
        flex: "1",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: "0px",
        cursor: 'pointer',
        color: '#141414',
        width: "100%",
        borderBottom: 'none',
        fontSize: '12px',

    };

    const bodyStyle = {
        padding: '20px',
        height: 'calc(100vh - 60px)',
        overflowY: 'scroll',
        backgroundColor: colors.background(isDarkMode),
        marginLeft: "100px",
        marginRight: "100px"


    }

    const semHeader = {
        fontSize: "50px",
        fontWeight: "600",
        color: colors.header(isDarkMode),

    };

    const secondaryTextStyle = {
        marginTop: "10px",
        fontSize: "12px",
        fontWeight: "400",
        color: "#969696",
        marginBottom: "10px"

    }

    const quickActionButtonStyle = (label) => {
        const colors = {
            edit: { bg: "#F3F4F6", text: "#333333" },
            delete: { bg: "#FCE8E8", text: "#D32F2F" },
            create: { bg: "#E6F4EA", text: "#2E7D32" },
            ai: { bg: "#EFE6FF", text: "#5E35B1" },
            view: { bg: "#E3F2FD", text: "#1565C0" },
            default: { bg: "#F3F4F6", text: "#333333" },
        };

        const { bg, text } = colors[label] || colors.default;

        return {
            backgroundColor: bg,
            color: text,
            border: "none",
            padding: "5px 15px",
            width: "fit-content",
            borderRadius: "20px",
            fontSize: "12px",
            display: 'flex',
            alignItems: "center",
            gap: "8px",
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            '&:hover': {
                backgroundColor: "#D1FAE5",
            }
        };
    };


    const fetchActiveUsers = async ({ queryKey }) => {
        const now = new Date();
        const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const today_timestamp = Math.floor(midnight.getTime() / 1000);
        const current_timestamp = Math.floor(Date.now() / 1000);

        const yesterdayMidnight = new Date(midnight);
        const yesterday_timestamp = Math.floor((yesterdayMidnight.getDate() - 1) / 1000);
        const [_key, idToken] = queryKey;
        const response = await axios.post(
            'https://us-central1-resevents-e239e.cloudfunctions.net/getActiveUsers',
            {
                uni: "MUN-SJ",
                today_timestamp: today_timestamp,
                current_timestamp: current_timestamp,
                yesterday_timestamp: yesterday_timestamp
            },
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            }
        );
        return response.data;
    };

    const { data: dataActive, isLoading: isLoadingActive } = useQuery(
        ['activeUsers', idToken],
        fetchActiveUsers,
        {
            refetchInterval: 5 * 60 * 1000, // Refetch every 5 minutes
            staleTime: 4.5 * 60 * 1000,     // Consider data stale after 4.5 minutes
        }
    );

    const fetchActiveUsersWeekly = async ({ queryKey }) => {
        const [_key, idToken] = queryKey;
        const response = await axios.post(
            'https://us-central1-resevents-e239e.cloudfunctions.net/getWeeklyActiveUsers',
            {
                uni: "MUN-SJ",
            },
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            }
        );
        return response.data;
    };

    

    const { data: dataWeekly = {},
        isLoading: isLoadingWeekly, } = useQuery(
        ['activeUsersWeekly', idToken],
        fetchActiveUsersWeekly,
        {
            refetchInterval: 5 * 60 * 1000, // Refetch every 5 minutes
            staleTime: 4.5 * 60 * 1000,     // Consider data stale after 4.5 minutes
        }
    );

    const Overview = () => {


        const generateSemesterOptions = (currentDate) => {
            const year = currentDate.getFullYear();
        
            // Define semester start dates
            const winterStart = new Date(year, 0, 8).getTime() / 1000; // Jan 8th
            const springStart = new Date(year, 4, 8).getTime() / 1000; // May 8th
            const fallStart = new Date(year, 8, 8).getTime() / 1000;   // Sep 8th
        
            // Define previous Fall semester for cases before Jan 8th
            const prevFallStart = new Date(year - 1, 8, 8).getTime() / 1000; // Sep 8th of the previous year
        
            // Get current timestamp
            const now = currentDate.getTime() / 1000;
        
            let selectedSemester;
        
            // Determine the correct semester based on the current date
            if (now < winterStart) {
                selectedSemester = { label: `Fall ${year - 1}`, timestamp: prevFallStart };
            } else if (now >= winterStart && now < springStart) {
                selectedSemester = { label: `Winter ${year}`, timestamp: winterStart };
            } else if (now >= springStart && now < fallStart) {
                selectedSemester = { label: `Spring ${year}`, timestamp: springStart };
            } else {
                selectedSemester = { label: `Fall ${year}`, timestamp: fallStart };
            }
        
            return selectedSemester;
        };
        
  
        
        
        const currentDate = new Date();

        const [selectedSemester, setSelectedSemester] = useState(generateSemesterOptions(currentDate)); 


        const handleChange = (event) => {
            // We now get both the label and the timestamp directly from the event value
            const selectedOption = event.target.value;
            
            setSelectedSemester(selectedOption);
        };
            

        const semHeader = {
            fontSize: "50px",
            fontWeight: "600",
            color: colors.header(isDarkMode)
        };

        const secondaryTextStyle = {
            fontSize: "12px",
            fontWeight: "400",
            color: "#969696",
            marginTop: "5px",
            marginBottom: "10px"
        };

        const quickActionButtonStyle = (label) => {
            const colors = {
                edit: { bg: "#F3F4F6", text: "#333333" }, // Light Gray background, Dark Gray text
                delete: { bg: "#FCE8E8", text: "#D32F2F" }, // Light Red background, Dark Red text
                create: { bg: "#E6F4EA", text: "#2E7D32" }, // Light Green background, Dark Green text
                ai: { bg: "#EFE6FF", text: "#5E35B1" }, // Light Purple background, Dark Purple text
                view: { bg: "#E3F2FD", text: "#1565C0" }, // Light Blue background, Dark Blue text
                default: { bg: "#F3F4F6", text: "#333333" }, // Neutral fallback
            };

            const { bg, text } = colors[label] || colors.default;

            return {
                backgroundColor: bg,
                color: text,
                border: "none",
                padding: "5px 15px", // Simplified shorthand
                width: "fit-content",
                borderRadius: "20px",
                fontSize: "12px",
                display: 'flex',
                alignItems: "center",
                gap: "8px",
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                '&:hover': {
                    backgroundColor: "#D1FAE5", // A light green hover for consistency (optional)
                }
            };
        };



        return (
            <>
                <div style={semHeader}>
                    {selectedSemester.label}
                </div>


                <h5 style={secondaryTextStyle}>
                    Quick Actions
                </h5>

             
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px"
                }}>
                    <div style={quickActionButtonStyle("edit")} onClick={() => {
                        if (userInfo.uni !== '')
                            setCreateEvent(true);
                    }}>
                        <Add style={{ width: "15px" }} />Create Event
                    </div>
                    
                    {/* Quick Action for New Program */}
                    {/* <div style={quickActionButtonStyle("edit")}>
                        <Add style={{ width: "15px" }} />New Program
                    </div> */}
                    <div style={quickActionButtonStyle("create")} onClick={() => {
                        setCurrentPage('events');
                    }}>
                        <Article style={{ width: "15px" }} />Generate Report
                    </div>
                </div>

                <div style={{ display: "flex", width: "40vw", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: "10px", height: "fit-content", gap: "20px" }}>

                    <div
                        style={{
                            width: '30%',
                            minWidth: '200px',
                            backgroundColor: "#EFE6FF",
                            borderRadius: '12px',
                            padding: '0px',
                            height: 'fit-content',
                            boxShadow: 'none'
                        }}
                    >
                        <div style={{ height: '100%' }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "110px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    paddingTop: "10px",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    color: "#5E35B1",
                                }}>
                                    <span>Active Students Today</span>
                                    <div>
                                        <Tooltip title="This panel shows the total number of students who used the Engage app from 12 AM today until 12 AM tomorrow.">
                                            <HelpOutline style={{ fontSize: "16px", cursor: "pointer", color: "#5E35B1", marginRight: "10px" }} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    paddingTop: "5px",
                                    fontWeight: "500",
                                    fontSize: "30px",
                                    height: "40px",
                                    color: "#5E35B1"
                                }}>
                                    {isLoadingActive ? <CircularProgress size={12} thickness={4} color='#5E35B1'/> : dataActive.total_active_users}
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    color: "#5E35B1",
                                    paddingRight: "10px",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    fontSize: "10px"
                                }}>

                                    {
                                    isLoadingActive ? <CircularProgress size={12} thickness={4} color='#5E35B1'/> : 
                                    dataActive.change >= 0 ? (
                                        <div style={{display: "flex", alignItems:"center"}}><ArrowUpward style={{width: "15px"}}/>{dataActive.change}% since yesterday</div>
                                    ) : (
                                        <div style={{display: "flex", alignItems:"center"}}><ArrowDownward style={{width: "15px"}}/>{dataActive.change}% since yesterday</div>
                                    )
                                    
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            width: '30%',
                            minWidth: '200px',
                            backgroundColor: "#EFE6FF",
                            borderRadius: '12px',
                            padding: '0px',
                            height: 'fit-content',
                            boxShadow: 'none'
                        }}
                    >
                        <div style={{ height: '100%' }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "110px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    paddingTop: "10px",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    color: "#5E35B1",
                                }}>
                                    <span>Active this Week</span>
                                    <div>
                                        <Tooltip title="This panel shows the total number of students who used the Engage app from Monday this week until now">
                                            <HelpOutline style={{ fontSize: "16px", cursor: "pointer", color: "#5E35B1", marginRight: "10px" }} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    paddingTop: "5px",
                                    fontWeight: "500",
                                    fontSize: "30px",
                                    height: "40px",
                                    color: "#5E35B1"
                                }}>

                                    {isLoadingWeekly ? <CircularProgress size={12} thickness={4} color='#5E35B1'/> : dataWeekly.total_active_users_this_week}
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    color: "#5E35B1",
                                    paddingRight: "10px",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    fontSize: "10px"
                                }}>

                                    {
                                    isLoadingWeekly ? <CircularProgress size={12} thickness={4} color='#5E35B1'/> : 
                                    dataWeekly.change >= 0 ? (
                                        <div style={{display: "flex", alignItems:"center"}}><ArrowUpward style={{width: "15px"}}/>{dataWeekly.change}% since last week</div>
                                    ) : (
                                        <div style={{display: "flex", alignItems:"center"}}><ArrowDownward style={{width: "15px"}}/>{dataWeekly.change}% since last week</div>
                                    )
                                    
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: "10px", height: "fit-content", gap: "30px" }}>
                    <_LifeCycleChart semesterInfo={selectedSemester} style={{ flex: "1" }} />
                    <_EventsPerWeek semesterInfo={selectedSemester} style={{ flex: "1" }} />

                </div>
            </>

        );
    };

    const Events = () => {

    };


    return (
        
        <div style={parentStyle}>

            {currentPage == 'overview' && <div style={supportOverlay}>
                <div style={{
                    fontSize: "10px",
                    color: "grey",
                    fontWeight: "700"
                }}>Run into a Bug? Just Call (709)-986-2094 · 24/7 On-Call Support</div>
            </div>}

            {createEvent && <div style={overlayContainer}>
                <div style={closeButton} onClick={() => setCreateEvent(false)}>
                    <Close />
                </div>
                <_EventForm event={{}} type={"create"} />
            </div>}

            <AuthProvider>

                {showSessionAlert && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(20, 20, 20, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#fafafa',
                                borderRadius: '10px',
                                padding: '20px',
                                maxWidth: '400px',
                                width: '90%',
                                textAlign: 'start',
                                fontSize: '12px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    color: '#141414',
                                }}
                            >
                                Session Expired
                            </div>
                            <div
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#969696',
                                }}
                            >
                                Your session has expired. Please log in again to continue.
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <button
                                    style={{
                                        backgroundColor: '#E6F4EA',
                                        color: '#2E7D32',
                                        border: 'none',
                                        padding: '10px 15px',
                                        width: 'fit-content',
                                        borderRadius: '20px',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.2s',
                                    }}
                                    onClick={() => {
                                        setShowSessionAlert(false);
                                        // Optional: Redirect to login
                                        window.location.href = '/';
                                    }}
                                >
                                    Log In
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </AuthProvider>

            {/* Navbar */}
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",

                alignItems: "center",
                marginRight: "100px",
                marginLeft: "100px"
            }}>
                <div style={accountText}>
                    <img src={isDarkMode ? engage_white : engage} style={{ width: "20%", minWidth: "80px", marginTop: "20px" }} />
                </div>


                <div style={navbarStyle}>
                    <NavbarWithNotifications
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        navItems={navItems}
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
                    {isDarkMode ?
                        (
                            <LightMode style={{ ...quickActionButtonStyle("view"), fontSize: "15px", width: "15px", paddingTop: "10px", paddingBottom: "10px" }} onClick={toggleTheme} />

                        ) : (
                            <DarkMode style={{ ...quickActionButtonStyle("view"), fontSize: "15px", width: "15px", paddingTop: "10px", paddingBottom: "10px" }} onClick={toggleTheme} />

                        )
                    }
                    <div style={quickActionButtonStyle("delete")} onClick={async () => {
                        await doSignOut();
                        navigate('/');
                    }}>

                        Logout <Logout style={{ marginLeft: "5px", width: "15px" }} />
                    </div>
                </div>

            </div>


            {/* Content Area */}
            <div style={bodyStyle}>
                {/* Overview Component */}
                <div style={{
                    display: currentPage === 'overview' ? 'block' : 'none'
                }}>
                    <Overview />
                </div>


                {/* Events Component */}
                <div style={{
                    display: currentPage === 'events' ? 'block' : 'none'
                }}>
                    <_Events />
                </div>

                {/* Programs Component */}
                <div style={{
                    display: currentPage === 'programs' ? 'block' : 'none'
                }}>
                    <div style={semHeader}>
                        Programs
                    </div>
                </div>

                {/* Manage Component */}
                <div style={{
                    display: currentPage === 'manage' ? 'block' : 'none'
                }}>
                    <div style={semHeader}>
                        Manage
                    </div>
                </div>

                {/* Reports Component */}
                <div style={{
                    display: currentPage === 'reports' ? 'block' : 'none'
                }}>
                    <_Reports />
                </div>
            </div>
        </div>


    );
};

export default DashboardWrapper;
